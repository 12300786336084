import axios from 'axios';
import qs from 'qs';
import { getAuthTokens } from 'helpers/localStorage';

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) =>
    qs.stringify(params, {
      skipNulls: true,
      encode: false,
      arrayFormat: 'brackets',
    }),
});

Axios.interceptors.request.use(
  async (config) => {
    const { accessToken } = getAuthTokens();

    return {
      ...config,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...config.headers,
      },
    };
  },
  (err) => Promise.reject(err),
);
