import { IntlKeys } from 'localization/keys';

const translations = {
  //COMMON
  [IntlKeys.here]: 'hier',
  [IntlKeys.image]: 'Bild',
  [IntlKeys.video]: 'Video',
  [IntlKeys.loading]: 'Laden...',
  [IntlKeys.helloWorld]: 'Hello World',
  [IntlKeys.email]: 'E-Mail',
  [IntlKeys.password]: 'Passwort',
  [IntlKeys.enterPassword]: 'Passwort eingeben',
  [IntlKeys.passwordPlaceholder]: 'Geben Sie Ihr Passwort ein',
  [IntlKeys.retypePassword]: 'Geben Sie Ihr Passwort erneut ein',
  [IntlKeys.newPassword]: 'Neues Passwort',
  [IntlKeys.retypeNewPassword]: 'Neues Passwort widerholen',
  [IntlKeys.confirm]: 'Bestätigen',
  [IntlKeys.cancel]: 'Abbrechen',
  [IntlKeys.name]: 'Name',
  [IntlKeys.namePlaceholder]: 'Geben Sie Ihren Namen ein',
  [IntlKeys.surname]: 'Vorname',
  [IntlKeys.surnamePlaceholder]: 'Geben Sie Ihren Vornamen ein',
  [IntlKeys.jobTitle]: 'Jobtitel',
  [IntlKeys.jobTitlePlaceholder]: 'Geben Sie einen Titel für den Job ein',
  [IntlKeys.yes]: 'Ja',
  [IntlKeys.no]: 'Nein',
  [IntlKeys.profile]: 'Profil',
  [IntlKeys.organization]: 'Organization',
  [IntlKeys.logout]: 'Ausloggen',
  [IntlKeys.settings]: 'Einstellungen',
  [IntlKeys.apply]: 'Anwenden',
  [IntlKeys.applySend]: 'Absenden',
  [IntlKeys.applyfiles]: 'Lebenslauf, Anschreiben, Zeugnisse hinzufügen',
  [IntlKeys.additionalFiles]: 'Anhänge',
  [IntlKeys.firstName]: 'Vorname',
  [IntlKeys.lastName]: 'Nachname',
  [IntlKeys.phoneNumber]: 'Telefonnummer',
  [IntlKeys.email]: 'E-Mail',
  [IntlKeys.enterFirstName]: 'Vornamen eingeben',
  [IntlKeys.enterLastName]: 'Nachnamen eingeben',
  [IntlKeys.enterEmail]: 'E-Mail eingeben',
  [IntlKeys.enterPhoneNumber]: 'Telefonnummer eingeben',
  [IntlKeys.foundingDate]: 'Gegründet: {date}',
  [IntlKeys.addressStreet]: 'Straße',
  [IntlKeys.addressHouseNumber]: 'Hausnummer',
  [IntlKeys.addressPostcode]: 'Postleitzahl',
  [IntlKeys.addressCountry]: 'Land',
  [IntlKeys.addressCity]: 'Stadt',
  [IntlKeys.dragAndDropOrChoose]: 'Drag & Drop oder Datei auswählen',
  [IntlKeys.undo]: 'Rückgängig machen',
  [IntlKeys.tryAgainMessage]: 'Da ist was schiefgelaufen! Bitte versuchen Sie es erneut.',
  [IntlKeys.save]: 'Speichern',
  [IntlKeys.edit]: 'Bearbeiten',
  [IntlKeys.delete]: 'Löschen',
  [IntlKeys.draft]: 'Entwurf',
  [IntlKeys.add]: 'Hinzufügen',
  [IntlKeys.hiddenSalary]: 'auf Anfrage',
  [IntlKeys.hideSalary]: 'zeige Gehalt dem Talent',
  [IntlKeys.hideSalaryTrue]: 'Zeige es nicht',
  [IntlKeys.hideSalaryFalse]: 'Zeige es',

  //LOGIN PAGE
  [IntlKeys.loginTitle]: 'Willkommen zurück bei Talents&Friends!',
  [IntlKeys.loginSubtitle]: 'Bitte loggen Sie sich in Ihren Account ein um fortzufahren',
  [IntlKeys.signIn]: 'Login',
  [IntlKeys.loginNoAccount]: 'Sie haben noch keinen Account?',
  [IntlKeys.signInError401]: 'Falsche E-Mail oder Passwort',
  [IntlKeys.signInError404]: 'Konnte diesen Nutzer nicht finden!',

  //FORGOT PASSWORD PAGE
  [IntlKeys.forgotPassword]: 'Passwort vergessen',
  [IntlKeys.forgotPasswordQuestion]: 'Passwort vergessen?',
  [IntlKeys.forgotPasswordEnterEmail]: 'Geben Sie die E-Mail Adresse ein welche mit Ihrem Account verknüpft ist.',
  [IntlKeys.forgotPasswordSendLink]: 'Link zum Zurücksetzen senden',
  [IntlKeys.forgotPasswordBackToLogin]: 'Zurück zum Login',
  [IntlKeys.forgotPasswordFulfilled]: 'Es wurde ein Link an Ihre E-Mail gesendet',
  [IntlKeys.forgotPasswordError404]: 'Diese E-Mail Adresse existiert nicht',

  //RESET PASSWORD PAGE
  [IntlKeys.registerHead]: 'Willkommen bei Talents&Friends',
  [IntlKeys.resetPassword]: 'Passwort zurücksetzen',
  [IntlKeys.resetPasswordSubtitle]: 'Bitte geben Sie ein neues Passwort ein',
  [IntlKeys.resetPasswordChangePassword]: 'Passwort ändern',
  [IntlKeys.resetPasswordHaveAccount]: 'Haben Sie schon einen Account?',
  [IntlKeys.resetPasswordFulfilled]: 'Passwort erfolgreich geändert',
  [IntlKeys.resetPasswordError404]: 'Dieser Link ist ungültig',
  [IntlKeys.resetPasswordError422]: 'Server kann die Anfrage nicht verarbeiten',

  //SIGNUP PASSWORD PAGE
  [IntlKeys.signUp]: 'Sign Up',
  [IntlKeys.signUpTitle]: 'Talents&Friends beitreten',
  [IntlKeys.signUpSubTitle]: 'Erstellen Sie einen Account und starten Sie mit der Recruiting Experience!',
  [IntlKeys.signUpHaveAccount]: 'Sie haben bereits einen Account?',
  [IntlKeys.signUpFulfilled]: 'Benutzer erfolgreich erstellt',
  [IntlKeys.signUpError404]: 'Dieser Link ist ungültig',
  [IntlKeys.signUpError422]: 'Dieser Link ist ungültig',
  [IntlKeys.signUpError500]: 'Diese E-Mail Adresse existiert bereits',

  // Form Validation
  [IntlKeys.formFieldFequired]: 'Dies ist ein Pflichtfeld',

  // Auth validations
  [IntlKeys.passwordsMustMatch]: 'Passwörter stimmen nicht überein',
  [IntlKeys.eightCharacterAtLeast]: 'Bitte geben Sie mindestens 8 Zeichen ein',
  [IntlKeys.incorrectEmail]: 'Falsche E-Mail Adresse',
  [IntlKeys.requiredItem]: 'Pflichtfeld',
  [IntlKeys.settingsPasswordMaxCharacter]: 'Passwort darf nicht länger als 20 Zeichen sein',
  [IntlKeys.enterValidString]: 'Bitte geben Sie einen gültigen String ein',

  // Talents
  [IntlKeys.talents]: 'Talente',
  [IntlKeys.talentsAll]: 'Alle Talente',
  [IntlKeys.talentsReview]: 'geprüfte Talente',
  [IntlKeys.talentsAccepted]: 'Eingestellt',
  [IntlKeys.talentsRejected]: 'Nicht eingestellt',
  [IntlKeys.talentsView]: 'Talent ansehen',
  [IntlKeys.talentsDownload]: 'Talent herunterladen',
  [IntlKeys.talentsMarkAsMatch]: 'Einstellen',
  [IntlKeys.talentsMarkAsNoMatch]: 'Nicht einstellen',
  [IntlKeys.talentsZero]: 'noch keine Talente',
  [IntlKeys.talentsProvided]: '<b>{applicationCount}</b>/{maxCandidates} Talente erhalten',
  [IntlKeys.talentsRequested]: 'Maximal Anzahl an Talenten',
  [IntlKeys.talentsBaseCredits]: 'Credits pro Talent',
  [IntlKeys.talentsCreditsNeeded]: 'Summe der Credits',
  [IntlKeys.talentsTotalCredits]: '{creditTotal} Credits',
  [IntlKeys.talentsMinRequired]: 'Bitte wählen Sie mindestens {amount} Talente',

  // Aufträge
  [IntlKeys.jobs]: 'Aufträge',
  [IntlKeys.jobsAbortQuestion]: 'Sind Sie sicher das Sie den Auftrag verlassen wollen?',
  [IntlKeys.jobsAbortDescription]: 'Sie haben eventuell ungespeichert Änderungen die verloren gehen!',
  [IntlKeys.jobsExpressRecruiting]:
    'Expressrecruiting. Es werden mehr Resourcen beim Finden von Talenten von uns aufgebracht',
  [IntlKeys.jobsFilter]: 'nach Aufträge filtern',
  [IntlKeys.jobApplied]: 'Beworben auf',
  [IntlKeys.jobRemarks]: 'Kommentar',
  [IntlKeys.remarksComesHere]: 'Hier kommen die Anforderungen hin',
  [IntlKeys.notAnsweredYet]: 'Noch nicht vom Talent beantwortet',
  [IntlKeys.noQuestions]: 'Keine Fragen hinterlegt',
  [IntlKeys.jobPreview]: 'Auftrag Vorschau',
  [IntlKeys.jobProceed]: 'speichern & weiter',
  [IntlKeys.searchJob]: 'Aufträge durchsuchen',
  [IntlKeys.createJob]: 'Neuen Auftrag erstellen',
  [IntlKeys.jobsAll]: 'Alle Aufträge',
  [IntlKeys.jobsActive]: 'Aktiv',
  [IntlKeys.jobsArchived]: 'Archiviert',
  [IntlKeys.jobsDraft]: 'Entwürfe',
  [IntlKeys.jobDeleteQuestion]: 'Sind Sie sicher, dass Sie den Auftrag löschen möchten?',
  [IntlKeys.jobDeleteDescription]:
    'Sie können dies nicht Rückgänging machen. Der Auftrag wird nich mehr verfügbar sein!',
  [IntlKeys.jobDeleteSuccess]: 'Auftrag erfolgreich gelöscht',
  [IntlKeys.jobDeleteError]: 'Konnte Auftrag nicht löschen. Bitte versuche es erneut',
  [IntlKeys.jobsExpired]: 'Vergangen',
  [IntlKeys.jobDescription]:
    'Eine <b>{contractType, select, fullTime {Vollzeit} partTime {Teilzeit} other {Andere}}</b> Position mit einem Gehalt zwischen <b>{minSalary}</b> und <b>{maxSalary}</b> pro Jahr in <b>{location}</b>. Es werden <b>{maxCandidates}</b> Talente gesucht bis zum <b>{endDate}</b>',
  [IntlKeys.jobName]: 'Jobtitel',
  [IntlKeys.enterJobTitle]: 'Geben Sie einen Titel für den Auftrag ein',
  [IntlKeys.workLocation]: 'Arbeitsort',
  [IntlKeys.contractType]: 'Vertragstyp',
  [IntlKeys.minSalary]: 'Jahresgehalt Minimum',
  [IntlKeys.maxSalary]: 'Jahresgehalt Maximum',
  [IntlKeys.selectCity]: 'Stadt auswählen',
  [IntlKeys.tasks]: 'Aufgaben',
  [IntlKeys.seniority]: 'Erfahrung',
  [IntlKeys.selectSeniority]: 'Erfahrung auswählen',
  [IntlKeys.selectBenefits]: 'Vorteil auswählen',
  [IntlKeys.benefits]: 'Vorteile',
  [IntlKeys.createBenefits]: 'Neue Vorteile hinzufügen',
  [IntlKeys.yourBenefits]: 'Deine Vorteile',
  [IntlKeys.emojiSelect]: 'Emoji auswählen',
  [IntlKeys.requirements]: 'Benötigte Fähigkeiten',
  [IntlKeys.jobCover]: 'Cover',
  [IntlKeys.introVideo]: 'Einführungsvideo',
  [IntlKeys.gallery]: 'Bildergallerie',
  [IntlKeys.customColor]: 'Eigene Schriftfarbe',
  [IntlKeys.customBackgroundColor]: 'Eigene Hintergrundfarbe',
  [IntlKeys.jobDetails]: 'Details',
  [IntlKeys.customization]: 'Personalisierung',
  [IntlKeys.qualifyingQuestion]: 'Qualifizierungsfragen',
  [IntlKeys.questionHeading]: 'Wir haben deine Bewerbung erhalten. Bitte beantworte noch die folgenden {count} Fragen.',
  [IntlKeys.questionShortAnswer]: 'Kurzantwort',
  [IntlKeys.questionShortAnswerDescription]: 'Stellen Sie eine Frage die das Talent in einem Freitext beantwortet.',
  [IntlKeys.questionChoice]: 'Multiple Choice',
  [IntlKeys.questionChoiceDescription]: 'Stellen Sie eine Frage mit mehreren Antwortmöglichkeiten.',
  [IntlKeys.questionYesNo]: 'Ja-Nein Frage',
  [IntlKeys.questionYesNoDescription]: 'Hier hat das Talent die Möglichkeit mit Ja oder mit Nein zu antworten.',
  [IntlKeys.summary]: 'Zusammenfassung',
  [IntlKeys.disqualifyTooltip]:
    'Kanditat:innen die diese Frage nicht beantworten können, werden automatisch disqualifiziert',
  [IntlKeys.disqualify]: 'Disqualifizierende Frage',
  [IntlKeys.typeYourQuestion]: 'Geben Sie eine Frage ein',
  [IntlKeys.addNewOption]: 'Fügen Sie eine Option hinzu',
  [IntlKeys.fullTime]: 'Vollzeit',
  [IntlKeys.partTime]: 'Teilzeit',
  [IntlKeys.workFromOffice]: 'Büro',
  [IntlKeys.hybrid]: 'Hybrid',
  [IntlKeys.remote]: 'Heimarbeit',
  [IntlKeys.workingHours]: 'Arbeitszeit',
  [IntlKeys.employemtType]: 'Arbeitsort',
  [IntlKeys.salary]: 'Gehalt',
  [IntlKeys.location]: 'Standort',
  [IntlKeys.match]: 'Einstellen',
  [IntlKeys.nomatch]: 'Nicht einstellen',
  [IntlKeys.appliedOn]: 'Beworben am:',
  [IntlKeys.gdp]: 'ich stimme den Datenschutzbestimmungen zu!',
  [IntlKeys.yetToWriteRemarks]:
    '{name} hat sich auf hat sich auf {position} beworben mit diesen Gehaltsvorstellungen {minSalary} - {maxSalary}',
  [IntlKeys.jobPreview]: 'Vorschau',
  [IntlKeys.addLinkToIntroVideo]: 'Geben Sie eine Video-Url ein',
  [IntlKeys.qualificationQuestionDescription]:
    'Qualifizierungsfragen werden den Talenten gestellt um an weiter Informationen zu kommen, sie können auch benutzt werden um ihn direkt zu disqualifizieren.',
  [IntlKeys.questionNoAnswer]: 'Bitte beantworte die Frage um fortzufahren',
  [IntlKeys.publishSuccess]:
    'Auftrag wurde erfolgreich veröffentlich und unsere Recruiter machen sich schnellstmöglich an die Arbeit.',
  [IntlKeys.publishFailed]: 'Könnte den Auftrag nicht veröffentlichen. Bitte versuchen Sie es erneut.',
  [IntlKeys.maximumCandidateNeeded]: 'Geben Sie hier ein wie viele Talente wir Ihnen maximal übermitteln sollen.',
  [IntlKeys.enterMaxCandidateNeeded]: 'Wie viele Talente werden benötigt',
  [IntlKeys.minCandidateRequired]: 'Es wird ein Minimum von {count} Talenten benötigt um mit dem Matching zu beginnen.',
  [IntlKeys.jobPagePreviewMessage]:
    'Dies ist nur eine Vorschau der Stellenanzeige. Diese Seite wird für keine Bewerber sichtbar sein. Die Seite ist eine Kopie der tatsächlichen Stellenanzeige zur Überprüfung und gegebenenfalls Änderung.',
  [IntlKeys.jobPagePreviewSubmit]: 'Dies ist nur eine Vorschau. Bewerbungen sind hier nicht möglich.',
  [IntlKeys.applicationSubmitSuccess]: 'Bewerbung wurde abgeschickt',
  [IntlKeys.viewApplications]: 'Bewerbungen ansehen',
  [IntlKeys.jobDuplicate]: 'Auftrag duplizieren',
  [IntlKeys.jobPosition]: 'Position',
  [IntlKeys.jobSalary]: 'Jahresgehalt',

  // job applications
  [IntlKeys.applicationEmptyPlaceholder]: 'Nicht mehr lange, die ersten Talente sind schon auf dem Weg zu Ihnen.',

  // job comments
  [IntlKeys.comments]: 'Job Chat',
  [IntlKeys.commentsDefault]: 'Wie können wir Ihnen weiterhelfen?',
  [IntlKeys.commentsEnter]: 'Geben Sie einen Kommentar ein',
  [IntlKeys.commentsSave]: 'Jetzt posten',

  // Applications
  [IntlKeys.userAcceptedMessage]: '<b>{name}</b> ist geeignet für <b>{job}</b>.',
  [IntlKeys.userDeclinedMessage]: '<b>{name}</b> ist nicht geeignet für <b>{job}</b>.',

  // Job Landing
  [IntlKeys.jobLandingWeAreLookingFor]: 'Wir suchen Dich als',
  [IntlKeys.jobLandingWeAreLookingForWithName]: 'Hallo {name}, wir suchen Dich als',
  [IntlKeys.interestedInRole]: 'Bist du interessiert als {role} zu arbeiten?',
  [IntlKeys.interestedIn]: 'Ich bin interessiert',
  [IntlKeys.jobShareMessage]:
    'Hey! Ich hab hier diese hervorragende Gelegenenheit für dich gefunden. Klicke hier um dich zu bewerben: {url}',
  [IntlKeys.shareThisJob]: 'Teile diesen Job',
  [IntlKeys.copyToClipboard]: 'In die Zwischenablage kopieren',
  [IntlKeys.shareOnWhatsapp]: 'Auf WhatsApp teilen',
  [IntlKeys.allSet]: 'Vielen Dank!',
  [IntlKeys.weWillGetBack]: 'Wir haben alles was wir brauchen und melden uns zeitnah bei dir!',

  // Company
  [IntlKeys.companyUpdatedSuccessfully]: 'Unternehmen erfolgreich geupdated!',
  [IntlKeys.companyName]: 'Unternehmensname',
  [IntlKeys.enterCompanyName]: 'Geben Sie den Namen Ihres Unternehmens ein',
  [IntlKeys.companyLogo]: 'Unternehmenslogo',
  [IntlKeys.companyWebsite]: 'Webseite',
  [IntlKeys.enterWebsite]: 'Webseite eingeben',
  [IntlKeys.linkedin]: 'LinkedIn',
  [IntlKeys.enterLinkedIn]: 'LinkedIn eingeben',
  [IntlKeys.enterXing]: 'Xing eingeben',
  [IntlKeys.xing]: 'Xing',
  [IntlKeys.industry]: 'Industrie',
  [IntlKeys.selectIndustry]: 'Industriezweig auswählen',
  [IntlKeys.headCount]: 'Mitarbeiteranzahl',
  [IntlKeys.foundingDateLabel]: 'Gegründet am',
  [IntlKeys.aboutCompany]: 'Über das Unternehmen',
  [IntlKeys.aboutCompanyHelper]:
    'Diese Informationen werden in der Jobbeschreibung angezeigt, damit die Talente etwas über Ihr Unternehmen erfahren können.',
  [IntlKeys.billingAddress]: 'Rechnungsaddresse',
  [IntlKeys.billingAddressHelper]: 'Die Rechnungsadresse steht auf Ihren Rechnungen.',
  [IntlKeys.companySettings]: 'Unternehmens Einstellungen',
  [IntlKeys.companySubscriptions]: 'Abonnements',
  [IntlKeys.companyUsers]: 'Team',
  [IntlKeys.companyCustomization]: 'Personalisierung',
  [IntlKeys.enterCompanyStreet]: 'Straße eingeben',
  [IntlKeys.enterCompanyStreetNumber]: 'Hausnummer eingeben',
  [IntlKeys.enterCompanyCity]: 'Stadt eingeben',
  [IntlKeys.enterCompanyPostcode]: 'Postleitzahl eingeben',
  [IntlKeys.enterCompanyCountry]: 'Land eingeben',

  // Settings
  [IntlKeys.passwordUpdatedSuccessfully]: 'Passwort erfolgreich geupdated!',
  [IntlKeys.failedToUploadPassword]: 'Konnte Passwort nicht updaten. Bitte versuchen Sie es erneut.',
  [IntlKeys.oldPassword]: 'Altes Passwort',
  [IntlKeys.enterOldPassword]: 'Geben Sie Ihr altes Passwort ein',
  [IntlKeys.enterNewPassword]: 'Geben Sie ein neues Passwort ein',
  [IntlKeys.confirmPassword]: 'Passwort bestätigen',
  [IntlKeys.enterConfirmPassword]: 'Geben Sie das Passwort erneut ein',
  [IntlKeys.profileUpdatedSuccessfully]: 'Profil erfolgreich geupdated!',
  [IntlKeys.avatar]: 'Profilbild',
  [IntlKeys.language]: 'Sprache',
  [IntlKeys.descriptionLanguage]: 'Sprache der Beschreibung',
  [IntlKeys.selectLanguage]: 'Wählen Sie eine Sprache',
  [IntlKeys.profileSettings]: 'Profil Einstellungen',
  [IntlKeys.accountSettings]: 'Account Einstellungen',

  // Credits
  [IntlKeys.credits]: 'Credits',
  [IntlKeys.currentCredits]: 'Aktuelle Credits',
  [IntlKeys.pendingCredits]: 'Noch zu bezahlende Credits',
  [IntlKeys.totalCredits]: 'Gesamte Credits',
  [IntlKeys.invoicesHeading]: 'Rechnungen',
  [IntlKeys.invoiceTitle]: 'Rechnung',
  [IntlKeys.invoicePaid]: 'Bezahlt',
  [IntlKeys.invoiceNotPaid]: 'Nicht bezahlt',
  [IntlKeys.invoiceHasStorno]: 'Storniert',
  [IntlKeys.noInvoicesAvailable]: 'Keine Rechnungen verfügbar',
  [IntlKeys.reservedCredits]: 'Reservierte Credits',
  [IntlKeys.yetToSpendCredits]: 'Verfügbare Credits',
  [IntlKeys.creditErrorLoadPrice]: 'Konnte Creditpreis nicht laden. Bitte versuchen Sie es erneut!',
  [IntlKeys.creditStartRecruiting]: 'Noch ein Klick um den Auftrag abzuschicken',
  [IntlKeys.creditTooLess]:
    'Sie haben aktuell nur {currentAmount} Credits. Bitte kaufen Sie {creditsNeeded} mehr um den Auftrag zu erteilen',
  [IntlKeys.creditSelectAmountHead]:
    'Bitte wählen Sie eine der folgenden Optionen um mehr Credits zu erwerben. Wir stellen Ihnen eine Rechnung und werden sobald der Auftrag erteilt wurde mit dem Recruitment beginnen.',
  [IntlKeys.creditPerCreditPrice]: 'Preis pro Credit',
  [IntlKeys.creditPriceTotal]: 'Gesamt',
  [IntlKeys.creditsIAgree]: 'Ich bin einverstanden mit den',
  [IntlKeys.termsConditions]: 'Geschäftsbedingungen',
  [IntlKeys.termsConditionsError]: 'Bitte akzeptieren Sie die Geschäftsbedingungen um die Credits zu erwerben',
  [IntlKeys.creditsSelectPlan]: 'Bitte wählen Sie ein Abonnement',
};
export default translations;
