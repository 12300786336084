import { Card, FlexBox } from 'components/base';
import { Wrapper } from 'components/Layout';
import { creditResourceSelector } from 'domain/credits/selectors';
import { IntlKeys } from 'localization';
import Invoices from './Invoices';
//
import React from 'react';
import { IoDiamondSharp } from 'react-icons/io5';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { theme } from 'theme';

const Credits = () => {
  const credits = useSelector(creditResourceSelector);
  const { formatMessage } = useIntl();

  return (
    <CreditsContainer>
      <CreditsCard>
        <CardHeader align="center" justify="center">
          <Crown>
            <IoDiamondSharp />
          </Crown>
        </CardHeader>
        <CardContent>
          <EachContent>
            <Value>{credits?.currentAmount}</Value>
            <Label>{formatMessage({ id: IntlKeys.currentCredits })}</Label>
          </EachContent>
          <EachContent className="middle">
            <Value>{credits?.reservedAmount}</Value>
            <Label>{formatMessage({ id: IntlKeys.reservedCredits })}</Label>
          </EachContent>
          <EachContent>
            <Value>{credits ? credits.currentAmount - credits.reservedAmount : 0}</Value>
            <Label>{formatMessage({ id: IntlKeys.yetToSpendCredits })}</Label>
          </EachContent>
        </CardContent>
      </CreditsCard>
      <Invoices />
    </CreditsContainer>
  );
};

export default Credits;

const CreditsContainer = styled(Wrapper)`
  max-width: 800px;
`;

const CreditsCard = styled(Card)`
  background-color: ${theme.colors.mintGreen};
  position: relative;
  margin-top: 6rem;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    margin-top: 4.5rem;
  }
`;

const CardHeader = styled(FlexBox)`
  position: absolute;
  top: -30px;
  width: 100%;
  left: 0;
  right: 0;
`;

const Crown = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background-color: ${theme.colors.mintGreen};
  border-radius: 100%;
  font-size: 1.5rem;
  filter: brightness(1.1);

  svg {
    fill: ${theme.colors.primary};
  }
`;

const CardContent = styled.div`
  display: flex;
  margin-top: 1rem;
  padding: 1rem 0rem;
`;

const EachContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.middle {
    border-left: 1px solid ${theme.colors.gray};
    border-right: 1px solid ${theme.colors.gray};
  }
`;

const Value = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0rem;
  font-weight: 500;
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.victorSerif};

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 1rem;
  }
`;

const Label = styled.span`
  color: ${theme.colors.secondary};

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 0.8rem;
  }
`;
