import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
// types
import {
  ApplicationList,
  ApplicationPayloadType,
  Application,
  QualificationQuestionAnswerPayload,
  UpdateApplicationPayloadType,
  DownloadCandidatesType,
} from 'types/application';
//
import { restClient } from 'helpers/http';
import { Params } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { CandidateType } from 'types/candidates';
//

export const fetchApplicationsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<ApplicationList>({
    api: restClient.getApplications,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const downloadCandidatesAction = createAsyncThunk<Record<string, string>, DownloadCandidatesType>(
  'applications/downloadCandidatesAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Record<string, string>>({
      api: restClient.downloadCandidates,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const getApplicationsAction = createAsyncThunk<ApplicationList, Params | undefined>(
  'applications/getApplicationsAction',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchApplicationsWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getApplicationDetailsAction = createAsyncThunk<Application, Params>(
  'applications/getApplicationDetailsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Application>({
      api: restClient.getApplicationDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

type NetworkErrorType = {
  data: AxiosError;
  requestPayload: Params;
};

export const createApplicatioAction = createAsyncThunk<Application, ApplicationPayloadType>(
  'applications/createApplicatioAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Application>({
      api: restClient.createApplication,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const updateApplicationAction = createAsyncThunk<Application, UpdateApplicationPayloadType & Params>(
  'applications/updateApplicationAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Application>({
      api: restClient.updateApplication,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const submitAnswerAction = createAsyncThunk<unknown, QualificationQuestionAnswerPayload & Params>(
  'applications/submitAnswerAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<unknown>({
      api: restClient.sumbitAnswer,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);
