/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormProvider, FieldValues, useWatch, useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';
import { isEqual } from 'lodash';
import { MdDeleteForever } from 'react-icons/md';
//
import InputWrapper from 'components/hook-form/components/InputWrapper';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'domain/store';
import { Gallery } from 'types/job';
import ColorPicker from 'components/ColorPicker';
import { IntlKeys } from 'localization';
import { theme } from 'theme';
import ImageDropzone from 'components/Dropzone/ImageDropzone';
import VideoDropzone from 'components/Dropzone/VideoDropzone';
import { getCustomizationValuesFromCompanyDetails, uploadGalleryImages, uploadMedia, MediaFileObj } from './helpers';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { FlexBox, SubmitButton } from 'components/base';
import { companyResourceSelector } from 'domain/company/selectors';
import { CompanyCustomizationPayloadType } from 'types/company';
import { getCompanyDetailsAction, updateCompanyAction } from 'domain/company/actions';
import { toast } from 'react-toastify';
import FormRadioSwitch from 'components/hook-form/fields/FormRadioSwitch';
import { Divider } from '@mui/material';

const CompanyCustomization = () => {
  const companyDetails = useSelector(companyResourceSelector);
  const [customizationDefaultValues, setCustomizationDefaultValues] = useState<CompanyCustomizationPayloadType>(
    getCustomizationValuesFromCompanyDetails(companyDetails),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  type InternalMedias = {
    companyJobCover?: Record<string, any>;
    companyJobVideo?: Record<string, any>;
    companyJobGalleryObj?: Record<string, any>[] | Gallery[];
    companyJobGalleryUrls: string[];
    jobCoverType: 'image' | 'video';
  };

  const form = useForm<CompanyCustomizationPayloadType & InternalMedias>({
    defaultValues: {
      ...getCustomizationValuesFromCompanyDetails(companyDetails),
      jobCoverType: 'image',
    },
  });

  const jobCoverType = useWatch({ name: 'jobCoverType', control: form.control });
  const jobCoverValue = useWatch({ name: 'companyJobCover', control: form.control });
  const introVideo = useWatch({ name: 'defaultVideo', control: form.control });
  const jobGalleryUrls = useWatch({
    name: 'companyJobGalleryUrls',
    control: form.control,
  }) as string[];
  const jobGalleryObjs = useWatch({ name: 'companyJobGalleryObj', control: form.control });
  const uploadedGallery: Gallery[] = useWatch({ name: 'defaultGallery', control: form.control });

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCompanyDetailsAction());
  }, [dispatch]);

  useEffect(() => {
    if (companyDetails) {
      const defaultValues = getCustomizationValuesFromCompanyDetails(companyDetails);
      setCustomizationDefaultValues(defaultValues);
      form.reset({ ...defaultValues });
    }
  }, [companyDetails, form]);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      setIsSubmitting(true);
      const [cover, gallery] = await Promise.all([
        uploadMedia(data.companyJobCover, customizationDefaultValues?.defaultCover),
        // uploadIntroVideo(data.introVideo, jobDetails?.video),
        uploadGalleryImages(data.companyJobGalleryObj),
      ]);

      const payload = {
        defaultColor: data.defaultColor,
        defaultCover: cover || null,
        defaultVideo: data.defaultVideo?.url ? data.defaultVideo : null,
        defaultGallery: [...(data.defaultGallery || []), ...gallery?.map((file) => ({ ...file, type: 'image' }))],
      } as CompanyCustomizationPayloadType;

      setTimeout(() => {
        // TODO: the header changes in the uploadFile actions are getting cached and making this call return CORS error.
        dispatch(updateCompanyAction(payload))
          .unwrap()
          .then(() => {
            toast.success(formatMessage({ id: IntlKeys.companyUpdatedSuccessfully }));
            setIsSubmitting(false);
            dispatch(getCompanyDetailsAction());
          })
          .catch(() => {
            setIsSubmitting(false);
          });
      }, 500);
    },
    [customizationDefaultValues?.defaultCover, dispatch, formatMessage],
  );

  const handleJobCoverUpload = useCallback(
    (jobCoverMedia: Record<string, any>) => {
      form.setValue('companyJobCover', jobCoverMedia);
    },
    [form],
  );

  const removeCover = () => {
    form.setValue('companyJobCover', undefined);

    setCustomizationDefaultValues({ ...customizationDefaultValues, defaultCover: undefined });
  };

  const removeGalleryImage = (image?: string) => {
    if (!image) return;
    const updatedGallery = uploadedGallery.filter((gallery) => gallery.url !== image) || [];
    const newUploadedGalleryObjs = jobGalleryObjs?.filter((img) => img.src !== image) as MediaFileObj[];

    if (!isEqual(newUploadedGalleryObjs, jobGalleryObjs)) {
      form.setValue('companyJobGalleryObj', newUploadedGalleryObjs);
      form.setValue('companyJobGalleryUrls', newUploadedGalleryObjs?.map((file) => file?.src) || []);
    }

    if (updatedGallery.length !== uploadedGallery?.length) {
      form.setValue('defaultGallery', updatedGallery);
    }
  };

  const handleGalleryUpdate = (galleryFiles: File[]) => {
    const galleryObjects = [
      ...(form.getValues('companyJobGalleryObj') || []),
      ...galleryFiles.map((file) => ({
        fileObj: file,
        src: URL.createObjectURL(file),
        name: file.name,
      })),
    ] as MediaFileObj[];
    form.setValue('companyJobGalleryObj', galleryObjects);
    form.setValue(
      'companyJobGalleryUrls',
      galleryObjects.map((file) => file?.src),
    );
  };

  const jobCoverOptions = [
    {
      label: formatMessage({ id: IntlKeys.image }),
      id: 'image',
      value: 'image',
    },
    {
      label: formatMessage({ id: IntlKeys.video }),
      id: 'video',
      value: 'video',
    },
  ];

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <FormContainer>
          <EachInput label={formatMessage({ id: IntlKeys.jobCover })}>
            <FormRadioSwitch name="jobCoverType" options={jobCoverOptions} />
            <Divider />
            {jobCoverType === 'image' ? (
              <ImageDropzone
                onDrop={(acceptedFiles) =>
                  handleJobCoverUpload({
                    fileObj: acceptedFiles[0],
                    src: URL.createObjectURL(acceptedFiles[0]),
                    name: acceptedFiles[0].name,
                  })
                }
                maxFiles={1}
                height={200}
                src={customizationDefaultValues?.defaultCover?.url || jobCoverValue?.src}
                multiple={false}
                previewClassName={'job-cover-preview'}
                onRemove={removeCover}
                cropConfig={{ aspectRatio: 1.1 }}
              />
            ) : (
              <VideoDropzone
                onDrop={(acceptedFiles) =>
                  handleJobCoverUpload({
                    fileObj: acceptedFiles[0],
                    src: URL.createObjectURL(acceptedFiles[0]),
                    name: acceptedFiles[0].name,
                  })
                }
                maxFiles={1}
                height={200}
                src={customizationDefaultValues?.defaultCover?.url || jobCoverValue?.src}
                multiple={false}
                previewClassName={'job-cover-preview'}
              />
            )}
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.introVideo })}>
            {/* <VideoDropzone
              onDrop={(acceptedFiles) =>
                handleIntroVideoUpload({
                  fileObj: acceptedFiles[0],
                  src: URL.createObjectURL(acceptedFiles[0]),
                  name: acceptedFiles[0].name,
                })
              }
              maxFiles={1}
              height={200}
              src={jobIntroVideoValue?.src}
              multiple={false}
            /> */}

            <FormTextField
              name={'defaultVideo.url'}
              placeholder={formatMessage({ id: IntlKeys.addLinkToIntroVideo })}
            />

            {introVideo ? (
              <VideoWrapper>
                <ReactPlayer url={introVideo?.url} width={'100%'} wrapper={VideoPlayer} />
              </VideoWrapper>
            ) : null}
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.gallery })}>
            {[...(uploadedGallery || []), ...(jobGalleryUrls || [])]?.[0] ? (
              <GalleryContainer>
                {[...(uploadedGallery?.map((gallary) => gallary.url) || []), ...(jobGalleryUrls || [])].map(
                  (image: string, index: number) => (
                    <GalleryEachItem key={`${image}-${index}`}>
                      <RemoveContainer onClick={() => removeGalleryImage(image)} className={'remove-image'}>
                        <MdDeleteForever />
                      </RemoveContainer>
                      <Image src={image} />
                    </GalleryEachItem>
                  ),
                )}
                <GalleryEachItem>
                  <ImageDropzone
                    onDrop={(acceptedFiles) => handleGalleryUpdate(acceptedFiles)}
                    maxFiles={5}
                    height={200}
                    multiple={true}
                  />
                </GalleryEachItem>
              </GalleryContainer>
            ) : (
              <ImageDropzone
                onDrop={(acceptedFiles) => handleGalleryUpdate(acceptedFiles)}
                maxFiles={5}
                height={200}
                multiple={true}
              />
            )}
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.customColor })}>
            <ColorPicker name="defaultColor" />
          </EachInput>
        </FormContainer>
        <Footer>
          <ButtonContainer align="center" justify={'flex-end'}>
            <SubmitButton type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
              {formatMessage({ id: IntlKeys.save })}
            </SubmitButton>
          </ButtonContainer>
        </Footer>
      </Form>
    </FormProvider>
  );
};

export default CompanyCustomization;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;

  .job-cover-preview {
    border-radius: 20px;

    img {
      border-radius: 20px;
      max-width: 100%;
    }
  }
`;

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  max-width: 100%;
  gap: 0.5rem;
`;

const Image = styled.img`
  max-width: 100%;
`;

const GalleryEachItem = styled.div`
  max-width: 100%;
  height: 240px;
  background-color: ${theme.colors.lightGray};
  border-radius: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .remove-image {
    display: none;
  }

  &:hover {
    .remove-image {
      display: flex;
    }
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 2rem 0rem;
  overflow: hidden;
`;

const VideoPlayer = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;

const RemoveContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  /* padding: 1rem; */
  border-radius: 30px;
  background: ${theme.colors.maroon};
  width: 36px;
  height: 36px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.colors.white};
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 64px;
  border-top: 1px solid ${theme.colors.stroke};
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${theme.colors.white};
`;

const ButtonContainer = styled(FlexBox)`
  max-width: 800px;
  margin: auto;
  align-items: center;
  padding: 0rem 1rem;
  height: 100%;

  > button {
    padding: 0.5rem 1.5rem;
  }
`;

const FormContainer = styled.div`
  max-width: 800px;
  padding-bottom: 64px;
`;
