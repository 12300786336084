import React, { MouseEvent, useCallback, useState } from 'react';
import Picker from '@emoji-mart/react';
import { TextField, IconButton, Popover } from '@mui/material';
import { GrClose, GrDown } from 'react-icons/gr';
import data, { Skin } from '@emoji-mart/data';
import { BenefitPayloadType } from 'types/benefits';
import styled from 'styled-components';
import { Button } from 'components/base';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import { MdAdd } from 'react-icons/md';

interface OwnProps {
  onSave: (benefit: BenefitPayloadType) => void;
}

const CreateBenefitField: React.FC<OwnProps> = ({ onSave }) => {
  const [emoji, onSelectEmoji] = useState<string | null>(null);
  const [newBenefit, setNewBenefit] = useState<string | null>(null);
  const { formatMessage } = useIntl();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLOrSVGElement | null>(null);

  const onSubmit = () => {
    if (newBenefit && emoji) {
      onSave({ name: newBenefit, icon: emoji });
      setNewBenefit(null);
      onSelectEmoji(null);
    } else if (newBenefit) {
      onSave({ name: newBenefit });
      setNewBenefit(null);
      onSelectEmoji(null);
    }
  };
  const handleEmojiClick = useCallback(
    (thisEmoji: Skin) => {
      onSelectEmoji(emoji ? emoji + thisEmoji.native : thisEmoji.native);
      setAnchorEl(null);
    },
    [emoji],
  );

  const clearValue = useCallback(
    (e: MouseEvent<HTMLDivElement | HTMLOrSVGElement>) => {
      e.stopPropagation();
      onSelectEmoji(null);
    },
    [onSelectEmoji],
  );

  const openPopover = useCallback(
    (e: MouseEvent<HTMLDivElement | HTMLOrSVGElement>) => setAnchorEl(e.currentTarget),
    [],
  );
  const closePopover = useCallback(() => setAnchorEl(null), []);

  return (
    <BenefitForm>
      <BenefitField
        focused={!!anchorEl}
        value={emoji || ''}
        placeholder={formatMessage({ id: IntlKeys.emojiSelect })}
        InputProps={{
          readOnly: true,
          endAdornment: emoji ? (
            <>
              <IconButton onClick={clearValue}>
                <GrClose size={16} />
              </IconButton>
              <IconButton onClick={openPopover}>
                <GrDown size={16} />
              </IconButton>
            </>
          ) : (
            <GrDown onClick={openPopover} />
          ),
        }}
      />
      <TextField
        placeholder={formatMessage({ id: IntlKeys.benefits })}
        value={newBenefit || ''}
        onChange={(e) => setNewBenefit(e.target.value)}
      />
      <Button variant="outline" onClick={onSubmit}>
        <MdAdd /> {formatMessage({ id: IntlKeys.add })}
      </Button>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl as HTMLElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={closePopover}
      >
        <Picker data={data} onEmojiSelect={handleEmojiClick} />
      </Popover>
    </BenefitForm>
  );
};

export default CreateBenefitField;

const BenefitForm = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
const BenefitField = styled(TextField)`
  min-width: 100px;
`;
