export const CountryEnum = {
  Deutschland: 'DE',
  Österreich: 'AT',
  Schweiz: 'CH',
  Afghanistan: 'AF',
  Ägypten: 'EG',
  Aland: 'AX',
  Albanien: 'AL',
  Algerien: 'DZ',
  'Amerikanisch-Samoa': 'AS',
  'Amerikanische Jungferninseln': 'VI',
  Andorra: 'AD',
  Angola: 'AO',
  Anguilla: 'AI',
  Antarktis: 'AQ',
  'Antigua und Barbuda': 'AG',
  Äquatorialguinea: 'GQ',
  Argentinien: 'AR',
  Armenien: 'AM',
  Aruba: 'AW',
  Ascension: 'AC',
  Aserbaidschan: 'AZ',
  Äthiopien: 'ET',
  Australien: 'AU',
  Bahamas: 'BS',
  Bahrain: 'BH',
  Bangladesch: 'BD',
  Barbados: 'BB',
  Belgien: 'BE',
  Belize: 'BZ',
  Benin: 'BJ',
  Bermuda: 'BM',
  Bhutan: 'BT',
  Bolivien: 'BO',
  'Bosnien und Herzegowina': 'BA',
  Botswana: 'BW',
  Bouvetinsel: 'BV',
  Brasilien: 'BR',
  Brunei: 'BN',
  Bulgarien: 'BG',
  'Burkina Faso': 'BF',
  Burundi: 'BI',
  Chile: 'CL',
  China: 'CN',
  Cookinseln: 'CK',
  'Costa Rica': 'CR',
  "Cote d'Ivoire": 'CI',
  Dänemark: 'DK',
  'Diego Garcia': 'DG',
  Dominica: 'DM',
  'Dominikanische Republik': 'DO',
  Dschibuti: 'DJ',
  Ecuador: 'EC',
  'El Salvador': 'SV',
  Eritrea: 'ER',
  Estland: 'EE',
  'Europäische Union': 'EU',
  Falklandinseln: 'FK',
  Färöer: 'FO',
  Fidschi: 'FJ',
  Finnland: 'FI',
  Frankreich: 'FR',
  'Französisch-Guayana': 'GF',
  'Französisch-Polynesien': 'PF',
  Gabun: 'GA',
  Gambia: 'GM',
  Georgien: 'GE',
  Ghana: 'GH',
  Gibraltar: 'GI',
  Grenada: 'GD',
  Griechenland: 'GR',
  Grönland: 'GL',
  Großbritannien: 'GB',
  Guadeloupe: 'CP',
  Guam: 'GU',
  Guatemala: 'GT',
  Guernsey: 'GG',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  Guyana: 'GY',
  Haiti: 'HT',
  'Heard und McDonaldinseln': 'HM',
  Honduras: 'HN',
  Hongkong: 'HK',
  Indien: 'IN',
  Indonesien: 'ID',
  Irak: 'IQ',
  Iran: 'IR',
  Irland: 'IE',
  Island: 'IS',
  Israel: 'IL',
  Italien: 'IT',
  Jamaika: 'JM',
  Japan: 'JP',
  Jemen: 'YE',
  Jersey: 'JE',
  Jordanien: 'JO',
  Kaimaninseln: 'KY',
  Kambodscha: 'KH',
  Kamerun: 'CM',
  Kanada: 'CA',
  'Kanarische Inseln': 'IC',
  'Kap Verde': 'CV',
  Kasachstan: 'KZ',
  Katar: 'QA',
  Kenia: 'KE',
  Kirgisistan: 'KG',
  Kiribati: 'KI',
  Kokosinseln: 'CC',
  Kolumbien: 'CO',
  Komoren: 'KM',
  Kongo: 'CG',
  Kroatien: 'HR',
  Kuba: 'CU',
  Kuwait: 'KW',
  Laos: 'LA',
  Lesotho: 'LS',
  Lettland: 'LV',
  Libanon: 'LB',
  Liberia: 'LR',
  Libyen: 'LY',
  Liechtenstein: 'LI',
  Litauen: 'LT',
  Luxemburg: 'LU',
  Macao: 'MO',
  Madagaskar: 'MG',
  Malawi: 'MW',
  Malaysia: 'MY',
  Malediven: 'MV',
  Mali: 'ML',
  Malta: 'MT',
  Marokko: 'MA',
  Marshallinseln: 'MH',
  Martinique: 'MQ',
  Mauretanien: 'MR',
  Mauritius: 'MU',
  Mayotte: 'YT',
  Mazedonien: 'MK',
  Mexiko: 'MX',
  Mikronesien: 'FM',
  Moldawien: 'MD',
  Monaco: 'MC',
  Mongolei: 'MN',
  Montserrat: 'MS',
  Mosambik: 'MZ',
  Myanmar: 'MM',
  Namibia: 'NA',
  Nauru: 'NR',
  Nepal: 'NP',
  Neukaledonien: 'NC',
  Neuseeland: 'NZ',
  'Neutrale Zone': 'NT',
  Nicaragua: 'NI',
  Niederlande: 'NL',
  'Niederländische Antillen': 'AN',
  Niger: 'NE',
  Nigeria: 'NG',
  Niue: 'NU',
  Nordkorea: 'KP',
  'Nördliche Marianen': 'MP',
  Norfolkinsel: 'NF',
  Norwegen: 'NO',
  Oman: 'OM',
  Pakistan: 'PK',
  Palästina: 'PS',
  Palau: 'PW',
  Panama: 'PA',
  'Papua-Neuguinea': 'PG',
  Paraguay: 'PY',
  Peru: 'PE',
  Philippinen: 'PH',
  Pitcairninseln: 'PN',
  Polen: 'PL',
  Portugal: 'PT',
  'Puerto Rico': 'PR',
  Réunion: 'RE',
  Ruanda: 'RW',
  Rumänien: 'RO',
  'Russische Föderation': 'RU',
  Salomonen: 'SB',
  Sambia: 'ZM',
  Samoa: 'WS',
  'San Marino': 'SM',
  'São Tomé und Príncipe': 'ST',
  'Saudi-Arabien': 'SA',
  Schweden: 'SE',
  Senegal: 'SN',
  'Serbien und Montenegro': 'CS',
  Seychellen: 'SC',
  'Sierra Leone': 'SL',
  Simbabwe: 'ZW',
  Singapur: 'SG',
  Slowakei: 'SK',
  Slowenien: 'SI',
  Somalia: 'SO',
  Spanien: 'ES',
  'Sri Lanka': 'LK',
  'St. Helena': 'SH',
  'St. Kitts und Nevis': 'KN',
  'St. Lucia': 'LC',
  'St. Pierre und Miquelon': 'PM',
  'St. Vincent/Grenadinen (GB)': 'VC',
  'Südafrika, Republik': 'ZA',
  Sudan: 'SD',
  Südkorea: 'KR',
  Suriname: 'SR',
  'Svalbard und Jan Mayen': 'SJ',
  Swasiland: 'SZ',
  Syrien: 'SY',
  Tadschikistan: 'TJ',
  Taiwan: 'TW',
  Tansania: 'TZ',
  Thailand: 'TH',
  'Timor-Leste': 'TL',
  Togo: 'TG',
  Tokelau: 'TK',
  Tonga: 'TO',
  'Trinidad und Tobago': 'TT',
  'Tristan da Cunha': 'TA',
  Tschad: 'TD',
  'Tschechische Republik': 'CZ',
  Tunesien: 'TN',
  Türkei: 'TR',
  Turkmenistan: 'TM',
  'Turks- und Caicosinseln': 'TC',
  Tuvalu: 'TV',
  Uganda: 'UG',
  Ukraine: 'UA',
  Ungarn: 'HU',
  Uruguay: 'UY',
  Usbekistan: 'UZ',
  Vanuatu: 'VU',
  Vatikanstadt: 'VA',
  Venezuela: 'VE',
  'Vereinigte Arabische Emirate': 'AE',
  'Vereinigte Staaten von Amerika': 'US',
  Vietnam: 'VN',
  'Wallis und Futuna': 'WF',
  Weihnachtsinsel: 'CX',
  Weißrussland: 'BY',
  Westsahara: 'EH',
  'Zentralafrikanische Republik': 'CF',
  Zypern: 'CY',
};
