import { Wrapper } from 'components/Layout';
import React from 'react';
import styled from 'styled-components';
import ApplicationsList from './components/ApplicationsList';

const Applications = () => {
  return (
    <Container>
      <ApplicationsList />
    </Container>
  );
};

export default Applications;

const Container = styled(Wrapper)`
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(min(380px, 100%), 1fr));
  }
`;
