import { Checkbox, CheckboxProps } from '@mui/material';
import { useFieldErrors } from 'helpers/forms/extractErrorProps';
import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface OwnProps extends CheckboxProps {
  name: string;
  onChangeExternal?: (value: boolean) => void;
}

const FormCheckBox: React.FC<OwnProps> = ({ name, onChangeExternal, ...props }) => {
  const { control } = useFormContext();
  const fieldErrorProps = useFieldErrors(name);

  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(checked);
      onChangeExternal?.(checked);
    },
    [onChange, onChangeExternal],
  );

  return <Checkbox {...props} onChange={handleChange} />;
};

export default FormCheckBox;
