import { getCompanyDetailsAction } from 'domain/company/actions';
import { companyResourceSelector } from 'domain/company/selectors';
import { getJobDetailsAction } from 'domain/jobs/actions';
import { jobsResourceSelector } from 'domain/jobs/selectors';
import { useAppDispatch } from 'domain/store';
import { IntlKeys } from 'localization';
import JobLandingPage from 'pages/JobDescription/components/JobLandingPage';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { theme } from 'theme';
import { JobType } from 'types/job';

interface OwnProps {}

const JobDescriptionPreview: React.FC<OwnProps> = () => {
  const dispatch = useAppDispatch();
  const { id: jobId } = useParams();
  const jobDetails = useSelector(jobsResourceSelector);
  const companyDetails = useSelector(companyResourceSelector);
  const { formatMessage } = useIntl();

  const [publicJob, setPublicjJob] = useState<JobType | null>(null);

  useEffect(() => {
    setPublicjJob({ ...(jobDetails as JobType), company: companyDetails });
  }, [companyDetails, jobDetails]);

  useEffect(() => {
    dispatch(getCompanyDetailsAction());
  }, []);

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetailsAction({ params: { id: jobId! } }));
    }
  }, [jobId]);

  if (!jobDetails) return null;

  return (
    <Container>
      <PreviewNotice>{formatMessage({ id: IntlKeys.jobPagePreviewMessage })}</PreviewNotice>
      <JobLandingPage jobDetails={publicJob!} isPreview />
    </Container>
  );
};

export default JobDescriptionPreview;

const Container = styled.section``;

const PreviewNotice = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${theme.colors.dark};
  color: ${theme.colors.white};
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
`;
