import React from 'react';
import Slider, { Settings } from 'react-slick';
//
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ children, ...props }: Settings) => {
  const settings = {
    dots: true,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: true,
    ...props,
  };
  return (
    // <RcCarousal swipeable showStatus={false} className={`${className} tf-carousal`} {...props}>
    //   {children}
    // </RcCarousal>

    <Slider {...settings}>{children}</Slider>
  );
};

export default Carousel;
