import * as React from 'react';
import styled from 'styled-components';
import { DropzoneProps, useDropzone } from 'react-dropzone';

//
import uploadIcon from 'assets/icons/cloud-add.svg';
import { FlexBox, Text } from 'components/base';
import { allMimeTypesArray } from './entities';
import { theme } from 'theme';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';

export interface CustomDropzoneProps extends Omit<DropzoneProps, 'children'> {
  width?: string | number;
  height?: string | number;
}

const Dropzone: React.FC<CustomDropzoneProps> = ({ accept = allMimeTypesArray, ...dropZoneProps }) => {
  const { getRootProps, getInputProps } = useDropzone({ accept, ...dropZoneProps });
  const { formatMessage } = useIntl();

  return (
    <DropzoneContainer {...getRootProps()} direction="column" align="center" justify="center">
      <input {...getInputProps()} />
      <Image src={uploadIcon} />
      <Text>{formatMessage({ id: IntlKeys.dragAndDropOrChoose })}</Text>
    </DropzoneContainer>
  );
};

export default Dropzone;

const DropzoneContainer = styled(FlexBox)`
  padding: 2rem 1rem;
  width: ${(props: Partial<CustomDropzoneProps>) => props.width || '100%'};
  height: ${(props: Partial<CustomDropzoneProps>) => props.height || '100%'};
  background-color: ${theme.colors.lightGray};
  border-radius: 4px;

  p {
    max-width: 100%;
    overflow: hidden;
  }

  &:hover {
    border-radius: 30px;
    cursor: pointer;
  }
`;

const Image = styled.img``;
