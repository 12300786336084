/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlexBox, SubmitButton, TextEditor, Title3 } from 'components/base';
import ImageDropzone from 'components/Dropzone/ImageDropzone';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import FormDatePicker from 'components/hook-form/fields/FormDatePicker';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { Loader } from 'components/loader';
import { getCompanyDetailsAction, updateCompanyAction } from 'domain/company/actions';
import { companyMetaSelector, companyResourceSelector } from 'domain/company/selectors';
import { useAppDispatch } from 'domain/store';
import { CountryEnum } from 'helpers/countryEnum';
import { IntlKeys } from 'localization';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { CompanyType } from 'types/company';
import { industriesOptions, uploadMedia } from './helpers';
import FormRadioSwitch from 'components/hook-form/fields/FormRadioSwitch';

const CompanySettings = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const companyDetails = useSelector(companyResourceSelector);
  const { isLoading: isCompanyDetailsLoading } = useSelector(companyMetaSelector);
  const form = useForm<
    CompanyType & {
      logoObj?: Record<string, any> | null;
      descriptionLanguage: 'de-DE' | 'en-US';
      descriptionTextDe: string;
      descriptionTextEn: string;
    }
  >({ defaultValues: companyDetails || {} });
  const descriptionLanguage = useWatch({ name: 'descriptionLanguage', control: form.control });
  const logoObj = useWatch({ name: 'logoObj', control: form.control });
  const [isSubitting, setIsSubitting] = useState(false);

  const countryOptions = Object.keys(CountryEnum).map((key) => ({
    label: key,
    value: CountryEnum[key as keyof typeof CountryEnum],
  }));

  useEffect(() => {
    if (companyDetails) {
      const description = companyDetails.description;
      const descriptionTextDe = description ? description['de-DE'] : '';
      const descriptionTextEn = description ? description['en-US'] : '';
      form.reset({
        ...companyDetails,
        descriptionLanguage: 'de-DE',
        descriptionTextDe,
        descriptionTextEn,
      });
    } else {
      dispatch(getCompanyDetailsAction());
    }
  }, [companyDetails, dispatch, form]);

  const onSubmit = useCallback(
    async ({ logoObj, descriptionTextDe, descriptionTextEn, description, ...data }: FieldValues) => {
      setIsSubitting(true);
      const [logo] = await Promise.all([uploadMedia(logoObj, data.logo)]);
      const newDescription = {
        'de-DE': descriptionTextDe,
        'en-US': descriptionTextEn,
      };
      dispatch(updateCompanyAction({ ...data, description: newDescription, logo } as unknown as CompanyType))
        .unwrap()
        .then(() => {
          toast.success(formatMessage({ id: IntlKeys.companyUpdatedSuccessfully }));
          setIsSubitting(false);
        })
        .catch(() => {
          toast.error('Error updating the company details');
          setIsSubitting(false);
        });
    },
    [dispatch, formatMessage],
  );

  const headCountOptions = ['1 - 10', '10 - 100', '100 - 1000', '1000+', '20,000+'];

  const changeDescriptionLanguage = (value: string) => {
    const thisDescription = form.getValues('description') || {};
    if (value === 'de-DE') {
      thisDescription['en-US'] = form.getValues('descriptionTextEn');
      form.setValue('descriptionLanguage', 'de-DE');
    } else {
      thisDescription['de-DE'] = form.getValues('descriptionTextDe');
      form.setValue('descriptionLanguage', 'en-US');
    }
    form.setValue('description', thisDescription);
  };

  const handleLogoUpload = useCallback(
    (logoObj: Record<string, any>) => {
      form.setValue('logoObj', logoObj);
    },
    [form],
  );

  const removeLogo = () => {
    form.setValue('logoObj', null);
    form.setValue('logo', null);
  };

  if (isCompanyDetailsLoading) {
    return <Loader />;
  }

  const languagesOptions = [
    {
      value: 'de-DE',
      label: '🇩🇪 Deutsch',
    },
    {
      value: 'en-US',
      label: '🇬🇧 English',
    },
  ];

  return (
    <SettingsContainer>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <FormContainer>
            <EachInput label={formatMessage({ id: IntlKeys.companyName })}>
              <FormTextField
                name={'name'}
                placeholder={formatMessage({ id: IntlKeys.enterCompanyName })}
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
              />
            </EachInput>
            <AddressLineWrapper>
              <EachInput label={formatMessage({ id: IntlKeys.addressStreet })}>
                <FormTextField
                  name={'address.street'}
                  placeholder={formatMessage({ id: IntlKeys.enterCompanyStreet })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
              <EachInput label={formatMessage({ id: IntlKeys.addressHouseNumber })}>
                <FormTextField
                  name={'address.housenumber'}
                  placeholder={formatMessage({ id: IntlKeys.enterCompanyStreetNumber })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
            </AddressLineWrapper>
            <AddressLineWrapper>
              <EachInput label={formatMessage({ id: IntlKeys.addressCity })}>
                <FormTextField
                  name={'address.city'}
                  placeholder={formatMessage({ id: IntlKeys.enterCompanyCity })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
              <EachInput label={formatMessage({ id: IntlKeys.addressPostcode })}>
                <FormTextField
                  name={'address.postcode'}
                  placeholder={formatMessage({ id: IntlKeys.enterCompanyPostcode })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
            </AddressLineWrapper>
            <EachInput label={formatMessage({ id: IntlKeys.addressCountry })}>
              <FormAutoComplete
                options={countryOptions}
                name={'address.country'}
                freeSolo
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
              />
            </EachInput>

            <BillingAddress>
              <Title3>{formatMessage({ id: IntlKeys.billingAddress })}</Title3>
              <SubHeading>{formatMessage({ id: IntlKeys.billingAddressHelper })}</SubHeading>
              <AddressLineWrapper>
                <EachInput label={formatMessage({ id: IntlKeys.addressStreet })}>
                  <FormTextField
                    name={'billingAddress.street'}
                    placeholder={formatMessage({ id: IntlKeys.enterCompanyStreet })}
                    rules={{
                      required: formatMessage({ id: IntlKeys.requiredItem }),
                    }}
                  />
                </EachInput>
                <EachInput label={formatMessage({ id: IntlKeys.addressHouseNumber })}>
                  <FormTextField
                    name={'billingAddress.housenumber'}
                    placeholder={formatMessage({ id: IntlKeys.enterCompanyStreetNumber })}
                    rules={{
                      required: formatMessage({ id: IntlKeys.requiredItem }),
                    }}
                  />
                </EachInput>
              </AddressLineWrapper>
              <AddressLineWrapper>
                <EachInput label={formatMessage({ id: IntlKeys.addressCity })}>
                  <FormTextField
                    name={'billingAddress.city'}
                    placeholder={formatMessage({ id: IntlKeys.enterCompanyCity })}
                    rules={{
                      required: formatMessage({ id: IntlKeys.requiredItem }),
                    }}
                  />
                </EachInput>
                <EachInput label={formatMessage({ id: IntlKeys.addressPostcode })}>
                  <FormTextField
                    name={'billingAddress.postcode'}
                    placeholder={formatMessage({ id: IntlKeys.enterCompanyPostcode })}
                    rules={{
                      required: formatMessage({ id: IntlKeys.requiredItem }),
                    }}
                  />
                </EachInput>
              </AddressLineWrapper>
              <EachInput label={formatMessage({ id: IntlKeys.addressCountry })}>
                <FormAutoComplete
                  options={countryOptions}
                  name={'billingAddress.country'}
                  freeSolo
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
            </BillingAddress>
            <EachInput label={formatMessage({ id: IntlKeys.companyLogo })}>
              <ImageDropzone
                onDrop={(acceptedFiles) =>
                  handleLogoUpload({
                    fileObj: acceptedFiles[0],
                    src: URL.createObjectURL(acceptedFiles[0]),
                    name: acceptedFiles[0].name,
                  })
                }
                maxFiles={1}
                height={200}
                src={form.getValues('logo.url') || logoObj?.src}
                multiple={false}
                previewClassName={'job-cover-preview'}
                onRemove={removeLogo}
                cropConfig={{}}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.companyWebsite })}>
              <FormTextField
                name={'website'}
                placeholder={formatMessage({ id: IntlKeys.enterWebsite })}
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.linkedin })}>
              <FormTextField name={'linkedin'} placeholder={formatMessage({ id: IntlKeys.enterLinkedIn })} />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.xing })}>
              <FormTextField name={'xing'} placeholder={formatMessage({ id: IntlKeys.enterXing })} />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.industry })}>
              <FormAutoComplete
                freeSolo
                name="industry"
                placeholder={formatMessage({ id: IntlKeys.selectIndustry })}
                options={industriesOptions}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.foundingDateLabel })}>
              <FormDatePicker name="foundingDate" />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.headCount })}>
              <FormAutoComplete
                freeSolo
                name="employeeCount"
                placeholder={formatMessage({ id: IntlKeys.selectSeniority })}
                options={headCountOptions.map((option) => ({ label: option, value: option }))}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.descriptionLanguage })}>
              <FormRadioSwitch
                noBullet
                name="descriptionLanguage"
                options={languagesOptions}
                onChangeExternal={changeDescriptionLanguage}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.aboutCompany })}>
              <HelpText>{formatMessage({ id: IntlKeys.aboutCompanyHelper })}</HelpText>
              {descriptionLanguage === 'de-DE' && <TextEditor name="descriptionTextDe" />}
              {descriptionLanguage === 'en-US' && <TextEditor name="descriptionTextEn" />}
            </EachInput>
          </FormContainer>
          <Footer>
            <ButtonContainer align="center" justify={'flex-end'}>
              <SubmitButton type="submit" isLoading={isSubitting} disabled={isSubitting}>
                {formatMessage({ id: IntlKeys.save })}
              </SubmitButton>
            </ButtonContainer>
          </Footer>
        </Form>
      </FormProvider>
    </SettingsContainer>
  );
};

export default CompanySettings;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;

  img {
    max-height: 100px;
  }
`;

const SettingsContainer = styled.div``;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 64px;
  border-top: 1px solid ${theme.colors.stroke};
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${theme.colors.white};
`;

const ButtonContainer = styled(FlexBox)`
  max-width: 800px;
  margin: auto;
  align-items: center;
  padding: 0rem 1rem;
  height: 100%;

  > button {
    padding: 0.5rem 1.5rem;
  }
`;

const FormContainer = styled.div`
  max-width: 800px;
  padding-bottom: 64px;
`;

const AddressLineWrapper = styled(FlexBox)`
  width: 100%;
  gap: 2rem;

  > div {
    flex: 1;
  }
`;

const BillingAddress = styled.div`
  padding: 1rem;
  border: 1px solid ${theme.colors.stroke};
  border-radius: 5px;
  margin-bottom: 1rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 530;
    margin-bottom: 0;
  }

  > div {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SubHeading = styled.p`
  margin-bottom: 1rem;
  color: ${theme.colors.gray};
`;

const HelpText = styled.p`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: ${theme.colors.gray};
  margin-top: 0;
`;
