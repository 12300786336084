import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#F7F8FF';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    backgroundColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  description: {
    width: '50%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  tax: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: '15%',
  },
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.description}>Bezeichnung</Text>
    <Text style={styles.qty}>Anzahl</Text>
    <Text style={styles.rate}>Einzel €</Text>
    <Text style={styles.tax}>Ust. %</Text>
    <Text style={styles.amount}>Gesamt €</Text>
  </View>
);

export default InvoiceTableHeader;
