import { IntlKeys } from 'localization/keys';

const translations = {
  //COMMON
  [IntlKeys.here]: 'here',
  [IntlKeys.image]: 'Image',
  [IntlKeys.video]: 'Video',
  [IntlKeys.loading]: 'Loading...',
  [IntlKeys.helloWorld]: 'Hallo World',
  [IntlKeys.email]: 'Email',
  [IntlKeys.password]: 'Password',
  [IntlKeys.enterPassword]: 'Enter password',
  [IntlKeys.passwordPlaceholder]: 'Type your password',
  [IntlKeys.retypePassword]: 'Re-type password',
  [IntlKeys.newPassword]: 'New password',
  [IntlKeys.retypeNewPassword]: 'Re-type new password',
  [IntlKeys.confirm]: 'Confirm',
  [IntlKeys.cancel]: 'Cancel',
  [IntlKeys.name]: 'Name',
  [IntlKeys.namePlaceholder]: 'Enter your name',
  [IntlKeys.surname]: 'Surname',
  [IntlKeys.surnamePlaceholder]: 'Enter your surname',
  [IntlKeys.jobTitle]: 'Job title',
  [IntlKeys.jobTitlePlaceholder]: 'Enter your job title',
  [IntlKeys.yes]: 'Yes',
  [IntlKeys.no]: 'No',
  [IntlKeys.profile]: 'Profile',
  [IntlKeys.organization]: 'Organization',
  [IntlKeys.logout]: 'Logout',
  [IntlKeys.settings]: 'Settings',
  [IntlKeys.apply]: 'Apply',
  [IntlKeys.applySend]: 'Submit',
  [IntlKeys.applyfiles]: 'Add your CV, motivational Letter, qualifications',
  [IntlKeys.additionalFiles]: 'Additional Files',
  [IntlKeys.firstName]: 'First Name',
  [IntlKeys.lastName]: 'Last Name',
  [IntlKeys.phoneNumber]: 'Phone Number',
  [IntlKeys.email]: 'Email',
  [IntlKeys.enterFirstName]: 'Enter first name',
  [IntlKeys.enterLastName]: 'Enter last name',
  [IntlKeys.enterEmail]: 'Enter E-mail ID',
  [IntlKeys.enterPhoneNumber]: 'Enter Phone No.',
  [IntlKeys.foundingDate]: 'Founded on: {date}',
  [IntlKeys.addressStreet]: 'Street',
  [IntlKeys.addressHouseNumber]: 'Building No:',
  [IntlKeys.addressPostcode]: 'Postcode',
  [IntlKeys.addressCountry]: 'Country',
  [IntlKeys.addressCity]: 'City',
  [IntlKeys.dragAndDropOrChoose]: 'Drag & Drop or click to choose',
  [IntlKeys.undo]: 'Undo',
  [IntlKeys.tryAgainMessage]: 'Something went wrong! Please try again.',
  [IntlKeys.save]: 'Save',
  [IntlKeys.edit]: 'Edit',
  [IntlKeys.delete]: 'Delete',
  [IntlKeys.draft]: 'Draft',
  [IntlKeys.add]: 'Add',
  [IntlKeys.hiddenSalary]: 'on request',
  [IntlKeys.hideSalary]: 'show salary to talent',
  [IntlKeys.hideSalaryTrue]: 'do not show',
  [IntlKeys.hideSalaryFalse]: 'do show',

  //LOGIN PAGE
  [IntlKeys.loginTitle]: 'Welcome back to Talents&Friends!',
  [IntlKeys.loginSubtitle]: 'Please login to your account to continue',
  [IntlKeys.signIn]: 'Login',
  [IntlKeys.loginNoAccount]: 'Don’t have an account?',
  [IntlKeys.signInError401]: 'Wrong email or password',
  [IntlKeys.signInError404]: 'User does not exist!',

  //FORGOT PASSWORD PAGE
  [IntlKeys.forgotPassword]: 'Forgot password',
  [IntlKeys.forgotPasswordQuestion]: 'Forgot password?',
  [IntlKeys.forgotPasswordEnterEmail]: 'Enter the email address associated with your account',
  [IntlKeys.forgotPasswordSendLink]: 'Send reset link',
  [IntlKeys.forgotPasswordBackToLogin]: 'Back to Login',
  [IntlKeys.forgotPasswordFulfilled]: 'Link sent to your email',
  [IntlKeys.forgotPasswordError404]: "This email address don't exist",

  //RESET PASSWORD PAGE
  [IntlKeys.registerHead]: 'Welcome to Talents&Friends',
  [IntlKeys.resetPassword]: 'Reset password',
  [IntlKeys.resetPasswordSubtitle]: 'Please enter your new password',
  [IntlKeys.resetPasswordChangePassword]: 'Change password',
  [IntlKeys.resetPasswordHaveAccount]: 'Already have an account?',
  [IntlKeys.resetPasswordFulfilled]: 'Password successfully changed',
  [IntlKeys.resetPasswordError404]: 'This link is invalid',
  [IntlKeys.resetPasswordError422]: 'This link is invalid',

  //SIGNUP PASSWORD PAGE
  [IntlKeys.signUp]: 'Sign up',
  [IntlKeys.signUpTitle]: 'Join Talents&Friends',
  [IntlKeys.signUpSubTitle]: 'Create an account and start your training!',
  [IntlKeys.signUpHaveAccount]: 'Have an account?',
  [IntlKeys.signUpFulfilled]: 'User successfully created',
  [IntlKeys.signUpError404]: 'This link is invalid',
  [IntlKeys.signUpError422]: 'This link is invalid',
  [IntlKeys.signUpError500]: 'This email already exists',

  // Form Validation
  [IntlKeys.formFieldFequired]: 'This field is required',

  // Auth validations
  [IntlKeys.passwordsMustMatch]: 'Passwords must match',
  [IntlKeys.eightCharacterAtLeast]: 'Must be 8 characters at least',
  [IntlKeys.incorrectEmail]: 'Incorrect email',
  [IntlKeys.requiredItem]: 'This is required',
  [IntlKeys.settingsPasswordMaxCharacter]: 'Password must be no longer than 20 characters',
  [IntlKeys.enterValidString]: 'Please enter valid string',

  // Talents
  [IntlKeys.talents]: 'Talents',
  [IntlKeys.talentsAll]: 'All Talents',
  [IntlKeys.talentsReview]: 'Talents under review',
  [IntlKeys.talentsAccepted]: 'Accepted Talents',
  [IntlKeys.talentsRejected]: 'Rejected Talents',
  [IntlKeys.talentsView]: 'View talent',
  [IntlKeys.talentsDownload]: 'Download talent',
  [IntlKeys.talentsMarkAsMatch]: 'Mark talent as match',
  [IntlKeys.talentsMarkAsNoMatch]: 'Reject talent',
  [IntlKeys.talentsZero]: ' no talents yet',
  [IntlKeys.talentsProvided]: '<b>{applicationCount}</b>/{maxCandidates} Talents provided',
  [IntlKeys.talentsRequested]: 'Talents requested',
  [IntlKeys.talentsBaseCredits]: 'Credits per Talent',
  [IntlKeys.talentsCreditsNeeded]: 'Total credits needed:',
  [IntlKeys.talentsTotalCredits]: '{creditTotal} Credits',
  [IntlKeys.talentsMinRequired]: 'Please choose at least {amount} Talents',

  // Jobs
  [IntlKeys.jobs]: 'Jobs',
  [IntlKeys.jobsAbortQuestion]: 'Are you sure to abort the recruiting?',
  [IntlKeys.jobsAbortDescription]: 'You may have unsaved changes that will be lost!',
  [IntlKeys.jobsExpressRecruiting]:
    'Express recruiting. More resources will be put on recruiting talentes on a high priority basis',
  [IntlKeys.jobsFilter]: 'Filter by Job',
  [IntlKeys.jobApplied]: 'Applied as:',
  [IntlKeys.jobRemarks]: 'Remarks',
  [IntlKeys.remarksComesHere]: 'Remarks comes here',
  [IntlKeys.notAnsweredYet]: 'Not answered by talent yet',
  [IntlKeys.noQuestions]: 'No question asked',
  [IntlKeys.jobPreview]: 'Job preview',
  [IntlKeys.jobProceed]: 'save & next',
  [IntlKeys.searchJob]: 'Search Jobs',
  [IntlKeys.createJob]: 'Create new Job',
  [IntlKeys.jobsAll]: 'All Jobs',
  [IntlKeys.jobsActive]: 'Active Jobs',
  [IntlKeys.jobsArchived]: 'Archived Jobs',
  [IntlKeys.jobsDraft]: 'Draft Jobs',
  [IntlKeys.jobDeleteQuestion]: 'Are you sure, you want to delete this job?',
  [IntlKeys.jobDeleteDescription]: 'Deleting a job is not reversible action. The job will be permanently lost!',
  [IntlKeys.jobDeleteSuccess]: 'Job deleted successfully',
  [IntlKeys.jobDeleteError]: 'Failed to delete Job. Please try again',
  [IntlKeys.jobsExpired]: 'Past Jobs',
  [IntlKeys.jobDescription]:
    'A <b>{contractType, select, fullTime {full time} partTime {part time} other {other}}</b> position with a salary of <b>{minSalary}</b> to <b>{maxSalary}</b> per year is available in <b>{location}</b>. There are <b>{maxCandidates}</b> talents wanted for this position by <b>{endDate}</b>',
  [IntlKeys.jobName]: 'Job Title',
  [IntlKeys.enterJobTitle]: 'Enter Job Title',
  [IntlKeys.workLocation]: 'Work Location',
  [IntlKeys.contractType]: 'Contract Type',
  [IntlKeys.minSalary]: 'Minimum annual salary',
  [IntlKeys.maxSalary]: 'Maximum annual salary',
  [IntlKeys.selectCity]: 'City',
  [IntlKeys.tasks]: 'Tasks',
  [IntlKeys.seniority]: 'Seniority',
  [IntlKeys.selectSeniority]: 'Select Seniority',
  [IntlKeys.selectBenefits]: 'Select Benefits',
  [IntlKeys.benefits]: 'Benefits',
  [IntlKeys.createBenefits]: 'Create Benefit',
  [IntlKeys.yourBenefits]: 'Your Benefits',
  [IntlKeys.emojiSelect]: 'Select emoji',
  [IntlKeys.requirements]: 'Requirements',
  [IntlKeys.jobCover]: 'Cover',
  [IntlKeys.introVideo]: 'Introduction video',
  [IntlKeys.gallery]: 'Image Gallery',
  [IntlKeys.customColor]: 'Custom font color',
  [IntlKeys.customBackgroundColor]: 'Custom background color',
  [IntlKeys.jobDetails]: 'Details',
  [IntlKeys.customization]: 'Customization',
  [IntlKeys.qualifyingQuestion]: 'Qualification Questions',
  [IntlKeys.questionHeading]: "You've successfully applied for the job! We have {count} more questions for you.",
  [IntlKeys.questionShortAnswer]: 'Short Answer',
  [IntlKeys.questionShortAnswerDescription]: 'The Talent will answer your question with his own words.',
  [IntlKeys.questionChoice]: 'Multiple Choice',
  [IntlKeys.questionChoiceDescription]: 'The Talent will choice one or more answers you provided.',
  [IntlKeys.questionYesNo]: 'Yes-No Question',
  [IntlKeys.questionYesNoDescription]: 'The Talent will answer "yes" or "no"',
  [IntlKeys.summary]: 'Summary',
  [IntlKeys.disqualifyTooltip]:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
  [IntlKeys.disqualify]: 'Disqualify',
  [IntlKeys.typeYourQuestion]: 'Type your question',
  [IntlKeys.addNewOption]: 'Add new option',
  [IntlKeys.fullTime]: 'Full-time',
  [IntlKeys.partTime]: 'Part-time',
  [IntlKeys.workFromOffice]: 'In Office',
  [IntlKeys.hybrid]: 'Hybrid',
  [IntlKeys.remote]: 'Remote',
  [IntlKeys.workingHours]: 'Working hours',
  [IntlKeys.employemtType]: 'Employment type',
  [IntlKeys.salary]: 'Salary',
  [IntlKeys.location]: 'Location',
  [IntlKeys.match]: 'Match',
  [IntlKeys.nomatch]: 'Not a Match',
  [IntlKeys.appliedOn]: 'Applied on:',
  [IntlKeys.gdp]: 'I agree to the Terms of Service!',
  [IntlKeys.yetToWriteRemarks]:
    '{name} has applied for the {position} position for a remuneration ranging {minSalary} - {maxSalary}',
  [IntlKeys.jobPreview]: 'Preview',
  [IntlKeys.addLinkToIntroVideo]: 'Add link to the intro video',
  [IntlKeys.qualificationQuestionDescription]:
    'Qualification questions will be asked to the talents to get more and specific information. Qualification questions can be optionally used to immidiately disqualify the user as well.',
  [IntlKeys.questionNoAnswer]: 'Please answer before proceeding',
  [IntlKeys.publishSuccess]: 'Job created succeffully and notified the hiring manager.',
  [IntlKeys.publishFailed]: 'Failed to publish the job. Please try again later.',
  [IntlKeys.maximumCandidateNeeded]: 'Maximum talents needed',
  [IntlKeys.enterMaxCandidateNeeded]: 'Enter number of talents needed',
  [IntlKeys.minCandidateRequired]: 'Atleast {count} talents are required to start the recruiting process',
  [IntlKeys.jobPagePreviewMessage]:
    "This is just a preview of the job page. This page won't be visible to any applicant. The page is a replica of actual job page for reviewing and make changes if needed",
  [IntlKeys.jobPagePreviewSubmit]:
    'You are currently previewing the job page. Application submission is not possible in preview page',
  [IntlKeys.applicationSubmitSuccess]: 'Application submitted successfully',
  [IntlKeys.viewApplications]: 'view applications',
  [IntlKeys.jobDuplicate]: 'dublicate job',
  [IntlKeys.jobPosition]: 'Position',
  [IntlKeys.jobSalary]: 'Annual salary',

  // job applications
  [IntlKeys.applicationEmptyPlaceholder]: "Can't wait for new talents? Don't worry, we got you covered soon!",

  // job comments
  [IntlKeys.comments]: 'Job Chat',
  [IntlKeys.commentsDefault]: 'How can we help you?',
  [IntlKeys.commentsEnter]: 'Enter your comment here...',
  [IntlKeys.commentsSave]: 'Post now',

  // Applications
  [IntlKeys.userAcceptedMessage]: '<b>{name}</b> is a match for the <b>{job}</b> position.',
  [IntlKeys.userDeclinedMessage]: '<b>{name}</b> is not a match for the <b>{job}</b> position.',

  // Job Landing
  [IntlKeys.jobLandingWeAreLookingFor]: 'We are looking for you as',
  [IntlKeys.jobLandingWeAreLookingForWithName]: 'Hello {name}, We are looking for you as',
  [IntlKeys.interestedInRole]: 'Interested in the {role} position?',
  [IntlKeys.interestedIn]: "I'm interested",
  [IntlKeys.jobShareMessage]:
    'Hey! I found this amazing job that you could be a good fit for. Click here to apply: {url}',
  [IntlKeys.shareThisJob]: 'Share this job',
  [IntlKeys.copyToClipboard]: 'Copy to clipboard',
  [IntlKeys.shareOnWhatsapp]: 'Share on WhatsApp',
  [IntlKeys.allSet]: 'Thanks!',
  [IntlKeys.weWillGetBack]:
    "We've got everything we needed. The hiring manager will review your application and get back to you as soon as possible",

  // Company
  [IntlKeys.companyUpdatedSuccessfully]: 'Company details updated successfully!',
  [IntlKeys.companyName]: 'Company Name',
  [IntlKeys.enterCompanyName]: 'Enter name of the company',
  [IntlKeys.companyLogo]: 'Company Logo',
  [IntlKeys.companyWebsite]: 'Website',
  [IntlKeys.enterWebsite]: 'Enter website',
  [IntlKeys.linkedin]: 'LinkedIn',
  [IntlKeys.enterLinkedIn]: 'Enter LinkedIn',
  [IntlKeys.enterXing]: 'Enter Xing',
  [IntlKeys.xing]: 'Xing',
  [IntlKeys.industry]: 'Industry',
  [IntlKeys.selectIndustry]: 'Select industry',
  [IntlKeys.headCount]: 'Employee Count',
  [IntlKeys.foundingDateLabel]: 'Founded on',
  [IntlKeys.aboutCompany]: 'About the company',
  [IntlKeys.aboutCompanyHelper]:
    'This information will be shown in the job landing page for the talents to get a better understanding about the company',
  [IntlKeys.billingAddress]: 'Billing Address',
  [IntlKeys.billingAddressHelper]:
    'Billing address will be used to generate invoices for the subscriptions and purchases you will make on Talents & Friends',
  [IntlKeys.companySettings]: 'Company Settings',
  [IntlKeys.companySubscriptions]: 'Subscriptions',
  [IntlKeys.companyUsers]: 'Team',
  [IntlKeys.companyCustomization]: 'Customization',
  [IntlKeys.enterCompanyStreet]: 'Enter street name',
  [IntlKeys.enterCompanyStreetNumber]: 'Enter the house/building number',
  [IntlKeys.enterCompanyCity]: 'Enter the city',
  [IntlKeys.enterCompanyPostcode]: 'Enter the zipcode',
  [IntlKeys.enterCompanyCountry]: 'Enter the country',

  // Settings
  [IntlKeys.passwordUpdatedSuccessfully]: 'Password updated successfully',
  [IntlKeys.failedToUploadPassword]: 'Failed to update the password',
  [IntlKeys.oldPassword]: 'Old password',
  [IntlKeys.enterOldPassword]: 'Enter old password',
  [IntlKeys.enterNewPassword]: 'Enter new password',
  [IntlKeys.confirmPassword]: 'Confirm Password',
  [IntlKeys.enterConfirmPassword]: 'Enter password again',
  [IntlKeys.profileUpdatedSuccessfully]: 'Profile updated successfully',
  [IntlKeys.avatar]: 'Profile Picture',
  [IntlKeys.language]: 'Language',
  [IntlKeys.descriptionLanguage]: 'Language for Description',
  [IntlKeys.selectLanguage]: 'Select language',
  [IntlKeys.profileSettings]: 'Profile Settings',
  [IntlKeys.accountSettings]: 'Account Settings',

  // Credits
  [IntlKeys.credits]: 'Credits',
  [IntlKeys.currentCredits]: 'Current',
  [IntlKeys.pendingCredits]: 'Payment pending',
  [IntlKeys.totalCredits]: 'Total purchased',
  [IntlKeys.invoicesHeading]: 'Invoices',
  [IntlKeys.invoiceTitle]: 'Invoice',
  [IntlKeys.invoicePaid]: 'Paid',
  [IntlKeys.invoiceNotPaid]: 'Not paid',
  [IntlKeys.invoiceHasStorno]: 'Canceled',
  [IntlKeys.noInvoicesAvailable]: 'No invoices available',
  [IntlKeys.reservedCredits]: 'Reserved for Active jobs',
  [IntlKeys.yetToSpendCredits]: 'Available for new job',
  [IntlKeys.creditErrorLoadPrice]: "Couldn't load price per credit. Please try again!",
  [IntlKeys.creditStartRecruiting]: 'One click away to start recruiting',
  [IntlKeys.creditTooLess]:
    'You currently have only {currentAmount} credits. You may need {creditsNeeded} more to publish the job',
  [IntlKeys.creditSelectAmountHead]:
    'Please select a credit basket from the following and we will send you and invoice. Recruitement will start ASAP and you can make the payment within next 7 days.',
  [IntlKeys.creditPerCreditPrice]: 'Amount per credit',
  [IntlKeys.creditPriceTotal]: 'Total',
  [IntlKeys.creditsIAgree]: 'I agree to the',
  [IntlKeys.termsConditions]: 'Terms and Conditions',
  [IntlKeys.termsConditionsError]:
    'Please accept the Terms and conditions to purchase the credits and start recruiting',
  [IntlKeys.creditsSelectPlan]: 'Select a subscription plan',
};
export default translations;
