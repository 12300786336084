import { restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';

export const convertFormDataToArray = (formData: Record<string, any>) => {
  const questionArray: Record<string, unknown>[] = [];
  for (const [key, value] of Object.entries(formData)) {
    const id = Number(key.split('-')[1]);
    const field = key.split('-')[2];
    const optionId = key.split('-')[3];

    questionArray[id] = {
      ...questionArray[id],
      ...(optionId ? { choices: [...((questionArray[id].choices || []) as string[]), value] } : { [field]: value }),
    };
  }
  return questionArray;
};

export const createQualificationQuestion = (payload: any) =>
  createApiCall<{ success: string }>({
    api: restClient.createJobQualificationQuestions,
  })(payload);

export const updateQualificationQuestion = (payload: any) =>
  createApiCall<{ success: string }>({
    api: restClient.updateQuestion,
  })(payload);
