import React, { FC, memo } from 'react';
import { Controller } from 'react-hook-form';
import { SliderProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// hooks
import { useHookFormContext } from 'components/hook-form';
import dayjs from 'dayjs';
import { theme } from 'theme';
import styled from 'styled-components';

type OwnProps = DatePickerProps<any> & {
  name: string;
};

const FormDatePicker: FC<OwnProps> = ({ name, ...props }) => {
  const { control } = useHookFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        defaultValue={props.defaultValue}
        control={control}
        render={({ field: { onChange, value = '' } }) => {
          return (
            <CustomDatePicker
              onChange={(newValue) => onChange(newValue)}
              value={dayjs(value)}
              {...props}
              views={['month', 'year']}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default memo(FormDatePicker);

const CustomDatePicker = styled(DatePicker)`
  font-family: ${theme.fonts.montreal};
`;
