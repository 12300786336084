import React, { useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import clsx from 'clsx';
import { BsDownload, BsEyeFill, BsThreeDotsVertical } from 'react-icons/bs';
import { MdCheck, MdClose } from 'react-icons/md';
//
import { Avatar, Card, FlexBox, SecText, Text } from 'components/base';
//
import { formatDate } from 'helpers/date';
import { formatCurrency } from 'helpers/number';
import { Application } from 'types/application';
//
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { theme } from 'theme';
import { useAppDispatch } from 'domain/store';
import { updateApplicationAction } from 'domain/applications/actions';
import { ApplicationActionType } from 'types/application';
import { ApplicationsContext } from 'pages/Applications/utils';
import { IntlKeys } from 'localization';
import DropdownMenu from 'components/base/DropdownMenu';
import { useIsMobile } from 'hooks/useIsMobile';
import ApplicationCardActions from '../ApplicationCardActions';
import { nameToInitials } from 'helpers/string';
import { CandidateType } from 'types/candidates';

interface OwnProps {
  data: unknown;
}
const ApplicationCard = ({ data }: OwnProps) => {
  const navigate = useNavigate();
  const application = data as Application;
  const { formatMessage } = useIntl();
  const [, copy] = useCopyToClipboard();
  const dispatch = useAppDispatch();
  const { addToUpdatedApplications } = useContext<{
    addToUpdatedApplications: (type: ApplicationActionType, application: Application) => void;
  }>(ApplicationsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useIsMobile();

  const copyToClipBoard = (e: React.SyntheticEvent<Element, Event>, value: string) => {
    e.stopPropagation();
    copy(value);
  };

  const acceptorRejectApplication = useCallback(
    (e: React.SyntheticEvent<Element, Event> | undefined, type: ApplicationActionType) => {
      e?.stopPropagation();
      const payload = { status: type === 'accept' ? 'accepted' : 'declined' };
      if (payload.status === application.status) {
        handleMenuClose();
        return;
      }
      dispatch(updateApplicationAction({ params: { id: application.id }, ...payload }))
        .unwrap()
        .then(() => {
          addToUpdatedApplications?.(type, application);
          handleMenuClose();
        });
    },
    [application, dispatch, addToUpdatedApplications],
  );

  const downloadCandidate = (candidate: CandidateType) => {
    const head = 'firstName; lastName; name; email; phone; linkedin; xing,';
    const row = `${candidate.firstName}; ${candidate.lastName}; ${candidate.firstName} ${candidate.lastName}; ${
      candidate.email
    }; ${candidate.phone}; ${candidate.socials?.linkedin || ''}; ${candidate.socials?.xing || ''},`;
    const csvContent = `${head}\n${row}`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${candidate.firstName}_${candidate.lastName}.csv`);
    link.click();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuTrigger = (
    <MenuTrigger onClick={handleMenuClick}>
      <BsThreeDotsVertical size="small" aria-haspopup="true" />
    </MenuTrigger>
  );

  const menuActions = [
    {
      label: formatMessage({ id: IntlKeys.talentsView }),
      icon: <BsEyeFill />,
      onClick: () => {
        navigate(`/applications/${application.id}`);
      },
    },
    {
      label: formatMessage({ id: IntlKeys.talentsDownload }),
      icon: <BsDownload />,
      onClick: () => {
        downloadCandidate(application.candidate);
      },
    },
    {
      label: formatMessage({ id: IntlKeys.talentsMarkAsMatch }),
      icon: <MdCheck />,
      onClick: () => {
        acceptorRejectApplication(undefined, 'accept');
      },
    },
    {
      label: formatMessage({ id: IntlKeys.talentsMarkAsNoMatch }),
      icon: <MdClose />,
      onClick: () => {
        acceptorRejectApplication(undefined, 'decline');
      },
    },
  ];

  return (
    <Container key={application.id} withBorder className={clsx({ 'card-menu-open': !!anchorEl && !isMobile })}>
      <CardWrapper gap={'1rem'} direction="column">
        <ApplicationHead gap={'1rem'} justify="space-between" align="center">
          <ClickableArea gap={'1rem'}>
            <UserAvatar src={application.candidate.avatar?.url}>
              {nameToInitials(`${application.candidate.firstName} ${application.candidate.lastName}`)}
            </UserAvatar>
            <div>
              <Text
                onClick={() => {
                  navigate(`/applications/${application.id}`);
                }}
              >
                {application.candidate.firstName} {application.candidate.lastName}
              </Text>
              <SecText>
                {formatMessage({ id: IntlKeys.appliedOn })} {formatDate(application.createdAt)}
              </SecText>
            </div>
          </ClickableArea>
          <DropdownMenu
            trigger={menuTrigger}
            anchorEl={anchorEl}
            handleClose={handleMenuClose}
            actions={menuActions}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={isMobile ? { marginLeft: '-1rem', marginTop: '1rem' } : { marginRight: '2rem', marginLeft: '2rem' }}
          />
        </ApplicationHead>
        <RemarksSection align="center" justify={!application.remarks ? 'center' : 'flex-start'}>
          {application.remarks ? (
            <div dangerouslySetInnerHTML={{ __html: application.remarks }}></div>
          ) : (
            formatMessage(
              { id: IntlKeys.yetToWriteRemarks },
              {
                name: `${application.candidate.firstName} ${application.candidate.lastName}`,
                position: application.job.title,
                minSalary: formatCurrency(application.job.minSalary),
                maxSalary: formatCurrency(application.job.maxSalary),
              },
            )
          )}
        </RemarksSection>

        <ApplicationDescription>
          <Text>{application.expectedSalary ? formatCurrency(application.expectedSalary) : ''}</Text>
        </ApplicationDescription>
        <UserMetaInfos gap="4px">
          <EachInfo onClick={(e) => copyToClipBoard(e, application.candidate.phone)}>
            {application.candidate.phone}
          </EachInfo>
          <EachInfo onClick={(e) => copyToClipBoard(e, application.candidate.email)}>
            {application.candidate.email}
          </EachInfo>
        </UserMetaInfos>
        <ApplicationCardActions application={application} />
      </CardWrapper>
    </Container>
  );
};

export default ApplicationCard;

const ClickableArea = styled(FlexBox)`
  cursor: pointer;
`;

const UserAvatar = styled(Avatar)`
  border-radius: 36px;
  background-color: ${theme.colors.mintGreen};
  color: ${theme.colors.secondary};
  height: 48px;
  width: 48px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
`;

const Container = styled(Card)`
  min-height: 264px;
  display: flex;
  height: 100%;

  &.card-menu-open {
    position: absolute;
    z-index: 1201;
  }
`;

const CardWrapper = styled(FlexBox)`
  flex: 1;
  max-width: 100%;
`;

const ApplicationHead = styled(FlexBox)`
  width: 100%;
`;

const ApplicationDescription = styled.div`
  flex: 1;
  color: ${theme.colors.gray};

  & strong {
    color: ${theme.colors.secondary};
    font-weight: 400;
  }
`;

const RemarksSection = styled(FlexBox)`
  border-radius: 10px;
  background-color: ${theme.colors.lightGray};
  padding: 0.5rem;
  font-size: 1rem;
  height: 80px;
  width: 100%;
  display: flex;
  overflow: auto;

  > div {
    ul {
      padding: 1rem;
    }

    * {
      background-color: transparent !important;
    }
  }
`;

const UserMetaInfos = styled(FlexBox)`
  flex-wrap: wrap;
  max-width: 100%;
`;

const EachInfo = styled.div`
  border-radius: 20px;
  padding: 6px 12px;
  background-color: ${theme.colors.lightGray};
  border: 1px solid transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border: 1px solid ${theme.colors.stroke};
  }
`;

const MenuTrigger = styled.div`
  cursor: pointer;
  padding: 0.5rem 0rem 1rem;
  svg {
    font-size: 1rem;
    height: 1rem;
  }
`;
