import React, { useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
//
import HeaderWithProgress from 'components/HeaderWithProgress';
//
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import StepsProgress from './components/StepsProgress';
//
import { useAppDispatch } from 'domain/store';
import { getJobDetailsAction } from 'domain/jobs/actions';

const CreateNewJob = () => {
  const [canPreview, setCanPreview] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(searchParams.get('step') ? Number(searchParams.get('step')) : 1);
  const jobId = searchParams.get('jobId');

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetailsAction({ params: { id: jobId } }));
    }
  }, [dispatch, jobId]);

  const updateUrlQueryParam = useCallback(
    (params: Record<string, string>) => {
      setSearchParams({ ...params });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (searchParams.get('jobId')) {
      setCanPreview(true);
    } else {
      setCanPreview(false);
    }
  }, [searchParams]);

  const updateStep = useCallback(
    (step: number) => {
      setCurrentStep(step);
    },
    [setCurrentStep],
  );

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 updateUrlQueryParam={updateUrlQueryParam} updateStep={updateStep} searchParams={searchParams} />;
      case 2:
        return <Step2 updateUrlQueryParam={updateUrlQueryParam} updateStep={updateStep} searchParams={searchParams} />;
      case 3:
        return <Step3 updateUrlQueryParam={updateUrlQueryParam} updateStep={updateStep} searchParams={searchParams} />;
      case 4:
        return <Step4 />;
      default:
        null;
    }
  };

  return (
    <CreateJobLayout>
      <HeaderWithProgress stepsCount={3} />
      <StepsProgress currentStep={currentStep as number} updateStep={updateStep} canPreview={canPreview} />
      {renderStep()}
    </CreateJobLayout>
  );
};

export default CreateNewJob;

const CreateJobLayout = styled.div`
  padding-bottom: 64px;
`;

export const workplaceOptions = [
  {
    label: 'Remote',
    id: 'remote',
    value: 'remote',
  },
  {
    label: 'Hybrid',
    id: 'hybrid',
    value: 'hybrid',
  },
  {
    label: 'Office',
    id: 'office',
    value: 'office',
  },
];

export const workTypeOptions = [
  {
    label: 'Vollzeit',
    id: 'fullTime',
    value: 'fullTime',
  },
  {
    label: 'Teilzeit',
    id: 'partTime',
    value: 'partTime',
  },
];

export const seniorityOptions = [
  {
    label: 'Junior/Mid-senior',
    id: 'junior',
    value: 'junior',
  },
  {
    label: 'Senior/Lead',
    id: 'senior',
    value: 'senior',
  },
  {
    label: 'Expert/Head of../Executive',
    id: 'expert',
    value: 'expert',
  },
];
