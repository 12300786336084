import { IntlKeys } from 'localization';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import { theme } from 'theme';

export enum settingsNavigation {
  profileSettings = '/settings/profile',
  accountSettings = '/settings/account',
}

type NavigationTab = {
  label: string;
  id: keyof typeof settingsNavigation;
  onClick: () => void;
};

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname.split('?')[0];
  const [selectedTab, setSelectedTab] = useState(currentUrl);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const currentUrl = location.pathname.split('?')[0];
    if (currentUrl !== selectedTab) {
      setSelectedTab(currentUrl);
    }
  }, [location]);

  const navigationTabs: NavigationTab[] = [
    {
      label: formatMessage({ id: IntlKeys.profileSettings }),
      id: 'profileSettings',
      onClick: () => {
        navigate(settingsNavigation.profileSettings);
      },
    },
    {
      label: formatMessage({ id: IntlKeys.accountSettings }),
      id: 'accountSettings',
      onClick: () => {
        navigate(settingsNavigation.accountSettings);
      },
    },
  ];

  return (
    <NavigationContainer>
      {navigationTabs.map((navigation) => (
        <EachNavigation
          key={navigation.id}
          onClick={navigation.onClick}
          active={selectedTab == settingsNavigation[navigation.id]}
        >
          {navigation.label}
        </EachNavigation>
      ))}
    </NavigationContainer>
  );
};
export default Navigation;

const NavigationContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  gap: 4px;
`;

export interface ButtonProps {
  active?: boolean;
}

const EachNavigation = styled.button`
  background-color: ${theme.colors.lightGray};
  border: none;
  color: ${theme.colors.dark};
  font-family: ${theme.fonts.montreal};
  font-size: 14px;
  line-height: 20px;
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;

  ${(props: ButtonProps) =>
    props.active &&
    css`
      border-radius: 20px;
      background: ${theme.colors.mintGreen};
      color: ${theme.colors};
    `}
`;
