import { createSlice } from '@reduxjs/toolkit';

import { getCompanyDetailsAction } from './actions';

import { CompanyType } from 'types/company';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    //
    isStale: false,
    //
  },
  resource: null as CompanyType | null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder //

      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getCompanyDetailsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getCompanyDetailsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;

        draft.resource = payload;
      })
      .addCase(getCompanyDetailsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.resource = null;
      });
    ////////////////////////////////////////////////////////////////////////////////////////////////
  },
});

export const companyReducer = companySlice.reducer;
