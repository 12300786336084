import { Card, FlexBox, Title3 } from 'components/base';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { MdAdd } from 'react-icons/md';
import { theme } from 'theme';
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';

const CreateNewJobCard = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const navigateToCreateJob = useCallback(() => {
    navigate(`/jobs/create`);
  }, [navigate]);

  return (
    <Container onClick={navigateToCreateJob} width={'100%'} withBorder>
      <CardWrapper gap={'1rem'} direction="column" justify="space-between">
        <Title3>{formatMessage({ id: IntlKeys.createJob })}</Title3>
        <IconWrapper justify="flex-end">
          <Icon align="center" justify="center">
            <MdAdd />
          </Icon>
        </IconWrapper>
      </CardWrapper>
    </Container>
  );
};

export default CreateNewJobCard;

const Container = styled(Card)`
  min-height: 264px;
  display: flex;
  background-color: ${theme.colors.maroon};
  border-color: ${theme.colors.maroon};
  color: ${theme.colors.white};

  h3 {
    color: ${theme.colors.white};
  }

  & * {
    border-color: ${theme.colors.white} !important;
  }

  &:hover {
    background-color: ${theme.colors.lightMaroon};
    border-color: ${theme.colors.stroke};
    color: ${theme.colors.secondary};

    h3 {
      color: ${theme.colors.secondary};
    }

    & * {
      border-color: ${theme.colors.stroke} !important;
    }
  }
`;

const CardWrapper = styled(FlexBox)`
  flex: 1;
`;

const IconWrapper = styled(FlexBox)`
  width: 100%;
`;

const Icon = styled(FlexBox)`
  width: 36px;
  height: 36px;
  border: 1px solid ${theme.colors.stroke};
  border-radius: 36px;

  & svg {
    width: 20px;
    height: 20px;
  }
`;
