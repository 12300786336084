import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { CommentPayloadType, JobCommentType } from 'types/job';
import styled from 'styled-components';
import { useAppDispatch } from 'domain/store';
import { FormProvider, useForm } from 'react-hook-form';
import { createCommentAction } from 'domain/jobs/actions';
import { toast } from 'react-toastify';
import { FlexBox, SubmitButton } from 'components/base';
import { theme } from 'theme';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';

interface OwnProps {
  jobId: number;
  comments: JobCommentType[];
  onClose: () => void;
  open: boolean;
  refetch: () => void;
}

const JobCommentsDialog: React.FC<OwnProps> = ({ jobId, comments, onClose, open, refetch }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const form = useForm({ defaultValues: { message: '' } });
  const commentSubmit = (data: CommentPayloadType) => {
    dispatch(createCommentAction({ params: { jobId }, ...data }))
      .then(() => {
        onClose();
        refetch();
        form.reset();
        toast.success('Comment saved');
      })
      .catch(() => {
        toast.error('Unable to post comment');
      });
  };
  const getAvatar = (comment: JobCommentType) => {
    if (comment.adminAuthor) {
      return {};
    }
    return {
      src: `${comment.userAuthor.avatar?.url}`,
    };
  };
  const getComment = (comment: JobCommentType) => {
    if (comment.adminAuthor) {
      return {
        primary: 'Talents & Friends',
        secondary: `${comment.message}`,
      };
    }
    return {
      primary: `${comment.userAuthor.firstName} ${comment.userAuthor.lastName}`,
      secondary: `${comment.message}`,
    };
  };
  return (
    <Dialog open={open} onClose={onClose} scroll="paper">
      <DialogTitle>{formatMessage({ id: IntlKeys.comments })}</DialogTitle>
      <JobDialogContent dividers={true}>
        <List>
          {comments.length === 0 && (
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <ListItemText primary="Talents & Friends" secondary={formatMessage({ id: IntlKeys.commentsDefault })} />
            </ListItem>
          )}
          {comments.map((comment) => (
            <>
              <ListItem alignItems="flex-start" key={comment.id}>
                <ListItemAvatar>
                  <Avatar {...getAvatar(comment)} />
                </ListItemAvatar>
                <ListItemText {...getComment(comment)} />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))}
        </List>
      </JobDialogContent>
      <DialogActions>
        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(commentSubmit)}>
            <TextArea
              required
              placeholder={formatMessage({ id: IntlKeys.commentsEnter })}
              {...form.register('message')}
            ></TextArea>
            <ButtonContainer align="center" gap={'1rem'}>
              <SubmitButton>{formatMessage({ id: IntlKeys.commentsSave })}</SubmitButton>
            </ButtonContainer>
          </Form>
        </FormProvider>
      </DialogActions>
    </Dialog>
  );
};

export default JobCommentsDialog;

const JobDialogContent = styled(DialogContent)`
  min-width: 360px;
  max-height: 50vh;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 0.5rem 1rem;
  border: 1px solid ${theme.colors.stroke};
  background-color: ${theme.colors.lightGray};
  min-height: 100px;

  &:focus,
  &:active {
    border-radius: 30px;
    outline: none;
  }
`;
const Form = styled.form`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const ButtonContainer = styled(FlexBox)`
  margin-top: 0.5rem;
  justify-content: center;
`;
