import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CompanyCustomizationPayloadType, CompanyType } from 'types/company';
//
import { restClient } from 'helpers/http';
import { Params } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';

export const getCompanyDetailsAction = createAsyncThunk<CompanyType, Params | undefined>(
  'company/getCompanyDetailsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: restClient.getCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCompanyAction = createAsyncThunk<CompanyType, CompanyType | CompanyCustomizationPayloadType>(
  'company/updateCompanyAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: restClient.updateCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
