import { Button, Card, Text } from 'components/base';
import { nameToInitials } from 'helpers/string';
import { IntlKeys } from 'localization';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { ApplicationActionType, ApplicationStatus } from 'types/application';
import { Application } from 'types/application';

interface OwnProps {
  type: ApplicationActionType;
  application: Application;
  key: string;
  updateApplicationStatus: (status: ApplicationStatus, applicationId: string | number, key: string) => void;
}

const FloatingApplicationCard: React.FC<OwnProps> = ({ type, application, updateApplicationStatus, key }) => {
  const { formatMessage } = useIntl();
  return (
    <CardContainer type={type}>
      <CardAvatar>{nameToInitials(`${application.candidate.firstName} ${application.candidate.lastName}`)}</CardAvatar>
      <Text>
        {type === 'accept' ? (
          <FormattedMessage
            id={IntlKeys.userAcceptedMessage}
            values={{
              name: `${application.candidate.firstName} ${application.candidate.lastName}`,
              job: application.job.title,
              b: (children) => <strong>{children}</strong>,
            }}
          />
        ) : (
          <FormattedMessage
            id={IntlKeys.userDeclinedMessage}
            values={{
              name: `${application.candidate.firstName} ${application.candidate.lastName}`,
              job: application.job.title,
              b: (children) => <strong>{children}</strong>,
            }}
          />
        )}
      </Text>

      <Action onClick={() => updateApplicationStatus(application.status as ApplicationStatus, application.id, key)}>
        {formatMessage({ id: IntlKeys.undo })}
      </Action>
    </CardContainer>
  );
};

export default FloatingApplicationCard;

const CardContainer = styled(Card)`
  background-color: ${(props: { type: string }) => (props.type === 'accept' ? theme.colors.success : theme.colors.red)};
  max-width: 400px;
  p {
    color: ${theme.colors.white};
    font-size: 1.5rem;
  }
  font-size: 1rem;
  padding: 1.5rem;
  border-radius: 30px;
  position: relative;
  margin-top: 2rem;
`;

const CardAvatar = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: ${theme.colors.primary};
  border-radius: 48px;
  top: -24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
  color: ${theme.colors.white};
`;

const Action = styled(Button)`
  background: ${theme.colors.white};
  color: ${theme.colors.secondary};
  border: none;
  margin-top: 1rem;
`;
