import React from 'react';
import { Box, Skeleton } from '@mui/material';

const DefaultRowSkeleton = () => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" padding="0.5em 0">
      <Skeleton variant="rectangular" height="calc(50px - 1em)" width="100%" />
    </Box>
  );
};

export default React.memo(DefaultRowSkeleton);
