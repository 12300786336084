import * as React from 'react';
import { Outlet } from 'react-router';
import styled from 'styled-components';
//
import Header from 'components/Header';
import { theme } from 'theme';

interface OwnProps {}

const WithHeader = () => {
  return (
    <Container>
      <Header></Header>
      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};

export default WithHeader;

const Container = styled.section`
  width: 100vw;
  font-family: ${theme.fonts.montreal};
`;

const Content = styled.section`
  height: calc(100vh - 65px);
  overflow: auto;
`;
