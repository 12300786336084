import { FlexBox } from 'components/base';
import { getNumberToCharacter } from 'helpers/string';
import { IntlKeys } from 'localization';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { QualificationQuestionsType, QualificationAnswer } from 'types/job';

interface OwnProps {
  questions: QualificationQuestionsType[];
  applicationId: number;
  answers?: QualificationAnswer[];
}

const QualificationQuestions: React.FC<OwnProps> = ({ questions, answers }) => {
  const { formatMessage } = useIntl();
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const answersMap = answers?.reduce(
    (acc, answer: QualificationAnswer) => ({ ...acc, [answer.questionId]: answer }),
    {} as Record<string | number, QualificationAnswer>,
  );

  const Answers = ({ question }: { question: QualificationQuestionsType }) => {
    const { type, choices } = question;
    switch (type) {
      case 'short_answer':
        return (
          <AnswersContainer>
            <EditorContainer>
              <TextAnswer dangerouslySetInnerHTML={{ __html: answersMap?.[question.id]?.answer as string }} />
            </EditorContainer>
          </AnswersContainer>
        );
      case 'choice':
        return (
          <AnswersContainer>
            <ChoiceOptions>
              {choices?.map((option, index) => (
                <OptionWrapper key={option} align="center">
                  <Option selected={(answersMap?.[question.id]?.answer as number[])?.includes(index)}>
                    <OptionLabel selected={(answersMap?.[question.id]?.answer as number[])?.includes(index)}>
                      {getNumberToCharacter(index + 1)}
                    </OptionLabel>
                    <OptionText>{option}</OptionText>
                  </Option>
                </OptionWrapper>
              ))}
            </ChoiceOptions>
          </AnswersContainer>
        );
      case 'yes_no':
        return (
          <AnswersContainer>
            <YesOrNoWrapper>
              <Option selected={(answersMap?.[question.id]?.answer as number[])?.includes(0)}>
                <OptionLabel selected={(answersMap?.[question.id]?.answer as number[])?.includes(0)}>A</OptionLabel>
                {formatMessage({ id: IntlKeys.yes })}
              </Option>
              <Option selected={(answersMap?.[question.id]?.answer as number[])?.includes(1)}>
                <OptionLabel selected={(answersMap?.[question.id]?.answer as number[])?.includes(1)}>B</OptionLabel>
                {formatMessage({ id: IntlKeys.no })}
              </Option>
            </YesOrNoWrapper>
          </AnswersContainer>
        );
    }
  };

  return (
    <Container>
      <>
        <QuestionsSection>
          {questions.length > 0 ? (
            questions.map((question, index) => (
              <>
                {currentQuestion === index ? (
                  <QuestionContainer key={question.id}>
                    <Question>{question.question}</Question>
                    <Answers question={question} />
                    {!answersMap?.[question.id]?.answer || !answersMap?.[question.id]?.answer.length ? (
                      <ErrorText>{formatMessage({ id: IntlKeys.notAnsweredYet })}</ErrorText>
                    ) : null}
                  </QuestionContainer>
                ) : null}
              </>
            ))
          ) : (
            <>{formatMessage({ id: IntlKeys.noQuestions })}</>
          )}
          <Dots>
            {questions.map((question, index) => (
              <Bullet key={question.id} active={currentQuestion === index} onClick={() => setCurrentQuestion(index)} />
            ))}
          </Dots>
        </QuestionsSection>
      </>
    </Container>
  );
};

export default QualificationQuestions;

interface OptionProps {
  selected?: boolean;
}

const Container = styled.div`
  width: 100%;
  padding: 1rem 0;
  overflow: auto;
`;

const QuestionContainer = styled.div`
  padding-bottom: 2rem;
  min-height: 380px;
  padding-top: 2rem;
  max-height: 380px;
  overflow: auto;
`;

const Question = styled.span`
  font-size: 1.25rem;
  font-weight: 530;
`;

const ChoiceOptions = styled.div``;

const OptionWrapper = styled(FlexBox)`
  margin: 1rem 0rem;
`;

const Option = styled(FlexBox)`
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: ${theme.colors.white};
  border-radius: 4px;
  align-items: center;
  flex: 1;
  cursor: pointer;

  ${(props: OptionProps) =>
    props.selected &&
    css`
      border: 1px solid ${theme.colors.primary};
    `}
`;

const OptionLabel = styled.div`
  font-weight: 600;
  color: ${theme.colors.secondary};
  background: ${theme.colors.lightGray};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border-radius: 4px;

  ${(props: OptionProps) =>
    props.selected &&
    css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    `}
`;

const OptionText = styled.span``;

const AnswersContainer = styled.div``;

const YesOrNoWrapper = styled(FlexBox)`
  margin: 2rem 0;
  gap: 1rem;
`;

const EditorContainer = styled.div`
  margin-top: 1rem;

  ul {
    padding-left: 0;
  }
`;

const QuestionsSection = styled.div``;

const Dots = styled(FlexBox)`
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const Bullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props: { active?: boolean }) => (props.active ? theme.colors.primary : theme.colors.gray)};
  transform: ${(props: { active?: boolean }) => (props.active ? 'scale(1.1)' : 'none')}; ;
`;

const TextAnswer = styled.div`
  background-color: ${theme.colors.white};
  padding: 0.25rem 1rem;
  border-radius: 20px;
  min-height: 100px;

  * {
    background-color: transparent !important;
  }
`;

const ErrorText = styled.p`
  color: ${theme.colors.red};
  font-weight: 530;
`;
