import { Wrapper } from 'components/Layout';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import Navigation, { companyNavigation } from './components/Navigation';

const OrganizationManagement = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = location.pathname.split('?')[0];
    const isCurrentUrlInclded = Object.values(companyNavigation).includes(currentUrl as companyNavigation);
    if (!isCurrentUrlInclded) {
      navigate(companyNavigation.companySettings);
    }
  }, [location]);

  return (
    <Wrapper>
      <Navigation />
      <Outlet />
    </Wrapper>
  );
};

export default OrganizationManagement;
