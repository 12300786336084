import { FlexBox } from 'components/base';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import { Wrapper } from 'components/Layout';
import { fetchJobsWorker, getJobDetailsAction } from 'domain/jobs/actions';
import { jobsResourceSelector } from 'domain/jobs/selectors';
import { useAppDispatch } from 'domain/store';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { theme } from 'theme';
import { ApplicationFiltersFormValues } from 'types/application/entities';
import { JobFiltersFormValues, JobsSortConfigs } from 'types/job/entities';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { debounce } from 'lodash';
import { JobType } from 'types/job';
import { getUniqueListBy } from 'helpers/object';
import { ApplicationStatus } from 'types/application';
import { FiFilter } from 'react-icons/fi';
import { useIsMobile } from 'hooks/useIsMobile';
import { useIntl } from 'react-intl';
import DropdownMenu from 'components/base/DropdownMenu';
import { IntlKeys } from 'localization';
import { Button, IconButton } from '@mui/material';
import { downloadCandidatesAction } from 'domain/applications/actions';
import { MdDownloadForOffline } from 'react-icons/md';
import { Loader } from 'components/loader';

interface OwnProps {
  onChange: (config: ApplicationFiltersFormValues) => void;
  config: ApplicationFiltersFormValues;
}

type ApplicationStatusFilter = {
  label: string;
  value: ApplicationStatus | string;
};

type ApplicationJobOption = {
  value: number | string;
  label: string;
};

const ApplicationFilter: React.FC<OwnProps> = ({ onChange, config }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const applicationStatus = searchParams.get('status');
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { formatMessage } = useIntl();

  const [isDownloading, setIsDownloading] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(config.filters.status || applicationStatus);
  const [jobSearchInputValue, setJobSearchInputValue] = useState('');

  const form = useForm({});
  const dispatch = useAppDispatch();
  const jobDetails = useSelector(jobsResourceSelector);

  const downloadCandidates = () => {
    setIsDownloading(true);
    if (jobId) {
      dispatch(downloadCandidatesAction({ jobId }))
        .unwrap()
        .then((data) => {
          setIsDownloading(false);
          window.open(data.url);
        })
        .catch(() => {
          setIsDownloading(false);
        });
    } else {
      dispatch(downloadCandidatesAction({}))
        .unwrap()
        .then((data) => {
          setIsDownloading(false);
          window.open(data.url);
        })
        .catch(() => {
          setIsDownloading(false);
        });
    }
  };

  const applicationStatusFilters: ApplicationStatusFilter[] = [
    {
      label: formatMessage({ id: IntlKeys.talentsAll }),
      value: '',
    },
    {
      label: formatMessage({ id: IntlKeys.talentsReview }),
      value: 'applied',
    },
    {
      label: formatMessage({ id: IntlKeys.talentsAccepted }),
      value: 'accepted',
    },
    {
      label: formatMessage({ id: IntlKeys.talentsRejected }),
      value: 'declined',
    },
  ];

  const allJobsOption = {
    value: '',
    label: formatMessage({ id: IntlKeys.jobsFilter }),
  };

  const jobFilterInitialValues: JobFiltersFormValues = {
    sort: 'jobTitleAsc',
    filters: { job: jobId || '' },
  };

  const initialOptions: ApplicationJobOption[] = [
    ...(jobId
      ? [
          {
            value: Number(jobId),
            label: jobDetails?.title || '',
          },
        ]
      : []),
    {
      ...allJobsOption,
    },
  ];

  const [jobFilterConfig, setJobFilterConfig] = useState(jobFilterInitialValues);
  const [jobSelectionOptions, setJobSelectionOptions] = useState(initialOptions);

  const {
    isLoading,
    isFetched,
    resources: jobResources,
    fetchNextPage,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'companyJobsList',
    //
    fetchResources: fetchJobsWorker,
    initialSort: JobsSortConfigs[jobFilterConfig.sort],
    initialFilters: jobFilterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlJobFiltersChange = useCallback(
    debounce((config: JobFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(JobsSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  const convertJobListToOptions = useCallback((jobs: JobType[]) => {
    const jobOptions: ApplicationJobOption[] = jobs.map((job) => ({ value: job.id, label: job.title }));
    const isSelectedJobPresent = jobOptions.find((job) => job.value == jobId);
    const filteredJobOptions = jobOptions.filter((job) => job.value != isSelectedJobPresent?.value);
    const updatedOptions = getUniqueListBy(
      [...initialOptions, { ...isSelectedJobPresent }, ...filteredJobOptions],
      'value',
    ) as ApplicationJobOption[];

    setJobSelectionOptions(updatedOptions);
  }, []);

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetailsAction({ params: { id: jobId } }));
    }

    if (jobId === null && applicationStatus === null) {
      onChange({ ...config, filters: { ...config.filters, jobId: '', status: '' } });
      return;
    }
    if (jobId === null) {
      onChange({ ...config, filters: { ...config.filters, jobId: '' } });
      return;
    }
    if (applicationStatus === null) {
      onChange({ ...config, filters: { ...config.filters, status: '' } });
      return;
    }
  }, [jobId, dispatch, applicationStatus]);

  useEffect(() => {
    if (jobSearchInputValue != allJobsOption.label) {
      const newFilters = { ...jobFilterConfig, filters: { ...jobFilterConfig.filters, search: jobSearchInputValue } };

      setJobFilterConfig(newFilters);
      handlJobFiltersChange(newFilters);
    }
  }, [jobSearchInputValue, handlJobFiltersChange]);

  useEffect(() => {
    if (jobResources) {
      convertJobListToOptions(jobResources);
    }
  }, [jobResources, convertJobListToOptions]);

  useEffect(() => {
    if (config.filters.status !== selectedStatus) {
      setSelectedStatus(config.filters.status);
    }
  }, [config]);

  const handleStatusChange = (e: React.MouseEvent<HTMLButtonElement> | null, status: ApplicationStatus | string) => {
    e?.preventDefault();
    const newStatus = status == selectedStatus ? '' : status;
    config.filters.status = newStatus;
    onChange(config);
    handleMenuClose();
    setSelectedStatus(newStatus);
  };

  const handleJobIdChange = useCallback(
    (value: ApplicationJobOption) => {
      onChange({ ...config, filters: { ...config.filters, jobId: value ? String(value?.value) : '' } });
    },
    [onChange],
  );

  const handleSearchJobChange = useCallback(
    (e: React.SyntheticEvent<Element, Event>, value: string, reason: string) => {
      if (reason === 'input') {
        setJobSearchInputValue(value);
      }
    },
    [],
  );

  const menuActions = applicationStatusFilters
    .filter((status) => status.value !== selectedStatus)
    .map(({ label, value }) => ({
      label,
      onClick: () => handleStatusChange(null, value),
    }));

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuTrigger = (
    <MenuTrigger onClick={handleMenuClick}>
      <FiFilter /> <span>{applicationStatusFilters.find(({ value }) => value === selectedStatus)?.label}</span>
    </MenuTrigger>
  );

  return (
    <FormProvider {...form}>
      <Form>
        <Container justify="space-between" align="center" gap={'2rem'}>
          <JobFilter>
            <FormAutoComplete
              freeSolo
              name="filters[jobId]"
              options={jobSelectionOptions}
              defaultOption={jobSelectionOptions.find((option) => option.value == jobId)}
              onChange={(_, value) => handleJobIdChange(value as ApplicationJobOption)}
              onInputChange={handleSearchJobChange}
              loading={isLoading}
              // isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </JobFilter>
          {isMobile && (
            <DropdownMenu
              trigger={menuTrigger}
              anchorEl={anchorEl}
              handleClose={handleMenuClose}
              actions={menuActions}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{ marginRight: '2rem' }}
            />
          )}
          {!isMobile && (
            <FilterContainer>
              {applicationStatusFilters.map((filter) => (
                <EachFilter
                  key={filter.value}
                  onClick={(e) => handleStatusChange(e, filter.value)}
                  active={selectedStatus == filter.value}
                >
                  {filter.label}
                </EachFilter>
              ))}
            </FilterContainer>
          )}
          {isDownloading ? (
            <Loader />
          ) : (
            <IconButton onClick={downloadCandidates}>
              <MdDownloadForOffline />
            </IconButton>
          )}
        </Container>
      </Form>
    </FormProvider>
  );
};

export default ApplicationFilter;

const Container = styled(FlexBox)`
  padding: 36px 0px 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 4px;
  flex: 1;
  justify-content: flex-end;
`;

export interface ButtonProps {
  active?: boolean;
}

const EachFilter = styled.button`
  background-color: ${theme.colors.lightGray};
  border: none;
  color: ${theme.colors.dark};
  font-family: ${theme.fonts.montreal};
  font-size: 14px;
  line-height: 20px;
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;

  ${(props: ButtonProps) =>
    props.active &&
    css`
      border-radius: 20px;
      background: ${theme.colors.mintGreen};
      color: ${theme.colors};
    `}
`;

const Form = styled.form`
  width: 100%;
`;

const JobFilter = styled.div`
  flex: 1;

  input {
    box-sizing: border-box;
    height: 42px;
  }

  .MuiOutlinedInput-root {
    height: 42px !important;
  }
`;

const MenuTrigger = styled.div`
  width: 76px;
  padding: 0.5rem 0.25rem;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.stroke};
  border-radius: 5px;
  cursor: pointer;
  svg {
    font-size: 1rem;
    height: 1.5rem;
    min-width: 1.2rem;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
  }
`;
