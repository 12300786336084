import { combineReducers } from '@reduxjs/toolkit';
// actions
import { signOutAction } from './auth/actions';
// reducers
import { authReducer } from './auth/reducer';
import { companyReducer } from './company/reducer';
import { creditReducer } from './credits/reducer';
import { jobsReducer } from './jobs/reducer';
import { applicationsReducer } from './applications/reducer';
import { benefitsReducer } from './benefits/reducer';

export const createRootReducer = () => {
  const rootReducer = combineReducers({
    auth: authReducer,
    jobs: jobsReducer,
    company: companyReducer,
    credits: creditReducer,
    applications: applicationsReducer,
    benefits: benefitsReducer,
  });

  const resettableReducer: typeof rootReducer = (state, action) => {
    // TODO: change it with actual action
    if (action.type === signOutAction.fulfilled.type) {
      state = undefined;
    }

    return rootReducer(state, action);
  };

  return resettableReducer;
};
