import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

interface OwnProps {
  isStorno?: boolean;
}

const InvoiceThankYouMsg: React.FC<OwnProps> = ({ isStorno }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>
      {isStorno ? 'Der Betrag wurde Ihnen gutgeschrieben!' : 'Bitte überweisen Sie den Betrag schnellstmöglich!'}
    </Text>
  </View>
);

export default InvoiceThankYouMsg;
