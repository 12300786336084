import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { FlexBox } from 'components/base';
import { theme } from 'theme';

export type SwitchOptionType = {
  label: string;
  value: string | number;
};

interface OwnProps {
  name: string;
  onChangeExternal?: (value: string) => void;
  options: SwitchOptionType[];
  noBullet?: boolean;
}

const FormRadioSwitch: React.FC<OwnProps> = ({ name, options, noBullet }) => {
  const { control } = useFormContext();

  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const selectOption = useCallback(
    (value: string | number) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <SwitchContainer>
      {options.map((option) => (
        <EachOption
          key={option.value}
          active={option.value === value}
          onClick={() => selectOption(option.value)}
          align="center"
          justify="center"
          gap={'1rem'}
        >
          {option.value === value && !noBullet && <Bullet />}
          {option.label}
        </EachOption>
      ))}
    </SwitchContainer>
  );
};

export default FormRadioSwitch;

const SwitchContainer = styled(FlexBox)`
  width: 100%;
  border-radius: 30px;
  background-color: ${theme.colors.lightGray};
  padding: 2px;
`;

interface OptionProps {
  active?: boolean;
}

const EachOption = styled(FlexBox)`
  flex: 1;
  padding: 1rem;
  background-color: ${theme.colors.lightGray};
  cursor: pointer;
  line-height: 20px;

  ${(props: OptionProps) =>
    props.active &&
    css`
      background: ${theme.colors.white};
      color: ${theme.colors.secondary};
      border-radius: 30px;
    `}
`;

const Bullet = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: ${theme.colors.primary};
`;
