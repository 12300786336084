import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import { Wrapper } from 'components/Layout';
import { createApplicatioAction } from 'domain/applications/actions';
import { toast } from 'react-toastify';
import { Button } from 'components/base';
import { IntlKeys } from 'localization';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { useAppDispatch } from 'domain/store';
import { theme } from 'theme';
import { JobTokenType, QualificationQuestionsType } from 'types/job';
import QualificationQuestions from './QualificationQuestions';
import { ApplicationFile } from 'types/application';
import { uploadFiles } from '../helpers/file-upload';
import { isEqual } from 'lodash';
import { MdDeleteForever } from 'react-icons/md';
import { Link } from '@mui/material';
import DocumentDropzone from 'components/Dropzone/DocumentDropzone';
import FormCheckBox from 'components/hook-form/fields/FormCheckBox';

interface OwnProps {
  isPreview?: boolean;
  questions?: QualificationQuestionsType[];
  applicationIdFromUrl?: string | null;
  setSearchParams: (parms: Record<string, string>) => void;
  hideApplyForm: () => void;
  token?: JobTokenType;
  uploadToken?: string;
}

const JobApplicationForm: React.FC<OwnProps> = ({
  isPreview,
  questions,
  applicationIdFromUrl,
  setSearchParams,
  hideApplyForm,
  token,
  uploadToken,
}) => {
  const [qualificationQuestionsVisible, setQualificationQuestionsVisible] = useState(
    applicationIdFromUrl ? true : false,
  );
  const [applicationId, setApplicationId] = useState<number | null>(Number(applicationIdFromUrl));

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { id: jobId } = useParams();
  const form = useForm({
    defaultValues: {
      firstName: token?.additionalInfo?.firstName,
      lastName: token?.additionalInfo?.lastName,
      email: token?.additionalInfo?.email,
    } as FieldValues,
  });

  const applicationFileObjs = useWatch({ name: 'applicationFileObj', control: form.control });
  const applicationFiles: ApplicationFile[] = useWatch({ name: 'files', control: form.control });

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      if (isPreview || !jobId || !uploadToken) {
        toast.warn(formatMessage({ id: IntlKeys.jobPagePreviewSubmit }), {
          position: 'top-right',
        });
        return;
      }
      const { applicationFileObj, ...fieldData } = data;
      const [files] = await Promise.all([uploadFiles(jobId, uploadToken, applicationFileObj)]);
      const payload = {
        ...fieldData,
        files: files?.map((file) => ({ ...file })),
      };

      dispatch(createApplicatioAction({ ...payload, jobId: jobId, token: token?.token }))
        .unwrap()
        .then((response) => {
          setSearchParams({ applicationId: String(response.id) });

          if (questions?.length) {
            setQualificationQuestionsVisible(true);
            setApplicationId(response.id);
          } else {
            hideApplyForm();
            setApplicationId(response.id);
          }
          toast.success('Aplication submitted successfully');
        });
    },
    [
      dispatch,
      formatMessage,
      hideApplyForm,
      isPreview,
      jobId,
      questions?.length,
      setSearchParams,
      token?.token,
      uploadToken,
    ],
  );

  const removeFile = useCallback(
    (fileUrl?: string) => {
      if (!fileUrl) return;
      const updatedFiles = applicationFiles.filter((file) => file.url !== fileUrl) || [];
      const newFileObjs = applicationFileObjs?.filter((obj: { src: string }) => obj.src !== fileUrl);

      if (!isEqual(newFileObjs, applicationFileObjs)) {
        form.setValue('applicationFileObj', newFileObjs);
      }

      if (updatedFiles.length !== applicationFiles?.length) {
        form.setValue('files', updatedFiles);
      }
    },
    [applicationFileObjs, applicationFiles, form],
  );

  const handleFileUpdate = useCallback(
    (updatefiles: File[]) => {
      const fileObjects = [
        ...(form.getValues('applicationFileObj') || []),
        ...updatefiles.map((file) => ({
          fileObj: file,
          src: URL.createObjectURL(file),
          name: file.name,
        })),
      ];
      form.setValue('applicationFileObj', fileObjects);
      form.setValue(
        'applicationFileUrls',
        fileObjects.map((file) => file.src),
      );
    },
    [form],
  );

  const BaseApplicationForm = () => (
    <FormContainer>
      <EachInput label={formatMessage({ id: IntlKeys.firstName })}>
        <FormTextField
          name={'firstName'}
          placeholder={formatMessage({ id: IntlKeys.enterFirstName })}
          rules={{
            required: formatMessage({ id: IntlKeys.requiredItem }),
          }}
          required
        />
      </EachInput>
      <EachInput label={formatMessage({ id: IntlKeys.lastName })}>
        <FormTextField
          name={'lastName'}
          placeholder={formatMessage({ id: IntlKeys.enterLastName })}
          rules={{
            required: formatMessage({ id: IntlKeys.requiredItem }),
          }}
          required
        />
      </EachInput>
      <EachInput label={formatMessage({ id: IntlKeys.email })}>
        <FormTextField
          name={'email'}
          placeholder={formatMessage({ id: IntlKeys.enterEmail })}
          type="email"
          rules={{
            required: formatMessage({ id: IntlKeys.requiredItem }),
          }}
          required
        />
      </EachInput>
      <EachInput label={formatMessage({ id: IntlKeys.phoneNumber })}>
        <FormTextField
          name={'phone'}
          placeholder={formatMessage({ id: IntlKeys.enterPhoneNumber })}
          rules={{
            required: formatMessage({ id: IntlKeys.requiredItem }),
          }}
          required
        />
      </EachInput>
      <EachInput label={formatMessage({ id: IntlKeys.applyfiles })}>
        <FilesContainer>
          {[
            ...(applicationFiles?.map((file) => {
              return { url: file.url, name: file.url.substring(file.url.lastIndexOf('/') + 1) };
            }) || []),
            ...(applicationFileObjs?.map((file: { src: string; name: string }) => {
              return { url: file.src, name: file.name };
            }) || []),
          ].map(({ url, name }: { url: string; name: string }, index: number) => (
            <FileEachItem key={`${url}-${index}`}>
              <RemoveContainer onClick={() => removeFile(url)} className={'remove-file'}>
                <MdDeleteForever />
              </RemoveContainer>
              <Link href={url} target="_blank">
                {name}
              </Link>
            </FileEachItem>
          ))}
        </FilesContainer>
        <DocumentDropzone
          onDrop={(acceptedFiles) => handleFileUpdate(acceptedFiles)}
          maxFiles={5}
          height={200}
          multiple={true}
        />
      </EachInput>
      <FilesContainer>
        <FormCheckBox required name={'gdp'} />
        {formatMessage({ id: IntlKeys.gdp })}
      </FilesContainer>
      <SubmitButton type="submit">{formatMessage({ id: IntlKeys.applySend })}</SubmitButton>
    </FormContainer>
  );

  return (
    <>
      {!qualificationQuestionsVisible && (
        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(onSubmit)}>
            {' '}
            <BaseApplicationForm />
          </Form>
        </FormProvider>
      )}

      {qualificationQuestionsVisible && !!applicationId && (
        <QualificationQuestions questions={questions!} applicationId={applicationId!} />
      )}
    </>
  );
};

export default JobApplicationForm;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;

  input {
    background-color: ${theme.colors.white};
    border-radius: 5px;
  }
`;

const FilesContainer = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 0.5rem;
`;
const FileEachItem = styled.div`
  height: 120px;
  border: 1px solid ${theme.colors.gray};
  background-color: ${theme.colors.lightGray};
  border-radius: 20px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .remove-file {
    display: none;
  }

  &:hover {
    .remove-file {
      display: flex;
    }
  }
`;
const RemoveContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  /* padding: 1rem; */
  border-radius: 30px;
  background: ${theme.colors.maroon};
  width: 36px;
  height: 36px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.colors.white};
`;

const FormContainer = styled(Wrapper)`
  max-width: 800px;
`;

const SubmitButton = styled(Button)`
  margin-top: 1.5rem;
  width: 100%;
`;
