import { uploadFile } from 'helpers/uploadFile';
import { theme } from 'theme';
import { FileType } from 'types/common';
import { CompanyCustomizationPayloadType } from 'types/company';
import { JobCustomizationPayload, JobType } from 'types/job';

export interface JobMediaUploadedFileObj {
  fileObj: File;
  src: string;
  name: string;
}

export const uploadJobCover = async (
  jobCoverObj: JobMediaUploadedFileObj,
  defaultCover: FileType | null | undefined,
) => {
  const { fileObj, ...jobCover } = jobCoverObj || {};
  //
  return fileObj
    ? await new Promise<FileType>(async (resolve) => {
        const formData = new FormData();
        formData.append('file', fileObj, jobCover.name);
        const response = await uploadFile(formData);

        const resolveValue = response as FileType;

        resolve(resolveValue);
      })
    : defaultCover;
};

export const uploadIntroVideo = async (
  jobIntroVideoObj: JobMediaUploadedFileObj,
  defaultVideo: FileType | undefined,
) => {
  const { fileObj, ...jobIntro } = jobIntroVideoObj || {};
  //
  return fileObj
    ? await new Promise<FileType>(async (resolve) => {
        const formData = new FormData();
        formData.append('file', fileObj, jobIntro.name);
        const response = (await uploadFile(formData)) as FileType;

        resolve(response);
      })
    : defaultVideo;
};

export const uploadGalleryImages = async (galleryImages: JobMediaUploadedFileObj[]) => {
  //
  return await Promise.all(
    (galleryImages || []).map(async ({ fileObj }) => {
      return fileObj
        ? new Promise<FileType>(async (resolve) => {
            const formData = new FormData();
            formData.append('file', fileObj, fileObj.name);
            const response = (await uploadFile(formData)) as FileType;

            resolve(response);
          })
        : undefined;
    }),
  );
};

export const getCustomizationValuesFromJobDetails = (
  jobDetails: JobType | null,
  companyDefaults: CompanyCustomizationPayloadType,
) => {
  const { defaultGallery, defaultColor, defaultCover, defaultVideo } = companyDefaults;

  if (!jobDetails)
    return {
      cover: defaultCover,
      gallery: defaultGallery,
      customBackgroundColor: defaultColor || theme.colors.primary,
      customColor: theme.colors.white,
      video: defaultVideo,
    } as JobCustomizationPayload;
  const { cover, gallery, customColor, customBackgroundColor, video } = jobDetails;
  return {
    cover: cover || defaultCover,
    gallery: gallery?.length ? gallery : defaultGallery,
    customBackgroundColor: customBackgroundColor || defaultColor || theme.colors.primary,
    customColor: customColor || theme.colors.white,
    video: video || defaultVideo,
  } as JobCustomizationPayload;
};
