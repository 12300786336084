import { createAsyncThunk } from '@reduxjs/toolkit';
import { Params, restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { BenefitList, BenefitsType, BenefitPayloadType } from 'types/benefits';

export const fetchBenefitsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<BenefitList>({ api: restClient.getBenefits });

  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);
  return data;
};

export const getBenefitsAction = createAsyncThunk<BenefitList, Params | undefined>(
  'benefits/getBenefitsAction',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchBenefitsWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createBenefitAction = createAsyncThunk<BenefitsType, BenefitPayloadType>(
  'benefits/createBeneftisAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<BenefitsType>({
      api: restClient.createBenefit,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
