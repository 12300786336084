import { BenefitsType } from 'types/benefits';
import { getBenefitsAction } from './actions';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isSubmitting: false,
    //
    isStale: false,
    //
  },
  filters: {} as Record<string, string>,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as BenefitsType | null,
  resources: [] as BenefitsType[],
};

const benefitsSlice = createSlice({
  name: 'benefits',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBenefitsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getBenefitsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        const { data, ...pagination } = payload;
        draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];
        draft.pagination = {
          ...draft.pagination,
          ...pagination,
        };
      })
      .addCase(getBenefitsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
      });
  },
});

export const benefitsReducer = benefitsSlice.reducer;
