export const allMimeTypes =
  'audio/aac, audio/mpeg, audio/mp4, audio/ogg, audio/wav, audio/webm, audio/x-m4a, audio/m4a, application/x-abiword, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/jpeg, image/png, image/vnd.microsoft.icon, image/svg+xml, video/x-msvideo, video/mp4, video/mpeg, video/ogg, video/webm';
export const audioMimeTypes =
  'audio/aac, audio/mpeg, audio/mp4, audio/ogg, audio/wav, audio/webm, audio/x-m4a, audio/m4a';
export const imageMimeTypes = 'image/jpeg, image/png, image/vnd.microsoft.icon, image/svg+xml, image/webp, image/gif';
export const videoMimeTypes =
  'video/x-msvideo, video/mp4, video/mpeg, video/ogg, video/webm, video/mov, video/x-m4v, video/mkv, video/x-matroska, video/avi, video/wmv, video/flv, video/*';
export const documentMimeTypes =
  'application/x-abiword, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12, text/csv, application/json, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, text/plain, application/x-iwork-keynote-sffkey, application/x-iwork-pages-sffpages, application/x-iwork-numbers-sffnumbers, application/vnd.apple.keynote, application/vnd.apple.pages, application/vnd.apple.numbers, application/vnd.oasis.opendocument.text-template, application/vnd.oasis.opendocument.spreadsheet-template, application/x-rtf, application/x-soffice, text/richtext';

export const allMimeTypesArray = { ['*']: ['*'] };
export const audioMimeTypesArray = { ['audio/*']: ['.aac', '.mpeg', '.mp4', '.ogg', '.webm', '.m4a'] };
export const imageMimeTypesArray = { ['image/*']: ['.jpeg', '.png', '.icon', '.svg', '.webp', '.gif'] };
export const videoMimeTypesArray = {
  ['video/*']: ['.mp4', '.mkv', '.avi', '.mov', '.m4v', '.3gp', '.mpeg'],
};
export const documentMimeTypesArray = {
  'application/x-abiword': ['.abw'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv'],
  'application/json': ['.json'],
  'text/plain': ['.txt'],
  'application/vnd.apple.pages': ['.pages'],
  'application/vnd.apple.keynote': ['.key'],
  'application/vnd.apple.numbers': ['.numbers'],
  'application/rtf': ['.rtf'],
};
