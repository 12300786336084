import { Tooltip } from '@mui/material';
import { Card, FlexBox, SecText, Text, Title3 } from 'components/base';
import { Wrapper } from 'components/Layout';
import { getApplicationDetailsAction } from 'domain/applications/actions';
import { applicationResourceSelector } from 'domain/applications/selectors';
import { useAppDispatch } from 'domain/store';
import { formatDate } from 'helpers/date';
import { nameToInitials } from 'helpers/string';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { IntlKeys } from 'localization';
import ApplicationCardActions from 'pages/Applications/components/ApplicationCardActions';
import React, { useEffect } from 'react';
import { BiClipboard } from 'react-icons/bi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
import QualificationQuestions from './components/QualificationQuestions';
import ApplicationFiles from './components/ApplicationFiles';

const ApplicationDetails = () => {
  const application = useSelector(applicationResourceSelector);
  const { id: applicationId } = useParams();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    if (applicationId) {
      dispatch(getApplicationDetailsAction({ params: { id: applicationId! } }));
    }
  }, [applicationId, dispatch]);

  const refetchApplication = () => {
    dispatch(getApplicationDetailsAction({ params: { id: applicationId! } }));
  };

  if (!application) {
    return <div>Loading...</div>;
  }

  const { candidate, job, answers, remarks } = application;

  return (
    <ApplicationDetailsContainer>
      <Container gap={'1rem'}>
        <BasicDetails>
          <Card withBorder>
            <ApplicationHead gap={'1rem'} justify="space-between" align="center">
              <FlexBox gap={'1rem'} align="center">
                <UserAvatar align="center" justify="center">
                  {nameToInitials(`${application.candidate.firstName} ${application.candidate.lastName}`)}{' '}
                </UserAvatar>
                <div>
                  <Text>
                    {application.candidate.firstName} {application.candidate.lastName}
                  </Text>
                  <SecText>
                    {formatMessage({ id: IntlKeys.appliedOn })} {formatDate(application.createdAt)}
                  </SecText>
                </div>
              </FlexBox>
            </ApplicationHead>
            <EachDetails>
              <Label>{formatMessage({ id: IntlKeys.phoneNumber })}: </Label>
              <Value>
                {candidate.phone}{' '}
                <Tooltip title={formatMessage({ id: IntlKeys.copyToClipboard })}>
                  <CopyButton onClick={() => copyToClipboard(candidate.phone)}>
                    <BiClipboard />
                  </CopyButton>
                </Tooltip>
              </Value>
            </EachDetails>
            <EachDetails>
              <Label>{formatMessage({ id: IntlKeys.email })}: </Label>
              <Value>
                {candidate.email}
                <Tooltip title={formatMessage({ id: IntlKeys.copyToClipboard })}>
                  <CopyButton onClick={() => copyToClipboard(candidate.email)}>
                    <BiClipboard />
                  </CopyButton>
                </Tooltip>
              </Value>
            </EachDetails>
            <EachDetails hidden={!candidate.socials?.linkedin}>
              <Label>{formatMessage({ id: IntlKeys.linkedin })}: </Label>
              <Value>
                {candidate.socials?.linkedin}
                <Tooltip title={formatMessage({ id: IntlKeys.copyToClipboard })}>
                  <CopyButton onClick={() => copyToClipboard(candidate.socials?.linkedin || '')}>
                    <BiClipboard />
                  </CopyButton>
                </Tooltip>
              </Value>
            </EachDetails>
            <EachDetails hidden={!candidate.socials?.xing}>
              <Label>{formatMessage({ id: IntlKeys.xing })}: </Label>
              <Value>
                {candidate.socials?.xing}
                <Tooltip title={formatMessage({ id: IntlKeys.copyToClipboard })}>
                  <CopyButton onClick={() => copyToClipboard(candidate.socials?.xing || '')}>
                    <BiClipboard />
                  </CopyButton>
                </Tooltip>
              </Value>
            </EachDetails>
            <EachDetails>
              <Label>{formatMessage({ id: IntlKeys.jobApplied })}: </Label>
              <Value>
                <NavLink onClick={() => navigate(`/applications?jobId=${job.id}`)}> {job.title}</NavLink>
              </Value>
            </EachDetails>
            <CardActions>
              <ApplicationCardActions application={application!} callbackFn={refetchApplication} />
            </CardActions>
          </Card>
          <RemarksCard withBorder>
            <Title3>{formatMessage({ id: IntlKeys.jobRemarks })}</Title3>
            {remarks ? (
              <RemarksSection dangerouslySetInnerHTML={{ __html: remarks }} />
            ) : (
              <NoRemarks>{formatMessage({ id: IntlKeys.remarksComesHere })}</NoRemarks>
            )}
          </RemarksCard>
          {application.files?.length ? <ApplicationFiles files={application.files} /> : <></>}
        </BasicDetails>
        <QualificationQuestionsSection>
          <Card withBorder bgColor={theme.colors.mintGreen}>
            <Title3>{formatMessage({ id: IntlKeys.qualifyingQuestion })}</Title3>
            <QualificationQuestions questions={job.questions} applicationId={application.id} answers={answers} />
          </Card>
        </QualificationQuestionsSection>
      </Container>
    </ApplicationDetailsContainer>
  );
};

export default ApplicationDetails;

const ApplicationDetailsContainer = styled(Wrapper)`
  padding: 3rem 1rem;
`;

const Container = styled(FlexBox)`
  width: 100%;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

const BasicDetails = styled(FlexBox)`
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  > div {
    width: 100%;
  }
`;

const QualificationQuestionsSection = styled.div`
  flex: 1;
  width: 100%;

  h3 {
    font-size: 1.25rem;
  }
`;

const ApplicationHead = styled(FlexBox)`
  width: 100%;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const UserAvatar = styled(FlexBox)`
  background-color: ${theme.colors.mintGreen};
  border-radius: 36px;
  color: ${theme.colors.secondary};
  height: 48px;
  width: 48px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
`;

const EachDetails = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.75rem 0rem;
  gap: 0.5rem;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    justify-content: space-between;
    margin: 0.5rem 0rem;
  }
`;

const Label = styled.span`
  display: block;
  min-width: 170px;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    font-size: 0.875rem;
    min-width: auto;
    max-width: 100px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Value = styled.span`
  font-size: 1.125rem;
  font-weight: 530;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    font-size: 1rem;
  }
`;

const NavLink = styled.span`
  color: ${theme.colors.link};
  cursor: pointer;
`;

const CopyButton = styled.div`
  border: 1px solid ${theme.colors.disabledGray};
  height: 32px;
  width: 32px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: ${theme.colors.gray};
  }

  &:hover {
    border: 1px solid ${theme.colors.gray};

    svg {
      color: ${theme.colors.secondary};
    }
  }
`;

const CardActions = styled.div`
  margin: 2rem 0rem 0.5rem;
`;

const RemarksSection = styled.div`
  color: ${theme.colors.secondary};

  * {
    background-color: transparent !important;
  }

  ul {
    padding-left: 1rem;
  }

  li {
    padding-bottom: 0.25rem;
  }

  li::marker {
    color: #3a42f2;
    font-weight: 600;
    font-size: 1.25rem;
  }
`;

const RemarksCard = styled(Card)`
  min-height: 150px;

  h3 {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
  }
`;

const NoRemarks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
`;
