import { TokenSet } from 'types/auth';
import { processTokens } from 'types/auth/utils';

const accessTokenName = 'accessToken';
const refreshTokenName = 'refreshToken';
const receivedAtTokenName = 'authTokensReceivedAt';

export const saveAuthTokensToLS = (data: TokenSet) => {
  const tokenSet = processTokens(data);

  localStorage.setItem(accessTokenName, tokenSet.accessToken);
  localStorage.setItem(refreshTokenName, tokenSet.refreshToken);
  //
  localStorage.setItem(receivedAtTokenName, tokenSet.receivedAt);

  return tokenSet;
};

export const getAuthTokens = () => {
  const accessToken = localStorage.getItem(accessTokenName);
  const refreshToken = localStorage.getItem(refreshTokenName);
  //
  const receivedAt = localStorage.getItem(receivedAtTokenName);

  return {
    accessToken,
    refreshToken,
    //
    receivedAt,
  };
};

export const removeAuthTokensFromLS = () => {
  localStorage.removeItem(accessTokenName);
  localStorage.removeItem(refreshTokenName);
  localStorage.removeItem(receivedAtTokenName);
};
