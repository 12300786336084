import { uploadFile } from 'helpers/uploadFile';
import { FileType } from 'types/common';

export interface AvatarFileObj {
  fileObj: File;
  src: string;
  name: string;
}

export const uploadAvatar = async (avatarObj: AvatarFileObj, defaultAvatar: FileType | undefined) => {
  const { fileObj } = avatarObj || {};
  //
  return fileObj
    ? await new Promise<FileType>(async (resolve) => {
        const formData = new FormData();
        formData.append('file', fileObj, avatarObj.name);
        const response = await uploadFile(formData);

        const resolveValue: FileType = response as FileType;

        resolve(resolveValue);
      })
    : defaultAvatar;
};
