import cx from 'clsx';
import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
//
import { IntlKeys } from 'localization';
import { theme } from 'theme';
// components
import { Button, FlexBox, Text, Title2, Title3 } from 'components/base';
import ModalDialog from 'components/base/ModalDialog';

export type ConfirmDialogProps = {
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm: (e?: React.MouseEvent<Element, MouseEvent>) => void;
  onCancel: (e?: React.MouseEvent<Element, MouseEvent>) => void;
  confirmBtnText?: React.ReactNode | string;
  imageSrc?: string;
  subText?: string;
  description?: string;
  children?: React.ReactNode;
  cancelButtonText?: React.ReactNode | string;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  isOpen,
  onConfirm,
  onCancel,
  imageSrc,
  subText,
  isLoading = false,
  confirmBtnText = <FormattedMessage id={IntlKeys.confirm} />,
  cancelButtonText = <FormattedMessage id={IntlKeys.cancel} />,
  description,
  children,
}: ConfirmDialogProps) => {
  const { formatMessage } = useIntl();

  return (
    <DialogBox
      maxWidth="sm"
      fullWidth
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={onCancel}
      classes={{
        topBar: 'modalBarWithoutBorder',
        topBarTitle: 'modalTopBarTitle',
        content: 'modalContent',
        bottomBar: cx('modalBarWithoutBorder', 'modalBottomBar'),
        dialog: 'confirm-dialog',
      }}
    >
      <ModalContentWrapper>
        <Title>{title}</Title>
        <SubText>{subText}</SubText>
        {description && <Description>{description}</Description>}
        {imageSrc && <ModalContentImage src={imageSrc} alt="modal image" />}
        {children}
        <FlexBox gap={'1rem'}>
          <ConfirmButton onClick={onConfirm}>{confirmBtnText}</ConfirmButton>
          <CancelButton variant="outline" onClick={onCancel}>
            {cancelButtonText}
          </CancelButton>
        </FlexBox>
      </ModalContentWrapper>
    </DialogBox>
  );
};

export default memo(ConfirmDialog);

const DialogBox = styled(ModalDialog)`
  &.confirm-dialog {
    background-color: ${theme.colors.mintGreen};
  }
  & {
    .modalBarWithoutBorder {
      border: none;
      background-color: ${theme.colors.mintGreen} !important;
    }

    .modalTopBarTitle {
      text-align: center;
      font-family: ${theme.fonts.montreal};
      color: ${theme.colors.dark};
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 3rem 0rem 1rem;
    }

    .modalContent {
      height: 100%;
    }

    .modalBottomBar {
      padding: 24px 32px;
      display: flex;
      justify-content: space-between;
    }
  }
`;

const ModalContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0rem 2rem;
  justify-content: center;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    justify-content: start;
    padding-bottom: 2rem;
    height: auto;
    min-height: 100%;
  }
`;

const Title = styled(Title2)`
  font-weight: 530;
  margin-bottom: 1rem;
`;

const SubText = styled(Title3)`
  font-family: ${theme.fonts.montreal};
  color: ${theme.colors.dark};
  text-align: center;
  font-size: 1rem;
`;

const Description = styled(Text)`
  font-size: 1.25rem;
  line-height: 28px;
  text-align: center;
  margin-bottom: 1rem;
`;

const ModalContentImage = styled.img`
  aspect-ratio: 1;
  width: 65%;
`;

const ConfirmButton = styled(Button)`
  font-size: 0.875rem;
  border: none;
  margin-top: 2rem;

  &:hover {
    background-color: ${theme.colors.red};
  }
`;

const CancelButton = styled(Button)`
  border: none;
  font-size: 0.875rem;
  margin-top: 2rem;
`;
