import React, { useEffect } from 'react';
// utils
// components
import { FlexBox } from 'components/base';
// local
import useAuthPage from '../hooks/useAuthPage';
import styled from 'styled-components';
// styles
import { theme } from 'theme';
import useQueryParams from 'hooks/useQueryParams';

const DemoLoginPage = () => {
  const query = useQueryParams();
  const token = query.get('token');
  //
  const { onSubmit } = useAuthPage({ authPage: 'demoSignIn' });

  useEffect(() => {
    if (token) {
      onSubmit({ token });
    }
  }, [onSubmit, token]);

  return (
    <Container align="center" justify="center">
      DEMO
    </Container>
  );
};

export default DemoLoginPage;

const Container = styled(FlexBox)`
  height: 100vh;
  width: 100vw;
  background-color: ${theme.colors.mintGreen};

  img {
    margin: 1.5rem 0 0.5rem;
  }

  h2 {
    margin: 0.5rem 0 1rem;
    font-weight: 400;
    font-size: 1.25rem;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.primary};
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid ${theme.colors.primary};
    }
  }
`;
