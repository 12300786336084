import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// components
import { Loader } from 'components/loader';
import PrivateRoute from 'components/PrivateRoute';
//
import WithHeader from 'layout/WithHeader';
import ApplicationDetails from 'pages/ApplicationDetails';
import Applications from 'pages/Applications';
import ForgotPasswordPage from 'pages/auth/forgot-password/ForgotPasswordPage';
import Login from 'pages/auth/login/LoginPage';
import ResetPasswordPage from 'pages/auth/reset-password/ResetPasswordPage';
import CreateNewJob from 'pages/createNewJob';
import Credits from 'pages/Credits';
import JobDescription from 'pages/JobDescription';
import JobDescriptionPreview from 'pages/JobDescriptionPreview';
import JobsList from 'pages/jobs';
import OrganizationManagement from 'pages/OrganizationManagement';
import CompanyCustomization from 'pages/OrganizationManagement/CompanyCustomization';
import CompanySettings from 'pages/OrganizationManagement/CompanySettings';
import CompanySubscriptions from 'pages/OrganizationManagement/CompanySubscriptions';
import Settings from 'pages/Settings';
import AccountSettings from 'pages/Settings/AccountSettings';
import ProfileSettings from 'pages/Settings/ProfileSettings';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import DemoLoginPage from 'pages/auth/login/DemoLoginPage';

const AppRoutes = () => {
  const { formatMessage } = useIntl();
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<WithHeader />}>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
        </Route>
        <Route element={<WithHeader />}>
          <Route
            path="/credits"
            element={
              <PrivateRoute>
                <Credits />
              </PrivateRoute>
            }
          />
        </Route>
        <Route element={<WithHeader />}>
          <Route
            path="/organization"
            id={'organization'}
            element={
              <PrivateRoute>
                <OrganizationManagement />
              </PrivateRoute>
            }
          >
            <Route
              path="settings"
              id={'companySettings'}
              element={
                <PrivateRoute>
                  <CompanySettings />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="subscriptions"
              id={'companySubscriptions'}
              element={
                <PrivateRoute>
                  <CompanySubscriptions />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="users"
              id={'companyUsers'}
              element={
                <PrivateRoute>
                  <div>Users list comes here</div>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="customization"
              id={'companyCustomization'}
              element={
                <PrivateRoute>
                  <CompanyCustomization />
                </PrivateRoute>
              }
            ></Route>
          </Route>
        </Route>
        <Route element={<WithHeader />}>
          <Route
            path="/settings"
            id={'profile'}
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          >
            <Route
              path="profile"
              id={'profileSettings'}
              element={
                <PrivateRoute>
                  <ProfileSettings />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="account"
              id={'accountSettings'}
              element={
                <PrivateRoute>
                  <AccountSettings />
                </PrivateRoute>
              }
            ></Route>
          </Route>
        </Route>
        <Route element={<WithHeader />}>
          <Route path="/applications">
            <Route
              path=""
              element={
                <PrivateRoute>
                  <Applications />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path=":id"
              element={
                <PrivateRoute>
                  <ApplicationDetails />
                </PrivateRoute>
              }
            ></Route>
          </Route>
        </Route>
        <Route>
          <Route path="/jobs">
            <Route element={<WithHeader />}>
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <JobsList />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path=":id/applications"
                element={
                  <PrivateRoute>
                    <div>Job details</div>
                  </PrivateRoute>
                }
              ></Route>
            </Route>
            <Route
              path="create"
              element={
                <PrivateRoute>
                  <CreateNewJob />
                </PrivateRoute>
              }
            />
            <Route
              path="edit"
              element={
                <PrivateRoute>
                  <CreateNewJob />
                </PrivateRoute>
              }
            />
            <Route
              path=":id/preview"
              element={
                <PrivateRoute>
                  <JobDescriptionPreview />
                </PrivateRoute>
              }
            />
            <Route path=":id/apply" element={<JobDescription />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/demo" element={<DemoLoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          path="/reset-password"
          element={<ResetPasswordPage header={formatMessage({ id: IntlKeys.resetPassword })} />}
        />
        <Route path="/register" element={<ResetPasswordPage header={formatMessage({ id: IntlKeys.registerHead })} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;

const Home = () => {
  return <Navigate to={'/jobs'} />;
};
