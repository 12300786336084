import { useState } from 'react';
import { useForm } from 'react-hook-form';
// types
import { FieldValues } from 'react-hook-form';
import { AxiosError } from 'axios';
import { Params } from 'helpers/http';
import { theme } from 'theme';

type JobCreationStep = 'jobDetails' | 'customization' | 'qualificationQnA' | 'summary';

type OwnProps = {
  step: JobCreationStep;
  defaultValues?: FieldValues;
};

const extractFormSchema = (step: JobCreationStep) => {
  switch (step) {
    case 'jobDetails':
      return {};
    case 'customization':
      return {};
    case 'qualificationQnA':
      return {};
    case 'summary':
      return {};
  }
};

const useJobCreation = ({ step, defaultValues }: OwnProps) => {
  const [fulfilledMessageId, setFulfilledMessageId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessageId, setErrorMessageId] = useState('');
  const form = useForm({
    defaultValues: { ...defaultValues, customColor: defaultValues?.customColor || theme.colors.primary, customBackgroundColor: defaultValues?.customBackgroundColor || theme.colors.white } as FieldValues,
  });

  return { form, isLoading, fulfilledMessageId, errorMessageId };
};

export default useJobCreation;
