import TandFLogo from 'assets/images/tandf-logo.svg';
import { Avatar, FlexBox } from 'components/base';
import DropdownMenu from 'components/base/DropdownMenu';
import { signOutAction } from 'domain/auth/actions';
import { authUserSelector } from 'domain/auth/selectors';
import { getCreditsAction } from 'domain/credits/actions';
import { creditResourceSelector } from 'domain/credits/selectors';
import { useAppDispatch } from 'domain/store';
import { IntlKeys } from 'localization';
import { theme } from 'theme';
import Navigation from './components/Navigation';
//
import { IconButton } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { IoDiamondSharp, IoLogOutOutline, IoPersonOutline } from 'react-icons/io5';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useIsMobile } from 'hooks/useIsMobile';
import { FiMenu } from 'react-icons/fi';
import { AiOutlineFileText } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';
import { getCompanyDetailsAction } from 'domain/company/actions';

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const userDetails = useSelector(authUserSelector);
  const credits = useSelector(creditResourceSelector);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const isMobile = useIsMobile();

  const menuTrigger = (
    <IconButton onClick={handleClick} size="small" sx={{ ml: isMobile ? 0 : 2 }} aria-haspopup="true">
      {isMobile ? (
        <FiMenu className="hamburger-menu" />
      ) : userDetails?.avatar ? (
        <ProfilePic src={userDetails?.avatar?.url || userDetails?.avatar?.thumbnail} />
      ) : (
        <Avatar>SP</Avatar>
      )}
    </IconButton>
  );

  useEffect(() => {
    dispatch(getCreditsAction());
    dispatch(getCompanyDetailsAction());
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    dispatch(signOutAction())
      .unwrap()
      .then(() => {
        navigate('/login');
        handleClose();
      });
  }, []);

  const menuActions = [
    ...(isMobile
      ? [
          {
            label: formatMessage({ id: 'IntlKeys.jobs' }),
            icon: <AiOutlineFileText />,
            onClick: () => {
              navigate('/jobs');
              handleClose();
            },
          },
        ]
      : []),
    ...(isMobile
      ? [
          {
            label: formatMessage({ id: IntlKeys.talents }),
            icon: <BsPeople />,
            onClick: () => {
              navigate('/applications');
              handleClose();
            },
          },
        ]
      : []),
    {
      label: formatMessage({ id: IntlKeys.profile }),
      icon: <IoPersonOutline />,
      onClick: () => {
        navigate('/settings');
        handleClose();
      },
    },
    {
      label: formatMessage({ id: IntlKeys.organization }),
      icon: <HiOutlineBuildingOffice2 />,
      onClick: () => {
        navigate('/organization');
        handleClose();
      },
    },
    {
      label: formatMessage({ id: IntlKeys.logout }),
      icon: <IoLogOutOutline />,
      onClick: () => handleLogout(),
    },
  ];

  return (
    <>
      <HeaderWrapper justify="space-between">
        <LogoContainer onClick={() => navigate('/')}>
          <Image src={TandFLogo} />
        </LogoContainer>
        {!isMobile && <Navigation />}
        <ExtraMenu>
          <CreditsContainer gap={'.25rem'} align="center" justify="center" onClick={() => navigate('/credits')}>
            <IoDiamondSharp />
            {credits ? credits.currentAmount - credits.reservedAmount : 0}
          </CreditsContainer>
          <DropdownMenu
            trigger={menuTrigger}
            anchorEl={anchorEl}
            handleClose={handleClose}
            actions={menuActions}
            dividers={isMobile ? [1, 3] : [1]}
          />
        </ExtraMenu>
      </HeaderWrapper>
      <Divider />
    </>
  );
};

export default Header;

const HeaderWrapper = styled(FlexBox)`
  height: 64px;
  padding: 0.875rem;
  display: flex;
  align-items: center;
  width: 100vw;
  max-width: 1440px;
  margin: auto;
`;

const Divider = styled.div`
  width: 100vw;
  border-bottom: 1px solid ${theme.colors.stroke};
`;

const LogoContainer = styled.div`
  width: 10rem;
  display: flex;
  cursor: pointer;
`;

const Image = styled.img``;

const ExtraMenu = styled.div`
  width: 10rem;
  display: flex;
  justify-content: end;
  align-items: center;

  .hamburger-menu {
    font-size: 1.5rem;
    fill: ${theme.colors.dark};
    color: ${theme.colors.dark};
  }
`;

const ProfilePic = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 100%;
  overflow: hidden;
`;

const CreditsContainer = styled(FlexBox)`
  border: 1px solid ${theme.colors.stroke};
  padding: 0.35rem 0.75rem;
  border-radius: 64px;
  cursor: pointer;
  color: ${theme.colors.secondary};
  font-family: ${theme.fonts.victorSerif};
  font-weight: 530;
  font-size: 0.875rem;

  svg {
    fill: ${theme.colors.primary};
    font-size: 1.25rem;
  }
`;
