import React, { useState, memo, useEffect } from 'react';
import { clsx as cx } from 'clsx';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { useController, useFormContext } from 'react-hook-form';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
//
import styled from 'styled-components';
import FlexBox from './FlexBox';
import { theme } from 'theme';
import { useFieldErrors } from 'helpers/forms/extractErrorProps';
//
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type OwnProps = Omit<EditorProps, 'onChange'> & {
  initialValue?: string;
  onChange?: (text: string) => void;
  onTextFormatChange?: (text: string) => void;
  className?: string;
  name: string;
  bgColor?: string;
  minHeight?: string;
};

const TextEditor = ({
  initialValue = '',
  onChange: onChangeExternal,
  className,
  name,
  bgColor,
  minHeight,
  ...editorProps
}: OwnProps) => {
  const { control } = useFormContext();
  const fieldErrorProps = useFieldErrors(name);
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const blocksFromHTML = convertFromHTML(value || initialValue);

  const [htmlFormatValue, setHtmlFormatValue] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
    ),
  );

  useEffect(() => {
    if (initialValue) {
      const blocksFromHTML = convertFromHTML(initialValue);

      setHtmlFormatValue(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
        ),
      );
    }
  }, [initialValue]);

  const onEditorStateChange = (editorState: EditorState) => {
    const htmlTextContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setHtmlFormatValue(editorState);
    onChange(htmlTextContent);
    onChangeExternal?.(htmlTextContent);
  };

  return (
    <Container
      className={cx(className)}
      direction="column"
      justify="space-between"
      bgColor={bgColor}
      minHeight={minHeight}
    >
      <Editor
        editorState={htmlFormatValue}
        wrapperClassName="editorWrapper"
        editorClassName="editorSection"
        toolbarClassName="editorToolbar"
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbarConfig}
        {...editorProps}
      />
    </Container>
  );
};

export default memo(TextEditor);

interface ContainerProps {
  minHeight?: string;
  bgColor?: string;
}

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding-bottom: 0;
  }

  & .editorSection {
    min-height: ${(props: ContainerProps) => (props.minHeight ? props.minHeight : '200px')};

    li:before,
    li::marker {
      color: #3a42f2;
      font-weight: 600;
    }
  }

  & .editorWrapper {
    border: 1px solid #e9eef2;
    border-radius: 4px;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    background-color: ${(props: ContainerProps) => (props.bgColor ? props.bgColor : theme.colors.lightGray)};

    &:hover,
    &:active {
      border-radius: 30px;
    }
  }

  & .rdw-option-active {
    background: ${theme.colors.primary} !important;
    height: auto;
    box-shadow: none;

    > img {
      filter: invert(1);
    }
  }

  & .rdw-option-wrapper {
    background: transparent;
    border: none;
    padding: 0.5rem;
    height: auto;
    border-radius: 4px;

    &:hover {
      box-shadow: none;
    }
  }
  & .editorToolbar {
    border: none;
    background: transparent;
    padding: 0;
  }
`;

export const toolbarConfig = {
  options: ['inline', 'list', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered'],
  },
};
