import * as yup from 'yup';
import { patterns } from 'helpers/forms/validationPatterns';
import { IntlKeys, intl } from 'localization';

export const emailValidation = yup
  .string()
  .matches(patterns.email, intl?.formatMessage({ id: IntlKeys.incorrectEmail }))
  .required(intl?.formatMessage({ id: IntlKeys.requiredItem }));
export const passwordValidation = yup
  .string()
  .min(8, intl?.formatMessage({ id: IntlKeys.eightCharacterAtLeast }))
  .max(20, intl?.formatMessage({ id: IntlKeys.settingsPasswordMaxCharacter }))
  .required(intl?.formatMessage({ id: IntlKeys.requiredItem }));
export const requiredStringValidation = yup
  .string()
  .required(intl?.formatMessage({ id: IntlKeys.requiredItem }))
  .trim()
  .matches(/^[A-Za-z äöüÄÖÜß]*$/, intl?.formatMessage({ id: IntlKeys.enterValidString }));
export const passwordConfirmationValidation = yup
  .string()
  .required(intl?.formatMessage({ id: IntlKeys.requiredItem }))
  .oneOf([yup.ref('password')], intl?.formatMessage({ id: IntlKeys.passwordsMustMatch }));

export const LoginValidationSchema = yup.object({
  email: emailValidation,
  password: passwordValidation,
});

export const ForgotPasswordValidationSchema = yup.object({
  email: emailValidation,
});

export const DemoLoginValidationSchema = yup.object({
  token: yup.string(),
});

export const ResetPasswordValidationSchema = yup.object({
  oldPassword: passwordValidation,
  password: passwordValidation,
  passwordConfirmation: passwordConfirmationValidation,
});

export const SignUpValidationSchema = yup.object({
  firstName: requiredStringValidation,
  lastName: requiredStringValidation,
  jobTitle: requiredStringValidation,
  email: emailValidation,
  password: passwordValidation,
  passwordConfirmation: passwordConfirmationValidation,
});
