import React, { useCallback, useEffect } from 'react';
import { isObject, isString } from 'lodash';
import { RegisterOptions, useController, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { Autocomplete, AutocompleteProps as MuiAutocompleteProps, TextFieldProps } from '@mui/material';
import { Avatar, Box } from '@mui/material';
import { ensureValueControlled } from '../utils';
import { TextFieldComponent } from './FormTextField';
import { useFieldErrors } from 'helpers/forms/extractErrorProps';
import styled, { css } from 'styled-components';
import { Text } from 'components/base';
import { theme } from 'theme';

export type OptionValue = string | number | null;
export type OptionType<
  //
  T = OptionValue,
  Entity extends object = object,
> = {
  label: string;
  value: T;
  //
  // refactor? react-hook-form Fails to unpack values if there's ReactNode in option
  icon?: string;
  avatar?: string;
  //
  isCreated?: boolean;
  relatedEntity?: Entity;
};
export type BaseAutocompleteProps = MuiAutocompleteProps<OptionType, boolean, boolean, boolean>;
export type AutocompleteProps = Omit<BaseAutocompleteProps, 'renderInput'>;
export type OverwrittenProps = {
  textFieldProps?: TextFieldProps;
  withAvatar?: boolean;
  generateAvatar?: () => string;
  renderTagIcon?: (selected: string) => React.ReactElement;
};

export type OwnProps = AutocompleteProps &
  OverwrittenProps & {
    name: string;
    setOption?: (value: OptionType) => void;
    defaultOption?: OptionType;
    disableTyping?: boolean;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  };

export const isOptionType = function <T = OptionValue>(value: unknown): value is OptionType<T> {
  if (!isObject(value)) return false;

  const possiblyOption = value as OptionType;

  return possiblyOption.value != null && possiblyOption.label != null;
};

export const matchValueToOption = (
  //
  value: OptionValue,
  options: readonly OptionType[],
): OptionType | null => {
  const maybeOption = options.find(({ value: optionValue }) => value === optionValue);

  return value == null ? null : isOptionType(maybeOption) ? maybeOption : transformIntoOption(value);
};

type TransformConfig = {
  generateAvatar?: () => string;
  isCreated?: boolean;
};

export const transformIntoOption = (value: string | number, { generateAvatar }: TransformConfig = {}): OptionType => ({
  value: value,
  label: String(value),
  avatar: generateAvatar ? generateAvatar() : undefined,
  isCreated: true,
});

type FormAutocompleteFieldValue = NonNullable<OptionValue> | NonNullable<OptionValue>[];

const FormAutoComplete: React.FC<OwnProps> = ({
  defaultOption,
  multiple,
  className,
  textFieldProps = {},
  rules,
  name,
  onChange: onChangeExternal,
  getOptionLabel = getOptionLabelDefault,
  disableTyping,
  ...rest
}) => {
  const { control } = useFormContext();
  const fieldErrorProps = useFieldErrors(name);
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name,
    control,
    rules,
  });

  useEffect(() => {
    if (defaultOption) {
      onChange((defaultOption as OptionType<OptionValue, object>)?.value);
    }
  }, [defaultOption]);

  const transformValues = (value: any): OptionType | OptionType[] | null => {
    const options = defaultOption ? [...rest.options, defaultOption] : rest.options;
    const formValue = ensureValueControlled<FormAutocompleteFieldValue>(value, multiple ? [] : '');

    return multiple
      ? (([formValue] as NonNullable<OptionValue>[])
          .map((value) => matchValueToOption(value, options))
          .filter((val) => val != null) as OptionType[])
      : matchValueToOption(value as NonNullable<OptionValue>, options);
  };

  const handleChange = useCallback<NonNullable<OwnProps['onChange']>>(
    (e, value, reason, details) => {
      onChange((value as OptionType<OptionValue, object>)?.value);
      onChangeExternal?.(e, value, reason, details);
    },
    [onChange, onChangeExternal],
  );

  return (
    <SelectBox icon={value?.icon || defaultOption?.icon}>
      <Autocomplete
        value={transformValues(value)}
        getOptionLabel={getOptionLabel}
        // popupIcon={<GrDown className="chevron" width={14} />}
        // Single value
        renderInput={(params) => {
          return (
            <TextFieldComponent
              {...params}
              {...textFieldProps}
              {...fieldErrorProps}
              InputProps={{
                ...params.InputProps,
                ...textFieldProps?.InputProps,
                ...{ startAdornment: renderOptionIcon(transformValues(value) as OptionType) },
              }}
            />
          );
        }}
        renderOption={(props, option: OptionType) => {
          return (
            <ListItem {...props} key={option.value}>
              {option?.icon && <Image src={option?.icon} />}
              <Text>{option.label}</Text>
            </ListItem>
          );
        }}
        //
        className={clsx(className)}
        forcePopupIcon
        onChange={handleChange}
        //
        {...rest}
      />
    </SelectBox>
  );
};

export default FormAutoComplete;

//

export const renderOptionWithAvatar: NonNullable<BaseAutocompleteProps['renderOption']> = (props, option) => {
  //
  return (
    <Box component="li" {...props} key={option.value} sx={{ ...optionStyles }}>
      <Avatar src={option?.avatar} />
      <span>{option.label}</span>
    </Box>
  );
};

export const renderOptionWithIcon: NonNullable<BaseAutocompleteProps['renderOption']> = (props, option) => {
  return (
    <Box component="li" {...props} key={option.value} sx={{ ...optionStyles }}>
      {renderOptionIcon(option)}
      <span>{option.label}</span>
    </Box>
  );
};

export const renderOptionIcon = (option: OptionType) => {
  if (!option) return null;
  return (
    option.icon && (
      <OptionIcon
        style={{
          width: 24,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {<img src={option.icon} alt="icon" />}
      </OptionIcon>
    )
  );
};

export const createOptionExtractor = <T extends keyof OptionType>(what: T) => {
  //
  return (option: OptionType | string) => {
    // Value selected with enter, right from the input
    if (isString(option)) return option;

    // Regular option
    return option[what] || '';
  };
};

export const getOptionLabelDefault = createOptionExtractor('label');
export const getOptionValue = createOptionExtractor('value');

const optionStyles = {
  display: 'flex',
  gap: '.5rem',
  padding: '.5rem 1rem !important',
  alignItems: 'center',
  cursor: 'pointer',

  '& .MuiAvatar-root': {
    width: 24,
    height: 24,
  },
};

const SelectBox = styled.div`
  width: 100%;

  ${(props: { icon?: string }) =>
    props.icon &&
    css`
      & .MuiOutlinedInput-root input.MuiAutocomplete-input {
        padding: 1rem !important;
        padding-left: 3rem !important;
      }
    `}

  & .MuiOutlinedInput-root {
    padding: 0 !important;
    height: 56px !important;

    input.MuiAutocomplete-input {
      font-family: ${theme.fonts.montreal};
      padding-left: 1rem;
      height: 100%;
      box-sizing: border-box;
    }
  }
`;

const ListItem = styled.li`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Image = styled.img``;

const OptionIcon = styled.span`
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0.75rem;
`;
