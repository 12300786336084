import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
// components
import VerticalInfiniteGrid from 'components/InfiniteList/VerticalInfiniteGrid';
import JobCard from 'components/JobCard';
import EmptyJobs from './components/EmptyJobs';
import JobsFilter from './components/JobsFilter';
//
import { deleteJobAction, fetchJobsWorker } from 'domain/jobs/actions';
import { JobsContext } from './utils';
//
import CreateNewJobCard from 'components/JobCard/CreateNewJobCard';
import { Wrapper } from 'components/Layout';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { JobFiltersFormValues, JobsSortConfigs } from 'types/job/entities';
import { JobType } from 'types/job';
import { useAppDispatch } from 'domain/store';
import { toast } from 'react-toastify';
import { ConfirmDialog } from 'components/base';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';

const JobsList = () => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [jobIdToDelete, setJobIdToDelete] = useState(0);
  const dispatch = useAppDispatch();

  const filterConfig: JobFiltersFormValues = {
    sort: 'jobCreatedAtDesc',
    filters: { status: searchParams.get('status') || '', search: searchParams.get('search') || '' },
  };

  const {
    isLoading,
    isFetched,
    resources: jobResources,
    fetchNextPage,
    refetch: refetchJobs,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'companyJobsList',
    //
    fetchResources: fetchJobsWorker,
    initialSort: JobsSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: JobFiltersFormValues) => {
      const { sort, filters } = config;
      setSearchParams({ ...filters });
      setSort(JobsSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  useEffect(() => {
    if (status === null) {
      handleFiltersChange({ ...filterConfig, filters: { ...filterConfig.filters, status: '' } });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const deleteJob = (job: JobType) => {
    setJobIdToDelete(job.id);
  };

  const cancelDeletion = () => {
    setJobIdToDelete(0);
  };

  const confirmDeleteJob = () => {
    dispatch(deleteJobAction({ params: { id: jobIdToDelete } }))
      .unwrap()
      .then(() => {
        toast.success(formatMessage({ id: IntlKeys.jobDeleteSuccess }));
        refetchJobs();
        setJobIdToDelete(0);
      })
      .catch(() => {
        toast.error(formatMessage({ id: IntlKeys.jobDeleteError }));
        setJobIdToDelete(0);
      });
  };

  return (
    <JobsContext.Provider value={{ deleteJob, updateJob: refetchJobs }}>
      <Container>
        <JobsFilter onChange={handleFiltersChange} config={filterConfig} />
        <VerticalInfiniteGrid
          list={jobResources}
          //
          isLoading={isLoading}
          isLoaded={isFetched}
          page={pagination.page}
          pageCount={pagination.pageCount}
          itemCount={pagination.total}
          perPage={pagination.perPage}
          //
          fetchNext={fetchNextPage}
          emptyState={<EmptyJobs />}
          ListCard={JobCard}
          createCard={<CreateNewJobCard />}
          classes={{
            container: 'grid-container',
          }}
        />
      </Container>
      <ConfirmDialog
        isOpen={!!jobIdToDelete}
        title={formatMessage({ id: IntlKeys.jobDeleteQuestion })}
        subText={formatMessage({ id: IntlKeys.jobDeleteDescription })}
        onConfirm={confirmDeleteJob}
        onCancel={cancelDeletion}
        confirmBtnText={formatMessage({ id: IntlKeys.yes })}
      />
    </JobsContext.Provider>
  );
};

export default JobsList;

const Container = styled(Wrapper)`
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(min(380px, 100%), 1fr));
  }
`;
