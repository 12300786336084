export const nameToInitials = (name: string): string => {
  const portions = name.split(' ').map((val) => val[0]);

  return portions
    .slice(0, 2)
    .reduce((a, b) => a + b, '')
    .toUpperCase();
};

export const getRandomColor = (): string => '#' + (((1 << 24) * Math.random()) | 0).toString(16);

export const getNumberToCharacter = (number: number): string => (number + 9).toString(36).toUpperCase();

// how to get alphabet from number in typescript?
