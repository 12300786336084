import styled, { css } from 'styled-components';

interface FlexBoxProps {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  align?: 'center' | 'flex-start' | 'flex-end';
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  gap?: number | string;
}

const FlexBox = styled.div`
  display: flex;
  flex-direction: ${(props: FlexBoxProps) => (props.direction ? props.direction : 'row')};
  align-items: ${(props: FlexBoxProps) => (props.align ? props.align : 'flex-start')};
  justify-content: ${(props: FlexBoxProps) => (props.justify ? props.justify : 'flex-start')};
  gap: ${(props: FlexBoxProps) => (props.gap ? props.gap : 0)};
`;

export default FlexBox;
