import { Card, FlexBox, Text, Title2 } from 'components/base';
import InfiniteList from 'components/InfiniteList';
import { fetchInvoicesWorker } from 'domain/credits/actions';
import { formatDate } from 'helpers/date';
import { formatCurrency } from 'helpers/number';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import React from 'react';
import { BsDownload } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { InvoiceType } from 'types/credits';
import { pdfjs } from 'react-pdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Invoice from './invoice/Invoice';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Invoices = () => {
  const { formatMessage } = useIntl();
  const {
    isLoading,
    isFetched,
    resources: invoicesResource,
    fetchNextPage,
    //
    pagination,
  } = useInfiniteData({
    queryKey: 'invoicesList',
    //
    fetchResources: fetchInvoicesWorker,
    initialSort: {
      field: 'createdAt',
      order: 'DESC',
    },
    initialFilters: {},
  });

  return (
    <Container>
      <Title2>{formatMessage({ id: IntlKeys.invoicesHeading })}</Title2>
      <List>
        <InfiniteList
          list={invoicesResource}
          isLoading={isLoading}
          isLoaded={isFetched}
          page={pagination.page}
          pageCount={pagination.pageCount}
          perPage={pagination.perPage}
          itemCount={pagination.total}
          fetchNext={fetchNextPage}
          ListItem={({ data }) => <InvoiceCard invoice={data as unknown as InvoiceType} />}
          emptyState={<Text>{formatMessage({ id: IntlKeys.noInvoicesAvailable })}</Text>}
        />
      </List>
    </Container>
  );
};

export default Invoices;

interface InvoiceCardProps {
  invoice: InvoiceType;
}

export const InvoiceCard: React.FC<InvoiceCardProps> = ({ invoice }) => {
  const { formatMessage } = useIntl();

  const getPaymentStatusLabel = ({ isPaid, cancelInvoice }: InvoiceType) => {
    if (cancelInvoice) return formatMessage({ id: IntlKeys.invoiceHasStorno });
    if (isPaid) return formatMessage({ id: IntlKeys.invoicePaid });
    return formatMessage({ id: IntlKeys.invoiceNotPaid });
  };

  return (
    <EachInvoice withBorder>
      <BasicDetails align="center" justify="space-between">
        <EachField>{formatDate(invoice.createdAt)}</EachField>
        <EachField>{invoice.quantity} credits</EachField>
        <EachField>{formatCurrency(invoice.perItemPrice * invoice.quantity)}</EachField>
        <EachField>
          <Status isPaid={invoice.isPaid} isStorno={Boolean(invoice.cancelInvoice)}>
            {getPaymentStatusLabel(invoice)}
          </Status>
        </EachField>
      </BasicDetails>
      <EachField className="download">
        <PDFDownloadLink document={<Invoice invoice={invoice} />}>
          <BsDownload />
        </PDFDownloadLink>
      </EachField>
    </EachInvoice>
  );
};

const Container = styled.div`
  margin-top: 2rem;
`;

const List = styled.div`
  margin: 1rem 0;
`;

const BasicDetails = styled(FlexBox)`
  flex: 1;
`;

const EachInvoice = styled(Card)`
  margin: 0.5rem 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EachField = styled.div`
  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 0.8rem;
  }

  &.download {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: end;

    @media (max-width: ${theme.breakpoints.md}px) {
      font-size: 0.8rem;
      width: 28px;
    }
  }

  svg {
    cursor: pointer;
  }
`;

const Status = styled.div`
  background-color: ${(props: { isPaid: boolean; isStorno: boolean }) =>
    props.isStorno ? 'transparent' : props.isPaid ? theme.colors.success : theme.colors.warning};
  color: ${(props: { isPaid: boolean; isStorno: boolean }) => (props.isStorno ? theme.colors.red : theme.colors.white)};
  border: ${(props: { isPaid: boolean; isStorno: boolean }) =>
    props.isStorno ? '1px solid ' + theme.colors.red : 'none'};
  border-radius: 4px;
  padding: 0.25rem 1rem;
  width: fit-content;
  font-weight: 500;
  font-size: 0.875rem;

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 0.7rem;
    padding: 0.25rem;
  }
`;
