import { FlexBox, Text } from 'components/base';
import { updateApplicationAction } from 'domain/applications/actions';
import { useAppDispatch } from 'domain/store';
import { IntlKeys } from 'localization';
import React, { useCallback, useContext } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { Application, ApplicationActionType, ApplicationStatus } from 'types/application';
import { ApplicationsContext } from '../utils';

interface OwnProps {
  application: Application;
  callbackFn?: () => void;
}

const ApplicationCardActions: React.FC<OwnProps> = ({ application, callbackFn }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { addToUpdatedApplications } = useContext<{
    addToUpdatedApplications: (type: ApplicationActionType, application: Application) => void;
  }>(ApplicationsContext);

  const acceptorRejectApplication = useCallback(
    (e: React.SyntheticEvent<Element, Event> | undefined, type: ApplicationActionType) => {
      e?.stopPropagation();
      const payload = { status: type === 'accept' ? 'accepted' : 'declined' };
      if (payload.status === application.status) {
        return;
      }
      dispatch(updateApplicationAction({ params: { id: application.id }, ...payload }))
        .unwrap()
        .then(() => {
          addToUpdatedApplications?.(type, application);
          callbackFn?.();
        });
    },
    [application, dispatch, addToUpdatedApplications, callbackFn],
  );

  const ActionButton: React.FC<{ type: ApplicationActionType }> = ({ type }) => {
    return (
      <Button status={application.status} type={type} onClick={(e) => acceptorRejectApplication(e, type)}>
        <Text>
          {type == 'accept' ? formatMessage({ id: IntlKeys.match }) : formatMessage({ id: IntlKeys.nomatch })}
        </Text>
        <IconContainer type={type}>{type == 'accept' ? <MdCheck /> : <MdClose />}</IconContainer>
      </Button>
    );
  };

  return (
    <Actions gap={'.5rem'}>
      <ActionButton type="accept"></ActionButton>
      <ActionButton type="decline"></ActionButton>
    </Actions>
  );
};

export default ApplicationCardActions;

const Actions = styled(FlexBox)`
  margin-top: 1rem;
  width: 100%;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.lightGray};
  border-radius: 30px;
  cursor: pointer;
  gap: 0.5rem;
  padding: 2px 2px 2px 1rem;
  cursor: pointer;
  transition: all linear 0.15s;
  flex: 1;

  ${(props: { status?: ApplicationStatus; type: string }) =>
    props.status === 'accepted' &&
    props.type === 'accept' &&
    css`
      background-color: ${theme.colors.success};
      > div {
        background: ${theme.colors.success};
        filter: brightness(1.1);
      }
      svg {
        color: ${theme.colors.white};
      }
      p {
        color: ${theme.colors.white};
      }
    `}

  ${(props: { status?: ApplicationStatus; type: string }) =>
    props.status === 'declined' &&
    props.type === 'decline' &&
    css`
      background-color: ${theme.colors.red};
      > div {
        background: ${theme.colors.red};
        filter: brightness(1.1);
      }
      svg {
        color: ${theme.colors.white};
      }
      p {
        color: ${theme.colors.white};
      }
    `}

  &:hover {
    background: ${(props: { type: string }) => (props.type === 'accept' ? theme.colors.success : theme.colors.red)};

    p {
      color: ${theme.colors.white};
    }

    > div {
      background: ${(props: { type: string }) => (props.type === 'accept' ? theme.colors.success : theme.colors.red)};
      filter: brightness(1.1);
    }

    svg {
      color: ${theme.colors.white};
    }
  }
`;

const IconContainer = styled.div`
  color: ${(props: { type: string }) => (props.type === 'accept' ? theme.colors.success : theme.colors.red)};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};
  border-radius: 32px;
  transition: all linear 0.15s;
`;
