import { Card, FlexBox, SecText, Title3 } from 'components/base';
import DropdownMenu from 'components/base/DropdownMenu';
import { formatCurrency } from 'helpers/number';
import { nameToInitials } from 'helpers/string';
import { IntlKeys } from 'localization';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import clsx from 'clsx';
import { theme } from 'theme';
import { JobType } from 'types/job';
import { FiEdit } from 'react-icons/fi';
import { BsEyeFill, BsThreeDotsVertical } from 'react-icons/bs';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2';
import { MdComment, MdOutlineDelete } from 'react-icons/md';
import { ImRocket } from 'react-icons/im';
import { Button, Tooltip } from '@mui/material';
import { JobsContext } from 'pages/jobs/utils';
import { useIsMobile } from 'hooks/useIsMobile';
import { VscPreview } from 'react-icons/vsc';
import { addDaysAndFormat } from 'helpers/date';
import JobCommentsDialog from './JobCommentsDialog';
import { useSelector } from 'react-redux';
import { companyResourceSelector } from 'domain/company/selectors';
import { CompanyRoleEnum } from 'types/company';

interface OwnProps {
  data: unknown;
}

const colors = [theme.colors.maroon, theme.colors.success, theme.colors.warning];

const JobCard: React.FunctionComponent<OwnProps> = ({ data }) => {
  const job = data as JobType;
  const companyDetails = useSelector(companyResourceSelector);
  const previewLink =
    companyDetails?.role === CompanyRoleEnum.Demo ? `/jobs/${job.id}/preview?name=Sabine` : `/jobs/${job.id}/preview`;
  const comments = job.comments;
  const { formatMessage } = useIntl();
  const hiddenApplicationCount = job.applicationCount - 3; // We are showing upto 3 users avatar
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openCommentsDialog, setOpenCommentsDialog] = useState<boolean>(false);
  const { deleteJob, updateJob } = useContext<{
    deleteJob: (job: JobType) => void;
    updateJob: () => void;
  }>(JobsContext);
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const duplicateJob = () => {};

  const menuTrigger = (
    <MenuTrigger onClick={handleMenuClick}>
      <BsThreeDotsVertical size="small" aria-haspopup="true" />
    </MenuTrigger>
  );

  const menuActions: {
    label: string;
    icon: JSX.Element;
    onClick: () => void;
  }[] = [
    {
      label: formatMessage({ id: IntlKeys.viewApplications }),
      icon: <BsEyeFill />,
      onClick: () => {
        navigate(`/applications?jobId=${job.id}`);
      },
    },
    {
      label: formatMessage({ id: IntlKeys.jobDuplicate }),
      icon: <HiOutlineDocumentDuplicate />,
      onClick: duplicateJob,
    },
    {
      label: formatMessage({ id: IntlKeys.jobPreview }),
      icon: <VscPreview />,
      onClick: () => {
        window.open(previewLink, '_blank');
      },
    },
    ...(job.status === 'draft'
      ? [
          {
            label: formatMessage({ id: IntlKeys.edit }),
            icon: <FiEdit />,
            onClick: () => {
              navigate(`/jobs/edit?jobId=${job.id}`);
            },
          },
        ]
      : []),
    ...(job.status === 'draft'
      ? [
          {
            label: formatMessage({ id: IntlKeys.delete }),
            icon: <MdOutlineDelete />,
            onClick: () => deleteJob(job),
          },
        ]
      : []),
  ];
  const getEndDate = useCallback(() => {
    return job.priority ? addDaysAndFormat(job.publishedAt, 14) : addDaysAndFormat(job.publishedAt, 30);
  }, [job]);

  return (
    <Container width={'100%'} withBorder className={clsx({ 'card-menu-open': !!anchorEl && !isMobile })}>
      <CardWrapper gap={'1rem'} direction="column">
        <JobHead gap={'1rem'} justify="space-between" align="center">
          <div>
            <Title3>{job.title}</Title3>
            <ApplicationCount>
              <FormattedMessage
                id={IntlKeys.talentsProvided}
                values={{
                  applicationCount: job.applicationCount || 0,
                  maxCandidates: job.maxCandidates || 0,
                  b: (children) => <strong>{children}</strong>,
                }}
              />
            </ApplicationCount>
          </div>
          <DropdownMenu
            trigger={menuTrigger}
            anchorEl={anchorEl}
            handleClose={handleMenuClose}
            actions={menuActions}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={isMobile ? { marginLeft: '-1rem', marginTop: '1rem' } : { marginRight: '2rem', marginLeft: '2rem' }}
          />
        </JobHead>
        <JobDescription>
          <FormattedMessage
            id={IntlKeys.jobDescription}
            values={{
              minSalary: formatCurrency(job.minSalary),
              contractType: job.contractType,
              maxSalary: formatCurrency(job.maxSalary),
              maxCandidates: job.maxCandidates,
              location: job.city,
              endDate: getEndDate(),
              b: (children) => <strong>{children}</strong>,
            }}
          />
        </JobDescription>
        <JobCardFooter align="center" justify="space-between">
          <Applicants gap={'3px'} onClick={() => navigate(`/applications?jobId=${job.id}`)}>
            {job.applications?.length ? (
              <>
                {job.applications?.slice(0, 3).map((applicant, index) => (
                  <EachApplicant
                    bgColor={colors[index]}
                    key={applicant?.firstName + index}
                    align="center"
                    justify="center"
                  >
                    {nameToInitials(`${applicant.firstName} ${applicant.lastName}`)}
                  </EachApplicant>
                ))}
                {hiddenApplicationCount > 0 && (
                  <EachApplicant
                    bgColor={theme.colors.lightGray}
                    color={theme.colors.dark}
                    align="center"
                    justify="center"
                  >
                    {`+${hiddenApplicationCount}`}
                  </EachApplicant>
                )}
              </>
            ) : (
              <>
                {job.status === 'draft' ? (
                  <DraftLabel>{formatMessage({ id: IntlKeys.draft })}</DraftLabel>
                ) : (
                  <NoCandidates>{formatMessage({ id: IntlKeys.talentsZero })}</NoCandidates>
                )}
              </>
            )}
          </Applicants>
          {job.priority && (
            <Tooltip title={formatMessage({ id: IntlKeys.jobsExpressRecruiting })}>
              <span>
                <ImRocket className="express-hiring" />
              </span>
            </Tooltip>
          )}
          {job.comments && (
            <Button variant="outlined" startIcon={<MdComment />} onClick={() => setOpenCommentsDialog(true)}>
              {job.comments.length}
            </Button>
          )}
        </JobCardFooter>
      </CardWrapper>
      <JobCommentsDialog
        comments={comments}
        onClose={() => setOpenCommentsDialog(false)}
        open={openCommentsDialog}
        jobId={job.id}
        refetch={updateJob}
      />
    </Container>
  );
};

export default JobCard;

const Container = styled(Card)`
  min-height: 264px;
  display: flex;

  &.card-menu-open {
    position: absolute;
    z-index: 1201;
  }
`;

const CardWrapper = styled(FlexBox)`
  flex: 1;
`;

const JobHead = styled(FlexBox)`
  width: 100%;
`;

const JobDescription = styled.div`
  flex: 1;
  color: ${theme.colors.gray};

  & strong {
    color: ${theme.colors.secondary};
    font-weight: 400;
  }
`;

const ApplicationCount = styled(SecText)`
  line-height: 1rem;

  & strong {
    color: ${theme.colors.secondary};
  }
`;

const JobCardFooter = styled(FlexBox)`
  width: 100%;

  .express-hiring {
    color: ${theme.colors.red};
  }
`;

const Applicants = styled(FlexBox)`
  cursor: pointer;
`;

const EachApplicant = styled(FlexBox)`
  background-color: ${(props: { bgColor: string }) => props.bgColor};
  border-radius: 4px;
  color: ${(props: { color?: string }) => props.color ?? theme.colors.white};
  height: 36px;
  width: 36px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
`;

const NoCandidates = styled(SecText)`
  line-height: 36px;
`;

const MenuTrigger = styled.div`
  cursor: pointer;
  padding: 0.25rem 0rem 1rem;
  svg {
    font-size: 1rem;
    height: 1rem;
  }
`;

const DraftLabel = styled.div`
  background-color: ${theme.colors.warning};
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  font-weight: 530;
  border-radius: 5px;
  color: ${theme.colors.white};
`;
