import { createSlice } from '@reduxjs/toolkit';

import { getApplicationDetailsAction, updateApplicationAction } from './actions';

import { Application } from 'types/application';
import { ApplicationsSortConfigs } from 'types/application/entities';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isSubmitting: false,
    //
    isStale: false,
    //
  },
  filters: {} as Record<string, string>,
  sort: ApplicationsSortConfigs.createdAtAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as Application | null,
  resources: [] as Application[],
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder //
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getApplicationDetailsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getApplicationDetailsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;

        draft.resource = payload;
      })
      .addCase(getApplicationDetailsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
      })

      //////////////////////////////////////////////////////////////////////////
      .addCase(updateApplicationAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
        draft.meta.isSubmitting = true;
      })
      .addCase(updateApplicationAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        draft.meta.isSubmitting = false;

        draft.resource = payload;
      })
      .addCase(updateApplicationAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.meta.isSubmitting = false;
      });
  },
});

export const applicationsReducer = applicationsSlice.reducer;
