import { Button, FlexBox, SubmitButton } from 'components/base';
import { jobsMetaSelector } from 'domain/jobs/selectors';
import { IntlKeys } from 'localization';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
//
import { BsEyeFill } from 'react-icons/bs';
import { useIntl } from 'react-intl';

interface OwnProps {
  showPreview?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FormFooter: React.FC<OwnProps> = ({ showPreview }) => {
  const [canPreview, setCanPreview] = useState(false);
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const { isSubmitting } = useSelector(jobsMetaSelector);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (jobId) {
      setCanPreview(true);
    } else {
      setCanPreview(false);
    }
  }, [searchParams, jobId]);

  return (
    <Footer>
      <ButtonContainer align="center" justify={canPreview ? 'space-between' : 'flex-end'}>
        {canPreview && (
          <Button variant="outline" onClick={showPreview} disabled={isSubmitting}>
            <BsEyeFill /> {formatMessage({ id: IntlKeys.jobPreview })}
          </Button>
        )}

        <SubmitButton type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
          {formatMessage({ id: IntlKeys.jobProceed })}
        </SubmitButton>
      </ButtonContainer>
    </Footer>
  );
};

export default FormFooter;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 64px;
  border-top: 1px solid ${theme.colors.stroke};
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${theme.colors.white};
`;

const ButtonContainer = styled(FlexBox)`
  max-width: 800px;
  margin: auto;
  align-items: center;
  padding: 0rem 1rem;
  height: 100%;

  > button {
    padding: 0.5rem 1.5rem;
    width: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  }
`;
