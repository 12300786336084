import React, { useCallback, useEffect } from 'react';
import { isArray, isObject } from 'lodash';
import { RegisterOptions, useController, useFormContext } from 'react-hook-form';
import { Autocomplete, AutocompleteProps as MuiAutocompleteProps, TextField, TextFieldProps } from '@mui/material';
import { useFieldErrors } from 'helpers/forms/extractErrorProps';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { createOptionExtractor } from './FormAutoComplete';

export type OptionValue = string | number | null;
export type OptionType<
  //
  T = OptionValue,
  Entity extends object = object,
> = {
  label: string;
  value: T;
  //
  // refactor? react-hook-form Fails to unpack values if there's ReactNode in option
  icon?: string;
  avatar?: string;
  //
  isCreated?: boolean;
  relatedEntity?: Entity;
  onClick?: () => void;
};
export type BaseAutocompleteProps = MuiAutocompleteProps<OptionType, boolean, boolean, boolean>;
export type AutocompleteProps = Omit<BaseAutocompleteProps, 'renderInput'>;
export type OverwrittenProps = {
  textFieldProps?: TextFieldProps;
  withAvatar?: boolean;
  generateAvatar?: () => string;
  renderTagIcon?: (selected: string) => React.ReactElement;
};

export type OwnProps = AutocompleteProps &
  OverwrittenProps & {
    name: string;
    setOption?: (value: OptionType) => void;
    defaultOption?: OptionType;
    disableTyping?: boolean;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    inputProps: TextFieldProps;
  };

export const isOptionType = function <T = OptionValue>(value: unknown): value is OptionType<T> {
  if (!isObject(value)) return false;

  const possiblyOption = value as OptionType;

  return possiblyOption.value != null && possiblyOption.label != null;
};

export const matchValueToOption = (
  //
  value: OptionValue,
  options: readonly OptionType[],
): OptionType | null => {
  const maybeOption = options.find(({ value: optionValue }) => value === optionValue);

  return value == null ? null : isOptionType(maybeOption) ? maybeOption : transformIntoOption(value);
};

type TransformConfig = {
  generateAvatar?: () => string;
  isCreated?: boolean;
};

export const transformIntoOption = (value: string | number, { generateAvatar }: TransformConfig = {}): OptionType => ({
  value: value,
  label: String(value),
  avatar: generateAvatar ? generateAvatar() : undefined,
  isCreated: true,
});

type FormAutocompleteFieldValue = NonNullable<OptionValue> | NonNullable<OptionValue>[];

const FormAutoCompleteMultiple: React.FC<OwnProps> = ({
  defaultOption,
  className,
  textFieldProps = {},
  rules,
  name,
  onChange: onChangeExternal,
  getOptionLabel = getOptionLabelDefault,
  disableTyping,
  inputProps,
  ...rest
}) => {
  const { control } = useFormContext();
  const fieldErrorProps = useFieldErrors(name);
  const {
    field: { value = [], onChange, onBlur },
  } = useController({
    name,
    control,
    rules,
  });

  useEffect(() => {
    if (defaultOption) {
      onChange(defaultOption as OptionType<OptionValue, object>);
    }
  }, [defaultOption]);

  const handleChange = useCallback<NonNullable<OwnProps['onChange']>>(
    (e, value, reason, details) => {
      if (!isArray(value)) return;
      onChange(value?.map((option) => (option as OptionType<OptionValue, object>).value));
      onChangeExternal?.(e, value, reason, details);
    },
    [onChange, onChangeExternal],
  );

  const transformValues = (values: number[]): OptionType<OptionValue, object>[] => {
    // @ts-expect-error
    return (
      rest.options
        ?.map((option) => {
          if (values?.includes(option!.value as number)) {
            return option;
          }
        })
        .filter((each) => Boolean(each)) || []
    );
  };

  return (
    <SelectBox icon={value?.icon || defaultOption?.icon}>
      <Autocomplete
        multiple
        value={transformValues(value)}
        options={rest.options}
        getOptionLabel={(option) => option.label}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} {...inputProps} variant="outlined" />}
      />
    </SelectBox>
  );
};

export default FormAutoCompleteMultiple;

export const getOptionLabelDefault = createOptionExtractor('label');
export const getOptionValue = createOptionExtractor('value');

const SelectBox = styled.div`
  width: 100%;

  ${(props: { icon?: string }) =>
    props.icon &&
    css`
      & .MuiOutlinedInput-root input.MuiAutocomplete-input {
        padding: 1rem !important;
        padding-left: 3rem !important;
        background-color: ${theme.colors.lightGray};
      }
    `}

  & .MuiOutlinedInput-root {
    background-color: ${theme.colors.lightGray};
    /* padding: 0 !important;
    height: 56px !important; */
    input.MuiAutocomplete-input {
      font-family: ${theme.fonts.montreal};
      padding-left: 1rem;
      height: 100%;
      box-sizing: border-box;
    }
  }

  & .MuiChip-root.MuiChip-filled {
    background-color: #e3ebf1;
    color: ${theme.colors.black};
  }

  & fieldset.MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;
