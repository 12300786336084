import React, { HTMLProps, useCallback, useEffect, useLayoutEffect } from 'react';
import {
  useForm,
  useFormContext,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  FieldValues,
  WatchObserver,
} from 'react-hook-form';

import { useValueUpdatedAt } from 'hooks/useIsEqualWithPrevious';

// @ts-expect-error
type FormContextType<T = FieldValues> = UseFormReturn<T> & {
  //
};
export const useHookFormContext = function <T = FieldValues>() {
  return useFormContext() as FormContextType<T>;
};
//
const dummyFunc = () => {};
//
export interface OwnProps extends UseFormProps {
  onSubmit?: (values: FieldValues) => void;
  onChange?: (
    values: Parameters<WatchObserver<FieldValues>>[0],
    info: Parameters<WatchObserver<FieldValues>>[1],
    controlBag: Pick<
      UseFormReturn<FieldValues>,
      'setValue' | 'setError' | 'setFocus' | 'reset' | 'resetField' | 'clearErrors'
    >,
  ) => void;
  //
  omitFormWrapper?: boolean;
  children: React.ReactNode;
  className?: string;
}
const HookForm: React.FC<OwnProps> = ({
  onSubmit = dummyFunc,
  onChange,
  //
  omitFormWrapper = false,
  children,
  //
  className,
  ...formProps
}) => {
  const formBag = useForm({
    ...formProps,
  });
  const {
    //
    watch,
    handleSubmit,
    setValue,
    setError,
    setFocus,
    reset,
    resetField,
    clearErrors,
  } = formBag;

  const defaultValuesUpdatedAt = useValueUpdatedAt(formProps.defaultValues);

  const FormWrapper = useCallback<React.FC<HTMLProps<HTMLFormElement>>>(
    ({ onSubmit, className, children }) => {
      return omitFormWrapper ? (
        <div className={className}>{children}</div>
      ) : (
        <form className={className} onSubmit={onSubmit}>
          {children}
        </form>
      );
    },
    [omitFormWrapper],
  );

  useLayoutEffect(() => {
    reset(formProps.defaultValues);
    // since defaultValues are often declared as object literal prop it'll change by reference every time
    // instead of looking directly at defaultValues we look at when it has become
    // not deep equal with defaultValues in previous render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValuesUpdatedAt, reset]);

  useEffect(() => {
    // Just for the case when you need to watch form state outside of form context
    // Probably better use useWatch in components
    if (onChange) {
      const subscription = watch((...args) =>
        onChange(...args, {
          setValue,
          setError,
          setFocus,
          reset,
          resetField,
          clearErrors,
        }),
      );

      return () => subscription.unsubscribe();
    }
  }, [watch, onChange, setValue, setError, setFocus, reset, resetField, clearErrors]);

  return (
    <FormProvider {...formBag}>
      <FormWrapper className={className} onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}>
        {children}
      </FormWrapper>
    </FormProvider>
  );
};

export default React.memo(HookForm);
