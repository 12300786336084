import { getPublicJobDetailsAction } from 'domain/jobs/actions';
import { useAppDispatch } from 'domain/store';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useSearchParams } from 'react-router-dom';
import JobLandingPage from './components/JobLandingPage';
import { useSelector } from 'react-redux';
import { publicJobResourceSelector } from 'domain/jobs/selectors';

interface OwnProps {}

const JobDescription: React.FC<OwnProps> = () => {
  const dispatch = useAppDispatch();
  const { id: jobId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const jobDetails = useSelector(publicJobResourceSelector);

  useEffect(() => {
    if (jobId) {
      dispatch(getPublicJobDetailsAction({ params: { jobId: jobId! }, token: token }));
    }
  }, [dispatch, jobId, token]);

  if (!jobDetails) return null;

  return (
    <Container>
      <JobLandingPage jobDetails={jobDetails!} />
    </Container>
  );
};

export default JobDescription;

const Container = styled.section``;
