import React from 'react';
import { FormProvider } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
// utils
import { IntlKeys } from 'localization/keys';
import { extractIntlKey } from 'localization/keys';
// components
import FormTextField from 'components/hook-form/fields/FormTextField';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import ErrorMessage from 'components/base/ErrorMessage';
// local
import useAuthPage from '../hooks/useAuthPage';
// styles
import logo from 'assets/images/tandf-logo.svg';
// import authPlaceholderImage from 'assets/auth-placeholder-image.svg';
import Button from 'components/base/Button';
import { Card, FlexBox } from 'components/base';
import styled from 'styled-components';

const ForgotPasswordPage = () => {
  const { formatMessage } = useIntl();
  //
  const { form, onSubmit, isLoading, errorMessageId } = useAuthPage({ authPage: 'forgotPassword' });

  return (
    <Container align="center" justify="center">
      <Card withBorder width={'400px'}>
        <img src={logo} />

        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <h2>{formatMessage({ id: IntlKeys.forgotPasswordQuestion })}</h2>
            <h3>{formatMessage({ id: IntlKeys.forgotPasswordEnterEmail })}</h3>

            <InputWrapper label={formatMessage({ id: IntlKeys.email })}>
              <FormTextField name="email" placeholder="codyfisher@mail.com" />
            </InputWrapper>

            <Button type="submit" disabled={isLoading}>
              {formatMessage({ id: IntlKeys.forgotPasswordSendLink })}
            </Button>

            {errorMessageId && <ErrorMessage errorMessage={formatMessage({ id: extractIntlKey(errorMessageId) })} />}

            <div>
              <NavLink to={'/login'}>{formatMessage({ id: IntlKeys.forgotPasswordBackToLogin })}</NavLink>
            </div>
          </form>
        </FormProvider>
      </Card>

      <div>{/* <img src={authPlaceholderImage} alt="auth placeholder image" /> */}</div>
    </Container>
  );
};

export default ForgotPasswordPage;

const Container = styled(FlexBox)`
  height: 100vh;
  width: 100vw;
`;
