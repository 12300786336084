import { FilledInputClasses, InputClasses, OutlinedInputClasses, TextField, TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
// utils
import { useFieldErrors } from 'helpers/forms/extractErrorProps';
import { mergeClasses } from 'helpers/styles';
//
import styled from 'styled-components';
import { theme } from 'theme';
import { useHookFormContext } from '../index';
import { ensureValueControlled } from '../utils';

//
export type OwnProps = TextFieldProps & {
  //
  name: string;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  min?: number;
  //
  InputProps?: TextFieldProps['InputProps'] & {
    classes?: Partial<OutlinedInputClasses> | Partial<InputClasses> | Partial<FilledInputClasses>;
  };
};
const FormTextField: React.FC<OwnProps> = ({
  name,
  rules,
  //
  className,
  //
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  ...textFieldProps
}) => {
  const formContext = useHookFormContext();

  // const { control } = useHookFormContext();
  const fieldErrorProps = useFieldErrors(name);
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name,
    control: formContext?.control,
    rules,
    shouldUnregister: false,
  });

  const handleChange: TextFieldProps['onChange'] = (e) => {
    const modifiedEvent = {
      ...e,
      target: {
        ...e.target,
        value: e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value,
      },
    };

    if (externalOnChange) {
      // @ts-expect-error e.target.value: string, In our case it can be a number for comfortable handling a number input
      externalOnChange(modifiedEvent);
    }

    onChange(modifiedEvent);
  };

  const handleBlur: TextFieldProps['onBlur'] = (e) => {
    if (externalOnBlur) {
      externalOnBlur(e);
    }

    onBlur();
  };

  return (
    <TextFieldComponent
      //
      {...textFieldProps}
      {...fieldErrorProps}
      //
      value={ensureValueControlled(value)}
      onChange={handleChange}
      onBlur={handleBlur}
      //
      className={className}
    />
  );
};

export const TextFieldComponent: React.FC<TextFieldProps> = ({ className, InputProps = {}, ...props }) => {
  const inputClasses = useMemo(() => {
    return mergeClasses({
      externalClasses: InputProps.classes,
      externalClassName: InputProps.className,
    });
  }, [InputProps.classes, InputProps.className]);

  return (
    <CustomTextField
      {...props}
      className={clsx(className)}
      InputProps={{
        ...InputProps,
        classes: inputClasses,
      }}
    />
  );
};

const CustomTextField = styled(TextField)`
  width: 100%;

  & fieldset,
  &:active fieldset,
  &:active input {
    border-color: transparent;
    border-radius: 4px;
  }

  &:hover input,
  &:focus input {
    border-radius: 30px;
  }

  & input {
    background-color: ${theme.colors.lightGray};
    font-family: ${theme.fonts.montreal};
    border-radius: 4px;
  }

  & .Mui-disabled {
    & fieldset {
      border-color: ${theme.colors.stroke} !important;
      opacity: 0.75;
    }
    &:hover fieldset {
      border-color: ${theme.colors.stroke};
      opacity: 0.75;
    }
  }

  & input:disabled {
    background-color: ${theme.colors.lightGray};
    -webkit-text-fill-color: ${theme.colors.dark};
    opacity: 0.75;
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset,
    &:focus fieldset {
      border-radius: 30px;
      border-color: ${theme.colors.stroke} !important;
    }
    &.Mui-focused fieldset {
      border-width: 1px;
      border-color: ${theme.colors.stroke} !important;
      border-radius: 30px;
    }

    &.Mui-focused input {
      border-radius: 30px;
    }
  }
`;

export default React.memo(FormTextField);
