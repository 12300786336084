import { useCallback, useState } from 'react';

import { ReactComponent as ShowPasswordIcon } from 'assets/icons/eye.svg';
import { ReactComponent as HidePasswordIcon } from 'assets/icons/eye_crossed-out.svg';

const usePasswordVisibility = (defaultShowPassword = false) => {
  const [showPassword, setShowPassword] = useState(defaultShowPassword);
  const inputType = showPassword ? 'text' : 'password';
  const VisibilityIcon = showPassword ? HidePasswordIcon : ShowPasswordIcon;

  const onToggleShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, []);

  return {
    inputType,
    VisibilityIcon,
    onToggleShowPassword,
  };
};

export default usePasswordVisibility;
