import { Card, FlexBox } from 'components/base';
import { formatCurrency } from 'helpers/number';
import { IntlKeys } from 'localization';
import { seniorityOptions, workplaceOptions, workTypeOptions } from 'pages/createNewJob';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { JobType } from 'types/job';

interface OwnProps {
  job: JobType | null;
}

const Summary: React.FC<OwnProps> = ({ job }) => {
  const { formatMessage } = useIntl();

  if (!job) return null;

  return (
    <SummaryCard withBorder>
      <EachRow>
        <Label>{formatMessage({ id: IntlKeys.jobPosition })}</Label>
        <Value>{job.title}</Value>
      </EachRow>
      <EachRow>
        <Label>{formatMessage({ id: IntlKeys.seniority })}</Label>
        <Value>{seniorityOptions.find((seniority) => job.seniority == seniority.id)?.label}</Value>
      </EachRow>
      <EachRow>
        <Label>{formatMessage({ id: IntlKeys.location })}</Label>
        <Value>{workplaceOptions.find((location) => job.location == location.id)?.label}</Value>
      </EachRow>
      <EachRow>
        <Label>{formatMessage({ id: IntlKeys.contractType })}</Label>
        <Value>{workTypeOptions.find((type) => job.contractType == type.id)?.label}</Value>
      </EachRow>
      <EachRow>
        <Label>{formatMessage({ id: IntlKeys.jobSalary })}</Label>
        <Value>
          {formatCurrency(job.minSalary)} - {formatCurrency(job.maxSalary)}
        </Value>
      </EachRow>
    </SummaryCard>
  );
};

export default Summary;

const SummaryCard = styled(Card)`
  margin-bottom: 1rem;
`;

const EachRow = styled(FlexBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem;
`;

const Label = styled.span``;

const Value = styled.span`
  font-weight: 530;
`;
