import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
// utils
import translations, { initializeIntl, LangCode } from 'localization';
import { fallbackLocale } from 'localization/utils';
import { history } from 'helpers/history';
//
import { authHeadersAvailableSelector, authIsPendingSelector, authUserSelector } from 'domain/auth/selectors';
//
import revalidateToken from 'helpers/http/revalidateToken';
//
import { Loader } from 'components/loader';
//
import AppRoutes from 'Routes/AppRoutes';
import { useAppDispatch } from 'domain/store';
import { fetchCurrentUserAction } from 'domain/auth/actions';

const App: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  history.navigate = useNavigate();
  history.location = useLocation();
  const user = useSelector(authUserSelector);
  const isAuthLoading = useSelector(authIsPendingSelector);
  const authHeadersAvailable = useSelector(authHeadersAvailableSelector);
  const dispatch = useAppDispatch();

  //
  const locale: LangCode = user?.language ?? fallbackLocale;

  useEffect(() => {
    initializeIntl(locale);
  }, [locale]);

  useEffect(() => {
    try {
      revalidateToken();
    } catch (error) {}
  }, []);

  useEffect(() => {
    authHeadersAvailable && dispatch(fetchCurrentUserAction());
  }, [authHeadersAvailable, dispatch]);

  return (
    <div className="App">
      <IntlProvider locale={locale} messages={translations[locale]}>
        {isAuthLoading ? <Loader /> : <AppRoutes />}

        {children}
      </IntlProvider>
    </div>
  );
};

export default App;
