import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import mime from 'mime';
import Dropzone, { CustomDropzoneProps } from '.';
import { videoMimeTypesArray } from './entities';

interface OwnProps extends CustomDropzoneProps {
  src?: string;
  onDelete?: () => void;
  onDrop: (files: File[]) => void;
  previewClassName?: string;
}

const VideoDropzone: React.FC<OwnProps> = ({ src, onDelete, onDrop, previewClassName = '', ...dropZoneProps }) => {
  const [mimeType, setMimeType] = useState<string | null>(null);
  useEffect(() => {
    if (src?.startsWith('blob')) {
      setMimeType('video');
    } else {
      setMimeType(src ? mime.getType(src) : null);
    }
  }, [src]);
  return (
    <VideoDropzoneContainer>
      {src && mimeType?.startsWith('video') ? (
        <PreviewContainer className={previewClassName}>
          <ReactPlayer
            url={src}
            controls
            width={'100%'}
            wrapper={VideoPlayer}
            config={{
              file: {
                attributes: {
                  crossOrigin: 'true',
                },
              },
            }}
            {...dropZoneProps}
          />
        </PreviewContainer>
      ) : (
        <Dropzone accept={videoMimeTypesArray} onDrop={onDrop} {...dropZoneProps} />
      )}
    </VideoDropzoneContainer>
  );
};

export default VideoDropzone;

const VideoDropzoneContainer = styled.div`
  width: 100%;
`;

const PreviewContainer = styled.div`
  max-width: 100%;
  width: auto;
`;

const VideoPlayer = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;
