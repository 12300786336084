import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormProvider, FieldValues } from 'react-hook-form';
//
import { Text } from 'components/base';
import { Wrapper } from 'components/Layout';
import { jobsResourceSelector } from 'domain/jobs/selectors';
import useJobCreation from 'hooks/useJobCreation';
import FormFooter from './FormFooter';
import { theme } from 'theme';
import { QuestionRenderer, QuestionOptionType } from './QualificationQuestions/Questions';
import AddNewQuestion from './QualificationQuestions/AddNewQuestion';
import { createQualificationQuestion, updateQualificationQuestion } from '../helpers/questions';
import { IntlKeys } from 'localization';
import { toast } from 'react-toastify';
import { QualificationQuestion, QualificationQuestionsPayload } from 'types/job';
import { deleteQuestionAction, getJobDetailsAction } from 'domain/jobs/actions';
import { useAppDispatch } from 'domain/store';

interface OwnProps {
  updateUrlQueryParam: (params: Record<string, string>) => void;
  updateStep: (step: number) => void;
  searchParams: URLSearchParams;
}

const Step3: React.FC<OwnProps> = ({ updateUrlQueryParam, updateStep, searchParams }) => {
  const jobDetails = useSelector(jobsResourceSelector);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const initialQuestions = jobDetails?.questions
    ? jobDetails?.questions.map(({ type, question, choices, disqualifying, id }, index) => ({
        id,
        type,
        position: index,
        question,
        choices,
        disqualifying,
      }))
    : ([
        {
          type: 'yesOrNo' as QualificationQuestion,
          position: 0,
          question: '',
          choices: [],
          disqualifying: [],
          id: null,
        },
      ] as QualificationQuestionsPayload[]);
  const [questions, setQuestions] = useState(initialQuestions);
  const jobId = searchParams.get('jobId');

  const { form } = useJobCreation({ step: 'qualificationQnA', defaultValues: {} });

  const initializeFormValue = useCallback(
    (questions: QualificationQuestionsPayload[]) => {
      questions.forEach((eachQuestion) => {
        form.setValue(`question.${eachQuestion.position}.question`, eachQuestion.question);
        form.setValue(`question.${eachQuestion.position}.type`, eachQuestion.type);
        form.setValue(`question.${eachQuestion.position}.id`, eachQuestion.id);
        form.setValue(`question.${eachQuestion.position}.disqualifying`, eachQuestion.disqualifying);
        eachQuestion?.choices?.forEach((choice, index) => {
          form.setValue(`question.${eachQuestion.position}.choices.${index}`, choice);
        });
      });
    },
    [form],
  );

  useEffect(() => {
    if (jobDetails) {
      const initialQuestions = jobDetails?.questions
        ? jobDetails?.questions.map(({ type, question, choices, disqualifying, id }, index) => ({
            type,
            position: index,
            question,
            choices,
            disqualifying,
            id,
          }))
        : ([
            {
              type: 'yesOrNo' as QualificationQuestion,
              position: 0,
              question: '',
              choices: [],
              disqualifying: [],
              id: null,
            },
          ] as QualificationQuestionsPayload[]);
      setQuestions(initialQuestions);
      initializeFormValue(initialQuestions);
    }
  }, [initializeFormValue, jobDetails]);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      if (!data?.question) {
        if (jobId) {
          updateUrlQueryParam({ jobId, step: '4' });
          updateStep(4);
        }
        return;
      }

      const questionArray: QualificationQuestionsPayload[] =
        data?.question.filter((question: QualificationQuestionsPayload) => Boolean(question?.question)) || [];

      await Promise.all(
        questionArray.map((question: QualificationQuestionsPayload) => {
          return question.id
            ? updateQualificationQuestion({ params: { jobId: jobId!, questionId: question.id }, ...question })
            : createQualificationQuestion({ params: { id: jobId }, ...question });
        }),
      )
        .then(() => {
          if (jobId) {
            updateUrlQueryParam({ jobId, step: '4' });
            updateStep(4);
          }
          dispatch(getJobDetailsAction({ params: { id: jobId! } }));
        })
        .catch(() => {
          toast.error(formatMessage({ id: IntlKeys.tryAgainMessage }));
        });
    },
    [dispatch, formatMessage, jobId, updateStep, updateUrlQueryParam],
  );

  const showPreview = useCallback(() => {
    window.open(`/jobs/${jobId}/preview`, '_blank');
  }, [jobId]);

  const onAddNewQuestionType = (type: QualificationQuestion) => {
    const newQuestion = {
      type,
      position: questions[questions.length - 1] ? questions[questions.length - 1]?.position + 1 : 0,
      question: '',
      choices: [],
      disqualifying: null,
      id: null,
    };

    setQuestions((questions) => [...questions, newQuestion]);
  };

  const removeQuestion = useCallback(
    (position: number) => {
      const question = questions.find((question) => question.position == position);
      if (!question) return;
      if (question.id) {
        dispatch(deleteQuestionAction({ params: { jobId: jobId!, questionId: question.id } }))
          .unwrap()
          .then(() => {
            dispatch(getJobDetailsAction({ params: { id: jobId! } }));
          });
      }
      const newQuestions = questions.filter((question) => question.position !== position);
      form.setValue(`question.${position}`, null);
      setQuestions(newQuestions);
    },
    [questions, form, dispatch, jobId],
  );

  const updateQuestionType = (option: QuestionOptionType, id: number) => {
    const changedIndex = questions.indexOf(questions.filter((each) => each.position == id)[0]);
    if (changedIndex > -1) {
      const questionsCopy = [...questions];
      questionsCopy[changedIndex].type = option.value;
      setQuestions(questionsCopy);
    }
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <FormContainer>
          <Text>{formatMessage({ id: IntlKeys.qualificationQuestionDescription })}</Text>
          {questions.map((eachQuestion) => (
            <QuestionRenderer
              initialValue={eachQuestion}
              removeQuestion={removeQuestion}
              questionType={eachQuestion.type}
              key={eachQuestion.position}
              id={eachQuestion.position}
              updateQuestionType={updateQuestionType}
            />
          ))}

          <AddNewQuestion onAdd={onAddNewQuestionType} />
        </FormContainer>
        <FormFooter showPreview={showPreview} />
      </Form>
    </FormProvider>
  );
};

export default Step3;

const Form = styled.form`
  width: 100%;
`;

const FormContainer = styled(Wrapper)`
  padding: 1rem;
  max-width: 800px;

  > p {
    border-left: 4px solid ${theme.colors.disabledGray};
    padding-left: 0.75rem;
    font-size: 0.875rem;
    color: ${theme.colors.gray};
  }
`;
