import { Button, FlexBox } from 'components/base';
import { IntlKeys } from 'localization';
import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { theme } from 'theme';

interface OwnProps {
  currentStep: number;
  updateStep: (step: number) => void;
  canPreview?: boolean;
}

const StepsProgress: React.FC<OwnProps> = ({ currentStep, updateStep, canPreview }) => {
  const { formatMessage } = useIntl();
  const steps = [
    {
      label: formatMessage({ id: IntlKeys.jobDetails }),
      progress: 1,
    },
    {
      label: formatMessage({ id: IntlKeys.customization }),
      progress: 2,
    },
    {
      label: formatMessage({ id: IntlKeys.qualifyingQuestion }),
      progress: 3,
    },
    {
      label: formatMessage({ id: IntlKeys.summary }),
      progress: 4,
    },
  ];

  const navigateToStep = (step: number) => {
    if (canPreview) {
      updateStep(step);
    } else {
      return;
    }
  };

  return (
    <StepsContainer align="center">
      {steps.map((step) => (
        <EachStep
          key={step.progress}
          isActive={currentStep === step.progress}
          onClick={() => navigateToStep(step.progress)}
        >
          {step.label}
        </EachStep>
      ))}
    </StepsContainer>
  );
};

export default StepsProgress;

const StepsContainer = styled(FlexBox)`
  padding: 1rem;
  width: 100%;
  max-width: 800px;
  margin: auto;
`;

interface StepProps {
  isActive?: boolean;
}

const EachStep = styled.div`
  padding: 0.75rem 1rem;
  cursor: pointer;
  color: ${(props: StepProps) => (props.isActive ? theme.colors.secondary : theme.colors.gray)};
  font-weight: ${(props: StepProps) => (props.isActive ? 600 : 400)};
  ${(props: StepProps) =>
    !props.isActive &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

const PreviewButton = styled(Button)`
  padding: 0.5rem 1rem;
`;
