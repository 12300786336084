import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { InvoiceType } from 'types/credits';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  billToContainer: {
    paddingBottom: 3,
    fontFamily: 'Helvetica-Oblique',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  invoiceContainer: {
    flexDirection: 'column',
  },
  invoiceHead: {
    borderBottom: '1px solid black',
  },
  invoiceRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold',
  },
  label: {
    width: 60,
  },
});

interface OwnProps {
  invoice: InvoiceType;
}

const BillTo: React.FC<OwnProps> = ({ invoice }) => (
  <View style={styles.headerContainer}>
    <View style={styles.invoiceContainer}>
      <View style={styles.invoiceHead}>
        <Text>Talent & Friends GmbH, Platz der Republik 48a, 42107 Wuppertal</Text>
      </View>
      <View style={styles.billToContainer}>
        <Text>{invoice.company.name}</Text>
        <Text>
          {invoice?.address?.street} {invoice?.address?.housenumber}
        </Text>
        <Text>
          {invoice?.address?.postcode} {invoice.address.city}
        </Text>
        <Text>{invoice.address.country}</Text>
      </View>
    </View>
  </View>
);

export default BillTo;
