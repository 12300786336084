import { createSelector } from '@reduxjs/toolkit';
//
import { RootState } from '../store';

const authSelector = (state: RootState) => state.auth;
export const authHeadersSelector = createSelector(authSelector, (auth) => auth.headers);
export const authHeadersAvailableSelector = createSelector(authSelector, ({ headers }) => {
  return Object.values(headers).every(Boolean);
});
export const authMetaSelector = createSelector(authSelector, (auth) => auth.meta);
export const authIsPendingSelector = createSelector(authMetaSelector, (meta) => meta.isLoading);

export const authUserSelector = createSelector(authSelector, (auth) => auth.user);
export const authUserAvatarSelector = createSelector(authUserSelector, (user) => user?.avatar);

export const authIsAuthorizedSelector = createSelector(
  authUserSelector,
  authHeadersAvailableSelector,
  (user, headersAvailable) => {
    return user && headersAvailable;
  },
);
