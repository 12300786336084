import { FileType } from 'types/common';
import { Address, SubscriptionType } from 'types/credits';
import { Gallery } from 'types/job';

export interface CompanyType {
  id: number;
  name: string;
  description: Record<string, string>;
  logo?: FileType | null;
  foundingDate: string;
  industry: string;
  website: string;
  employeeCount: string;
  defaultCover: FileType | null;
  defaultVideo: FileType | null;
  defaultColor: string | null;
  createdAt: string;
  updatedAt: string;
  defaultGallery: Gallery[];
  linkedin?: string;
  xing?: string;
  address: {
    country: string;
    city: string;
    postcode: string;
    street: string;
    housenumber: string;
  };
  billingAddress: Address;
  subscription: SubscriptionType;
  role: CompanyRoleEnum;
}

export enum CompanyRoleEnum {
  Demo = 'demo',
  Recruiter = 'recruiter',
  Recruiting = 'recruiting',
}

export interface CompanyPayloadType {
  name: string;
  description: string;
  logo: string;
  foundingDate: string;
  industry: string;
  website: string;
  employeeCount: string;
  defaultCover: FileType;
  defaultVideo: FileType;
  defaultColor: string;
  defaultGallery: Gallery[];
  createdAt: string;
  updatedAt: string;
  linkedin?: string;
  xing?: string;
  address: {
    country: string;
    city: string;
    postcode: string;
    street: string;
    housenumber: string;
  };
}

export interface CompanyCustomizationPayloadType {
  defaultCover?: FileType | null;
  defaultVideo?: FileType | null;
  defaultColor?: string | null;
  defaultGallery: Gallery[];
}
