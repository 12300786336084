import { IntlKeys } from 'localization';
import React from 'react';
import { useIntl } from 'react-intl';

interface OwnProps {}

export const Loader: React.FC<OwnProps> = ({}) => {
  const { formatMessage } = useIntl();
  return <div>{formatMessage({ id: IntlKeys.loading })}</div>;
};
