import React from 'react';
import styled from 'styled-components';
//
import { Carousel } from 'components/base';
//
import { Gallery as GalleryType } from 'types/job';

interface OwnProps {
  gallery: GalleryType[];
}

const Gallery: React.FC<OwnProps> = ({ gallery }) => {
  if (!gallery) return null;
  return (
    <GalleryImageSection>
      <Carousel className={'gallery-images'}>
        {gallery.map((image) => (
          // <Eachgallery url={image.url} key={image.id} />
          <Image src={image.url} key={image.id} />
        ))}
      </Carousel>
    </GalleryImageSection>
  );
};

export default Gallery;

const GalleryImageSection = styled.div`
  position: relative;
  width: 100%;
  max-height: 300px;
  margin: 4rem auto;

  img {
    height: 300px;
    width: auto;
    border-radius: 10px;
  }

  .slick-slide > div {
    padding: 0rem 0.25rem;
  }
`;

const Image = styled.img``;

const Eachgallery = styled.div`
  background-image: url(${(props: { url: string }) => props.url});
  height: 300px;
  width: 200px;
`;
