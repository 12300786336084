import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { InvoiceType } from 'types/credits';
import { formatDate } from 'helpers/date';

const borderColor = '#050622';
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderTopColor: borderColor,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    textAlign: 'left',
    marginTop: 20,
    padding: '10px 0',
  },
  title: { fontWeight: 'bold' },
});

interface OwnProps {
  invoice: InvoiceType;
  isStorno?: boolean;
}

const InvoiceNumber: React.FC<OwnProps> = ({ invoice, isStorno }) =>
  isStorno ? (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>Storno-Nr.:</Text>
        <Text>{invoice.cancelInvoice?.id}</Text>
      </View>
      <View>
        <Text style={styles.title}>zur RE-Nr.:</Text>
        <Text>{invoice.id}</Text>
      </View>
      <View>
        <Text style={styles.title}>Kundennummer</Text>
        <Text>{invoice.companyId}</Text>
      </View>
      <View>
        <Text style={styles.title}>Datum</Text>
        <Text>{formatDate(invoice.cancelInvoice?.createdAt || invoice.createdAt)}</Text>
      </View>
    </View>
  ) : (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>RE-Nr.:</Text>
        <Text>{invoice.id}</Text>
      </View>
      <View>
        <Text style={styles.title}>Kundennummer</Text>
        <Text>{invoice.companyId}</Text>
      </View>
      <View>
        <Text style={styles.title}>Datum</Text>
        <Text>{formatDate(invoice.createdAt)}</Text>
      </View>
    </View>
  );

export default InvoiceNumber;
