import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormProvider, FieldValues, useWatch } from 'react-hook-form';
//
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormTextField from 'components/hook-form/fields/FormTextField';
import useJobCreation from 'hooks/useJobCreation';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import CitySelection from 'components/base/CitySelection';
import TextEditor from 'components/base/TextEditor';
import styled from 'styled-components';
import { Wrapper } from 'components/Layout';
import FormFooter from './FormFooter';
import FormRadioSwitch from 'components/hook-form/fields/FormRadioSwitch';
import { createJobAction, updateJobAction } from 'domain/jobs/actions';
import { JobPayloadType } from 'types/job';
//

import { useAppDispatch } from 'domain/store';
import { useSelector } from 'react-redux';
import { jobsResourceSelector } from 'domain/jobs/selectors';
import { benefitsResourcesSelector } from 'domain/benefits/selectors';
import { IntlKeys } from 'localization';
import { seniorityOptions, workplaceOptions, workTypeOptions } from 'pages/createNewJob';
import { getBenefitsAction } from 'domain/benefits/actions';
import FormAutoCompleteMultiple from 'components/hook-form/fields/FormAutoCompleteMultiple';
import { BenefitPayloadType } from 'types/benefits';
import CreateBenefitField from './CreateBenefitField';
import { Chip } from '@mui/material';

import { formatCurrency } from 'helpers/number';

interface OwnProps {
  updateUrlQueryParam: (params: Record<string, string>) => void;
  updateStep: (step: number) => void;
  searchParams: URLSearchParams;
}

const Step1: React.FC<OwnProps> = ({ updateUrlQueryParam, updateStep, searchParams }) => {
  const jobDetails = useSelector(jobsResourceSelector);
  const benefits = useSelector(benefitsResourcesSelector);
  const [benefitsLoaded, setBenefitsLoaded] = useState(false);
  const [jobId, setJobId] = useState(searchParams.get('jobId'));
  const [newBenefits, setNewBenefits] = useState<BenefitPayloadType[]>([]);

  const { form } = useJobCreation({
    step: 'jobDetails',
    defaultValues: jobId
      ? { ...jobDetails, hideSalery: 'false', benefits: jobDetails?.benefits?.map((benefit) => benefit.id) || [] } || {}
      : { language: 'de-DE' },
  });
  const language = useWatch({ name: 'language', control: form.control });
  const minSalary = useWatch({ name: 'minSalary', control: form.control });
  const maxSalary = useWatch({ name: 'maxSalary', control: form.control });
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (jobId && jobDetails) {
      form.reset({ ...jobDetails, benefits: jobDetails?.benefits?.map((benefit) => benefit.id) || [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDetails]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!benefitsLoaded) {
      dispatch(
        getBenefitsAction({
          filters: { language },
          pagination: {
            perPage: 100,
            page: 1,
          },
        }),
      ).then(() => setBenefitsLoaded(true));
    }
  }, [language, benefitsLoaded, dispatch]);

  useEffect(() => {
    dispatch(
      getBenefitsAction({
        filters: { language },
        pagination: {
          perPage: 100,
          page: 1,
        },
      }),
    ).then(() => setBenefitsLoaded(true));
  }, [dispatch, language]);

  const onSubmit = useCallback(
    (data: FieldValues) => {
      const action = jobId
        ? updateJobAction({
            ...(data as JobPayloadType),
            newBenefits,
            params: {
              id: jobId,
            },
          })
        : createJobAction({ ...data, newBenefits } as JobPayloadType);
      dispatch(action)
        .unwrap()
        .then((response) => {
          if (jobId) {
            updateUrlQueryParam({ jobId, step: '2' });
            updateStep(2);
          }
          setJobId(response.id.toString());
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, jobId, updateStep, updateUrlQueryParam],
  );

  const showPreview = useCallback(() => {
    window.open(`/jobs/${jobId}/preview`, '_blank');
  }, [jobId]);

  const onBenefitSave = (data: BenefitPayloadType) => {
    setNewBenefits([...newBenefits, data]);
  };

  const handleBenefitDelet = (name: string) => {
    setNewBenefits(newBenefits.filter((bene) => bene.name !== name));
  };

  const benefitsOptions = benefits?.map((benefit) => {
    return {
      label: benefit.icon ? `${benefit.icon} ${benefit.name}` : benefit.name,
      value: benefit.id,
      id: benefit.id,
    };
  });

  const salaryOptions = [
    {
      label: formatMessage({ id: IntlKeys.hideSalaryFalse }),
      value: 'false',
    },
    {
      label: formatMessage({ id: IntlKeys.hideSalaryTrue }),
      value: 'true',
    },
  ];

  return (
    <>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <FormContainer>
            <EachInput
              label={
                <>
                  {formatMessage({ id: IntlKeys.jobName })} <span>*</span>
                </>
              }
            >
              <FormTextField
                name={'title'}
                placeholder={formatMessage({ id: IntlKeys.enterJobTitle })}
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
                required
              />
            </EachInput>

            <EachInput
              label={
                <>
                  {formatMessage({ id: IntlKeys.language })} <span>*</span>
                </>
              }
            >
              <FormRadioSwitch noBullet name="language" options={languagesOptions} />
            </EachInput>
            <EachInput
              label={
                <>
                  {formatMessage({ id: IntlKeys.workLocation })} <span>*</span>
                </>
              }
            >
              <FormRadioSwitch name="location" options={workplaceOptions} />
            </EachInput>
            <EachInput
              label={
                <>
                  {formatMessage({ id: IntlKeys.contractType })} <span>*</span>
                </>
              }
            >
              <FormRadioSwitch name="contractType" options={workTypeOptions} />
            </EachInput>
            <EachInput
              label={
                <>
                  {formatMessage({ id: IntlKeys.seniority })} <span>*</span>
                </>
              }
            >
              <FormAutoComplete
                freeSolo
                name="seniority"
                placeholder={formatMessage({ id: IntlKeys.selectSeniority })}
                options={seniorityOptions}
              />
            </EachInput>
            <EachInput
              label={
                <>
                  {formatMessage({ id: IntlKeys.minSalary })} <span>*</span>
                </>
              }
            >
              <SaleryRow>
                <FormTextField
                  name={'minSalary'}
                  type="number"
                  placeholder={formatMessage({ id: IntlKeys.minSalary })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
                <div>{formatCurrency(minSalary || 0)}</div>
              </SaleryRow>
            </EachInput>
            <EachInput
              label={
                <>
                  {formatMessage({ id: IntlKeys.maxSalary })} <span>*</span>
                </>
              }
            >
              <SaleryRow>
                <FormTextField
                  name={'maxSalary'}
                  type="number"
                  placeholder={formatMessage({ id: IntlKeys.maxSalary })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
                <div>{formatCurrency(maxSalary || 0)}</div>
              </SaleryRow>
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.hideSalary })}>
              <FormRadioSwitch name="hideSalery" options={salaryOptions} />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.selectCity })}>
              <CitySelection name="city" />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.tasks })}>
              <TextEditor name="tasks" initialValue={jobId ? jobDetails?.tasks : ''} />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.requirements })}>
              <TextEditor name="requirements" initialValue={jobId ? jobDetails?.requirements : ''} />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.benefits })}>
              <FormAutoCompleteMultiple
                freeSolo
                name="benefits"
                multiple
                placeholder={formatMessage({ id: IntlKeys.selectBenefits })}
                options={benefitsOptions}
                inputProps={{ placeholder: formatMessage({ id: IntlKeys.selectBenefits }) }}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.createBenefits })}>
              {newBenefits.length > 0 && (
                <BenefitRow>
                  {newBenefits.map((newBenefit) => (
                    <Chip
                      label={`${newBenefit.icon} ${newBenefit.name}`}
                      key={`newBenefit_${newBenefit.name}`}
                      onDelete={() => handleBenefitDelet(newBenefit.name)}
                    />
                  ))}
                </BenefitRow>
              )}
              <CreateBenefitField onSave={onBenefitSave} />
            </EachInput>
          </FormContainer>
          <FormFooter showPreview={showPreview} />
        </Form>
      </FormProvider>
    </>
  );
};

const languagesOptions = [
  {
    value: 'de-DE',
    label: '🇩🇪 Deutsch',
  },
  {
    value: 'en-US',
    label: '🇬🇧 English',
  },
];

export default Step1;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;
const BenefitRow = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0 2rem;
`;

const SaleryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  div {
    width: 100%;
    text-align: end;
  }
`;

const FormContainer = styled(Wrapper)`
  padding: 1rem;
  max-width: 800px;
`;
