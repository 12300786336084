import { yupResolver } from '@hookform/resolvers/yup';
import { FlexBox, SubmitButton } from 'components/base';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { Loader } from 'components/loader';
import { resetPasswordAction } from 'domain/auth/actions';
import { authMetaSelector, authUserSelector } from 'domain/auth/selectors';
import { useAppDispatch } from 'domain/store';
import { IntlKeys } from 'localization';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { ResetPasswordValidationSchema } from 'pages/auth/validation';

const AccountSettings = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const userDetails = useSelector(authUserSelector);
  const { isLoading: isUserLoading } = useSelector(authMetaSelector);
  const form = useForm({
    resolver: yupResolver(ResetPasswordValidationSchema),
    defaultValues: { email: userDetails?.email, password: '' } || {},
  });
  const [isSubitting, setIsSubitting] = useState(false);

  // const watchPassword = useWatch({
  //   name: 'password',
  //   control: form.control,
  // });

  useEffect(() => {
    if (userDetails) {
      form.reset({ email: userDetails?.email });
    }
  }, [userDetails]);

  const onSubmit = useCallback(async (data: FieldValues) => {
    setIsSubitting(true);

    dispatch(resetPasswordAction({ ...data, email: userDetails?.email }))
      .unwrap()
      .then(() => {
        toast.success(formatMessage({ id: IntlKeys.passwordUpdatedSuccessfully }));
        setIsSubitting(false);
      })
      .catch((err) => {
        toast.error(formatMessage({ id: IntlKeys.failedToUploadPassword }));
        setIsSubitting(false);
      });
  }, []);

  const languagesOptions = [
    {
      value: 'de-DE',
      label: '🇩🇪 Deutsch',
    },
    {
      value: 'en-US',
      label: '🇬🇧 English',
    },
  ];

  if (isUserLoading) {
    return <Loader />;
  }

  return (
    <SettingsContainer>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <FormContainer>
            <EachInput label={formatMessage({ id: IntlKeys.email })}>
              <FormTextField
                name={'email'}
                placeholder={formatMessage({ id: IntlKeys.enterEmail })}
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
                disabled
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.oldPassword })}>
              <FormTextField
                type="password"
                name={'oldPassword'}
                placeholder={formatMessage({ id: IntlKeys.enterOldPassword })}
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.newPassword })}>
              <FormTextField
                type="password"
                name={'password'}
                placeholder={formatMessage({ id: IntlKeys.enterNewPassword })}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.confirmPassword })}>
              <FormTextField
                type="password"
                name={'passwordConfirmation'}
                placeholder={formatMessage({ id: IntlKeys.enterConfirmPassword })}
              />
            </EachInput>
          </FormContainer>
          <Footer>
            <ButtonContainer align="center" justify={'flex-end'}>
              <SubmitButton type="submit" isLoading={isSubitting} disabled={isSubitting}>
                {formatMessage({ id: IntlKeys.save })}
              </SubmitButton>
            </ButtonContainer>
          </Footer>
        </Form>
      </FormProvider>
    </SettingsContainer>
  );
};

export default AccountSettings;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

const SettingsContainer = styled.div``;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 64px;
  border-top: 1px solid ${theme.colors.stroke};
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${theme.colors.white};
`;

const ButtonContainer = styled(FlexBox)`
  max-width: 800px;
  margin: auto;
  align-items: center;
  padding: 0rem 1rem;
  height: 100%;

  > button {
    padding: 0.5rem 1.5rem;
  }
`;

const FormContainer = styled.div`
  max-width: 800px;
  padding-bottom: 64px;
`;
