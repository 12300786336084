import { FlexBox, SubmitButton, TextEditor, Title2 } from 'components/base';
import { getNumberToCharacter } from 'helpers/string';
import { IntlKeys } from 'localization';
import React, { useState } from 'react';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { QualificationQuestionsType } from 'types/job';
import { useAppDispatch } from 'domain/store';
import { submitAnswerAction } from 'domain/applications/actions';
import { toast } from 'react-toastify';
import { HiHandThumbUp } from 'react-icons/hi2';

interface OwnProps {
  questions: QualificationQuestionsType[];
  applicationId: number;
}

const QualificationQuestions: React.FC<OwnProps> = ({ questions, applicationId }) => {
  const { formatMessage } = useIntl();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const getCountLabel = () => {
    const count = questions.length - currentQuestion;
    return count > 3 ? 'a few' : count;
  };

  const onTextAnswerSubmit = (data: FieldValues, index: number) => {
    if (!data.answer) {
      toast.error(formatMessage({ id: IntlKeys.questionNoAnswer }));
      return;
    }
    setIsSubmitting(true);
    const answerObject = {
      answer: data.answer,
      questionId: data.questioId,
    };
    dispatch(submitAnswerAction({ params: { id: applicationId }, ...answerObject }))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        setCurrentQuestion(index + 1);
      })
      .catch(() => {
        toast.error('Something went wrong! Please try again');
        setIsSubmitting(false);
      });
  };

  const submitAnswer = (selectedAnswer: number | null, question: QualificationQuestionsType, index: number) => {
    if (selectedAnswer === null) {
      toast.error(formatMessage({ id: IntlKeys.questionNoAnswer }));
      return;
    }
    setIsSubmitting(true);
    const answerObject = {
      answer: [selectedAnswer],
      questionId: question.id,
    };
    dispatch(submitAnswerAction({ params: { id: applicationId }, ...answerObject }))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        setCurrentQuestion(index + 1);
      })
      .catch(() => {
        toast.error('Something went wrong! Please try again');
        setIsSubmitting(false);
      });
  };

  const Answers = ({ question, index }: { question: QualificationQuestionsType; index: number }) => {
    const [selectedAnswer, setSelctedAnswer] = useState<number | null>(null);
    const form = useForm({ defaultValues: { questioId: question.id, answer: '' } });
    const textAnswer = useWatch({ name: 'answer', control: form.control });

    const { type, choices } = question;
    switch (type) {
      case 'short_answer':
        return (
          <AnswersContainer>
            <FormProvider {...form}>
              <Form onSubmit={form.handleSubmit((data: FieldValues) => onTextAnswerSubmit(data, index))}>
                <EditorContainer>
                  <TextEditor name={`answer`} bgColor={theme.colors.white} minHeight={'100px'} />
                </EditorContainer>
                <SubmitButton
                  type="submit"
                  className="submit-button"
                  isLoading={isSubmitting}
                  disabled={isSubmitting || !textAnswer}
                >
                  Submit
                </SubmitButton>
              </Form>
            </FormProvider>
          </AnswersContainer>
        );
      case 'choice':
        return (
          <AnswersContainer>
            <ChoiceOptions>
              {choices?.map((option, index) => (
                <OptionWrapper key={option} align="center">
                  <Option selected={selectedAnswer === index} onClick={() => setSelctedAnswer(index)}>
                    <OptionLabel selected={selectedAnswer === index}>{getNumberToCharacter(index + 1)}</OptionLabel>
                    <OptionText>{option}</OptionText>
                  </Option>
                </OptionWrapper>
              ))}
            </ChoiceOptions>
            <SubmitButton
              className="submit-button"
              onClick={() => submitAnswer(selectedAnswer, question, index)}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Submit
            </SubmitButton>
          </AnswersContainer>
        );
      case 'yes_no':
        return (
          <AnswersContainer>
            <YesOrNoWrapper>
              <Option onClick={() => setSelctedAnswer(0)} selected={selectedAnswer === 0}>
                <OptionLabel selected={selectedAnswer === 0}>A</OptionLabel>
                {formatMessage({ id: IntlKeys.yes })}
              </Option>
              <Option onClick={() => setSelctedAnswer(1)} selected={selectedAnswer === 1}>
                <OptionLabel selected={selectedAnswer === 1}>B</OptionLabel>
                {formatMessage({ id: IntlKeys.no })}
              </Option>
            </YesOrNoWrapper>
            <SubmitButton
              className="submit-button"
              onClick={() => submitAnswer(selectedAnswer, question, index)}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Submit
            </SubmitButton>
          </AnswersContainer>
        );
    }
  };

  return (
    <Container>
      {currentQuestion < questions?.length ? (
        <>
          <Heading>{formatMessage({ id: IntlKeys.questionHeading }, { count: getCountLabel() })}</Heading>
          <QuestionsSection>
            {questions.map((question, index) => (
              <>
                {currentQuestion === index ? (
                  <QuestionContainer key={question.id}>
                    <Question>{question.question}</Question>
                    <Answers question={question} index={index} />
                  </QuestionContainer>
                ) : null}
              </>
            ))}
            <Dots>
              {questions.map((question, index) => (
                <Bullet key={question.id} active={currentQuestion === index} />
              ))}
            </Dots>
          </QuestionsSection>
        </>
      ) : (
        <ThankYouSection>
          <HiHandThumbUp />
          <Title2>{formatMessage({ id: IntlKeys.allSet })}</Title2>
          <span>{formatMessage({ id: IntlKeys.weWillGetBack })}</span>
        </ThankYouSection>
      )}
    </Container>
  );
};

export default QualificationQuestions;

interface OptionProps {
  selected?: boolean;
}

const Container = styled.div`
  width: 100%;
  padding: 1rem 0;
  overflow: auto;
`;

const QuestionContainer = styled.div`
  padding: 2rem 0rem;
`;

const Question = styled.span`
  font-size: 1.25rem;
  font-weight: 530;
`;

const Form = styled.form`
  width: 100%;
`;

const ChoiceOptions = styled.div``;

const OptionWrapper = styled(FlexBox)`
  margin: 1rem 0rem;
`;

const Option = styled(FlexBox)`
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: ${theme.colors.white};
  border-radius: 4px;
  align-items: center;
  flex: 1;
  cursor: pointer;

  ${(props: OptionProps) =>
    props.selected &&
    css`
      border: 1px solid ${theme.colors.primary};
    `}
`;

const OptionLabel = styled.div`
  font-weight: 600;
  color: ${theme.colors.secondary};
  background: ${theme.colors.lightGray};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border-radius: 4px;

  ${(props: OptionProps) =>
    props.selected &&
    css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    `}
`;

const OptionText = styled.span``;

const AnswersContainer = styled.div``;

const Heading = styled.span`
  font-size: 1rem;
  text-align: center;
  display: block;
`;

const YesOrNoWrapper = styled(FlexBox)`
  margin: 2rem 0;
  gap: 1rem;
`;

const EditorContainer = styled.div`
  margin-top: 1rem;
`;

const QuestionsSection = styled.div`
  button.submit-button {
    width: 100%;
    margin-top: 1rem;
  }
`;

const Dots = styled(FlexBox)`
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

const Bullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props: { active?: boolean }) => (props.active ? theme.colors.primary : theme.colors.gray)};
  transform: ${(props: { active?: boolean }) => (props.active ? 'scale(1.1)' : 'none')}; ;
`;

const ThankYouSection = styled(FlexBox)`
  min-height: 200px;
  align-items: center;
  flex-direction: column;

  svg {
    font-size: 3rem;
    margin-bottom: 1rem;
    fill: ${theme.colors.primary};
  }

  span {
    font-size: 1.125rem;
    text-align: center;
    margin-top: 1.5rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;
