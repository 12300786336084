import { Wrapper } from 'components/Layout';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import Navigation, { settingsNavigation } from './components/Navigation';

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = location.pathname.split('?')[0];
    const isCurrentUrlInclded = Object.values(settingsNavigation).includes(currentUrl as settingsNavigation);
    if (!isCurrentUrlInclded) {
      navigate(settingsNavigation.profileSettings);
    }
  }, [location]);

  return (
    <Wrapper>
      <Navigation />
      <Outlet />
    </Wrapper>
  );
};

export default Settings;
