import { uploadFilePublic } from 'helpers/uploadFile';
import { FileType } from 'types/common';

export interface MediaUploadedFileObj {
  fileObj: File;
  src: string;
  name: string;
}

export const uploadFiles = async (jobId: string, token: string, files: MediaUploadedFileObj[]) => {
  //
  return await Promise.all(
    (files || []).map(async ({ fileObj }) => {
      return fileObj
        ? new Promise<FileType>(async (resolve) => {
            const formData = new FormData();
            formData.append('file', fileObj, fileObj.name);
            formData.append('jobId', jobId);
            formData.append('token', token);
            const response = (await uploadFilePublic(formData)) as FileType;

            resolve(response);
          })
        : undefined;
    }),
  );
};
