import { Card, FlexBox } from 'components/base';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { IntlKeys } from 'localization';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { JobType } from 'types/job';

interface OwnProps {
  maxCandidates: number;
  job: JobType | null;
  creditPerCandidate: {
    seniorityFactor: number;
    rareFactor: number;
    priorityFactor: number;
  };
  creditsNeeded: {
    total: number;
    toBePurchased: number;
  };
}

const CalculationSection: React.FC<OwnProps> = ({ maxCandidates, creditsNeeded }) => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <EachInput label={formatMessage({ id: IntlKeys.maximumCandidateNeeded })}>
        <FormTextField
          name={'maxCandidates'}
          type="number"
          placeholder={formatMessage({ id: IntlKeys.enterMaxCandidateNeeded })}
          rules={{
            required: formatMessage({ id: IntlKeys.requiredItem }),
            min: formatMessage({ id: IntlKeys.minCandidateRequired }, { count: 5 }),
          }}
          InputProps={{ inputProps: { min: 5, type: 'number' } }}
        />
      </EachInput>

      <Calculation>
        <EachRow>
          <Label>{formatMessage({ id: IntlKeys.talentsRequested })}</Label>
          <Value>{maxCandidates}</Value>
        </EachRow>
        <EachRow>
          <Label>{formatMessage({ id: IntlKeys.talentsBaseCredits })}</Label>
          <Value>{1}</Value>
        </EachRow>
        <EachRow>
          <Label>{formatMessage({ id: IntlKeys.talentsCreditsNeeded })}</Label>
          <Value className="totalValue">
            {formatMessage({ id: IntlKeys.talentsTotalCredits }, { creditTotal: creditsNeeded.total })}
          </Value>
        </EachRow>
      </Calculation>
    </Container>
  );
};

export default CalculationSection;

const Container = styled(Card)`
  background-color: ${theme.colors.mintGreen};
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

const Calculation = styled.div`
  margin-top: 1rem;
  border-top: 1px solid ${theme.colors.gray};
`;

const EachRow = styled(FlexBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem;
`;

const Label = styled.span``;

const Value = styled.span`
  font-weight: 530;
  &.totalValue {
    border-top: 1px solid ${theme.colors.gray};
    width: 100px;
    display: flex;
    justify-content: end;
    padding-top: 1rem;
  }
`;
