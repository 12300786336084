import { IntlKeys } from 'localization';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import { theme } from 'theme';

export enum companyNavigation {
  companySettings = '/organization/settings',
  companyUsers = '/organization/users',
  customization = '/organization/customization',
  subscriptions = '/organization/subscriptions',
}

type NavigationTab = {
  label: string;
  id: keyof typeof companyNavigation;
  onClick: () => void;
};

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname.split('?')[0];
  const [selectedTab, setSelectedTab] = useState(currentUrl);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const currentUrl = location.pathname.split('?')[0];
    if (currentUrl !== selectedTab) {
      setSelectedTab(currentUrl);
    }
  }, [location, selectedTab]);

  const navigationTabs: NavigationTab[] = [
    {
      label: formatMessage({ id: IntlKeys.companySettings }),
      id: 'companySettings',
      onClick: () => {
        navigate(companyNavigation.companySettings);
      },
    },
    {
      label: formatMessage({ id: IntlKeys.companySubscriptions }),
      id: 'subscriptions',
      onClick: () => {
        navigate(companyNavigation.subscriptions);
      },
    },
    {
      label: formatMessage({ id: IntlKeys.companyUsers }),
      id: 'companyUsers',
      onClick: () => {
        navigate(companyNavigation.companyUsers);
      },
    },
    {
      label: formatMessage({ id: IntlKeys.companyCustomization }),
      id: 'customization',
      onClick: () => {
        navigate(companyNavigation.customization);
      },
    },
  ];

  return (
    <NavigationContainer>
      {navigationTabs.map((navigation) => (
        <EachNavigation
          key={navigation.id}
          onClick={navigation.onClick}
          active={selectedTab == companyNavigation[navigation.id]}
        >
          {navigation.label}
        </EachNavigation>
      ))}
    </NavigationContainer>
  );
};
export default Navigation;

const NavigationContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  gap: 4px;
  overflow: auto;
`;

export interface ButtonProps {
  active?: boolean;
}

const EachNavigation = styled.button`
  background-color: ${theme.colors.lightGray};
  border: none;
  color: ${theme.colors.dark};
  font-family: ${theme.fonts.montreal};
  font-size: 14px;
  line-height: 20px;
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;

  ${(props: ButtonProps) =>
    props.active &&
    css`
      border-radius: 20px;
      background: ${theme.colors.mintGreen};
      color: ${theme.colors};
    `}
`;
