import { Button, Container } from '@mui/material';
import { IntlKeys } from 'localization';
import React from 'react';
import { MdPictureAsPdf } from 'react-icons/md';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ApplicationFile } from 'types/application';

interface OwnProps {
  files: ApplicationFile[];
}

const ApplicationFiles: React.FC<OwnProps> = ({ files }) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <FilesSection>
        <h3>{formatMessage({ id: IntlKeys.additionalFiles })}</h3>
        <CardSection>
          {files.map((file, index) => (
            <Button
              href={file.url}
              target="_blank"
              key={`file-${index}`}
              startIcon={<MdPictureAsPdf />}
            >{`File - ${index}`}</Button>
          ))}
        </CardSection>
      </FilesSection>
    </Container>
  );
};
export default ApplicationFiles;

const FilesSection = styled.div``;
const CardSection = styled.div``;
