import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import logger from 'redux-logger';
// reducers
import { createRootReducer } from './createRootReducer';

export const createStore = () => {
  const rootReducer = createRootReducer();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    devTools: process.env.NODE_ENV === 'development',
    enhancers: [],
  });

  //
  return store;
};

export const store = createStore();
export const storeSelector = (state: RootState) => state;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
