import FormTextField from 'components/hook-form/fields/FormTextField';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
//
import { theme } from 'theme';
import { JobStatus } from 'types/job';
import { JobFiltersFormValues } from 'types/job/entities';
import { useIsMobile } from 'hooks/useIsMobile';
import { useIntl } from 'react-intl';
import DropdownMenu from 'components/base/DropdownMenu';
import { FiFilter } from 'react-icons/fi';
import { IntlKeys } from 'localization';

interface OwnProps {
  onChange: (config: JobFiltersFormValues) => void;
  config: JobFiltersFormValues;
}

type JobFilter = {
  label: string;
  value: JobStatus | string;
};

const JobsFilter: React.FC<OwnProps> = ({ onChange, config }) => {
  const [selectedStatus, setSelectedStatus] = useState(config.filters.status || '');
  const form = useForm({ defaultValues: { search: config.filters.search } });
  const searchValue = useWatch({ name: 'search', control: form.control });
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { formatMessage } = useIntl();

  const jobFilters: JobFilter[] = [
    {
      label: formatMessage({ id: IntlKeys.jobsAll }),
      value: '',
    },
    {
      label: formatMessage({ id: IntlKeys.jobsActive }),
      value: 'published',
    },
    {
      label: formatMessage({ id: IntlKeys.jobsDraft }),
      value: 'draft',
    },
    {
      label: formatMessage({ id: IntlKeys.jobsArchived }),
      value: 'archived',
    },
    {
      label: formatMessage({ id: IntlKeys.jobsExpired }),
      value: 'recruting-done',
    },
  ];

  useEffect(() => {
    if (searchValue?.length >= 3 || searchValue?.length == 0) {
      config.filters.search = searchValue;
      onChange(config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, searchValue]);

  const handleStatusChange = (status: JobStatus | string) => {
    const newStatus = status == selectedStatus ? '' : status;
    config.filters.status = newStatus;
    onChange(config);
    setSelectedStatus(newStatus);
    handleMenuClose();
  };

  useEffect(() => {
    if (config.filters.status !== selectedStatus) {
      setSelectedStatus(config.filters.status);
    }
  }, [config.filters.status, selectedStatus]);

  const menuActions = jobFilters
    .filter((status) => status.value !== selectedStatus)
    .map(({ label, value }) => ({
      label,
      onClick: () => handleStatusChange(value),
    }));

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuTrigger = (
    <MenuTrigger onClick={handleMenuClick}>
      <FiFilter /> <span>{jobFilters.find(({ value }) => value === selectedStatus)?.label}</span>
    </MenuTrigger>
  );

  return (
    <FormProvider {...form}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FilterContainer>
          <SearchBox>
            <FormTextField name="search" placeholder={formatMessage({ id: IntlKeys.searchJob })} />
          </SearchBox>
          {isMobile && (
            <DropdownMenu
              trigger={menuTrigger}
              anchorEl={anchorEl}
              handleClose={handleMenuClose}
              actions={menuActions}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{ marginRight: '2rem' }}
            />
          )}
          {!isMobile && (
            <StatusFilters>
              {jobFilters.map((filter) => (
                <EachFilter
                  key={filter.value}
                  onClick={() => handleStatusChange(filter.value)}
                  active={selectedStatus == filter.value}
                >
                  {filter.label}
                </EachFilter>
              ))}
            </StatusFilters>
          )}
        </FilterContainer>
      </Form>
    </FormProvider>
  );
};

export default JobsFilter;
const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  font-family: ${theme.fonts.montreal};
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 400px;
  font-family: ${theme.fonts.montreal};
  input {
    height: 42px;
    font-family: ${theme.fonts.montreal};
    box-sizing: border-box;
  }
`;

const StatusFilters = styled.div`
  display: flex;
  gap: 4px;
`;

export interface ButtonProps {
  active?: boolean;
}

const EachFilter = styled.button`
  background-color: ${theme.colors.lightGray};
  border: none;
  color: ${theme.colors.dark};
  font-family: ${theme.fonts.montreal};
  font-size: 14px;
  line-height: 20px;
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  height: 42px;

  ${(props: ButtonProps) =>
    props.active &&
    css`
      border-radius: 20px;
      background: ${theme.colors.mintGreen};
      color: ${theme.colors.primary};
    `}
`;

const Form = styled.form`
  width: 100%;
`;

const MenuTrigger = styled.div`
  width: 76px;
  padding: 0.5rem 0.25rem;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.stroke};
  border-radius: 5px;
  cursor: pointer;
  svg {
    font-size: 1rem;
    height: 1.5rem;
    min-width: 1.2rem;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
  }
`;
