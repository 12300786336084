export const omitNullable = <T extends unknown | string>(object: Record<string, T> = {}) => {
  return Object.entries(object).reduce((acc, [key, val]) => {
    if (val !== '' && val != null) {
      acc[key] = val;
    }

    return acc;
  }, {} as Record<string, T>);
};

export const getUniqueListBy = (arr: Record<string, unknown>[], key: string) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};
