import { Switch, Tooltip } from '@mui/material';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { FlexBox, Text } from 'components/base';
import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { IntlKeys } from 'localization';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { getNumberToCharacter } from 'helpers/string';
import { GrAdd, GrTrash } from 'react-icons/gr';
import { QualificationQuestionsPayload } from 'types/job';

interface DisqualifyOptionProps {
  id: number;
  option: number | null;
  onUpdate: (id: number, isTrue: boolean) => void;
  value?: string | number;
}

interface YesOrNoProps {
  id?: number;
  initialValue?: QualificationQuestionsPayload;
}

export const YesOrNoOptions: React.FC<YesOrNoProps> = ({ id: questionId }) => {
  const { formatMessage } = useIntl();
  //
  const { control, getValues } = useFormContext();

  const fieldName = `question.${questionId}.disqualifying`;

  const fieldValue = useWatch<Record<string, string[] | number[]>>({
    name: fieldName,
    // useWatch return defaultValue instead current value after remount
    // see https://github.com/react-hook-form/react-hook-form/issues/2739
    defaultValue: getValues(fieldName),
  });

  const {
    field: { value, onChange },
  } = useController({
    name: fieldName,
    control,
  });
  //
  const [disqualifyingAnswer, setDisqualifyingAnswer] = useState<number | null>(value?.[0] || null);

  const updateDisqualifyingOption = useCallback(
    (id: number, isTrue: boolean) => {
      setDisqualifyingAnswer(isTrue ? id : null);
      onChange(isTrue ? [id] : null);
    },
    [setDisqualifyingAnswer, onChange],
  );

  return (
    <>
      <OptionWrapper align="center">
        <Option>
          <OptionLabel>A</OptionLabel>
          {formatMessage({ id: IntlKeys.yes })}
        </Option>
        <DisqualifyOption
          id={0}
          option={disqualifyingAnswer}
          onUpdate={updateDisqualifyingOption}
          value={fieldValue?.[0]}
        />
      </OptionWrapper>
      <OptionWrapper align="center">
        <Option>
          <OptionLabel>B</OptionLabel>
          {formatMessage({ id: IntlKeys.no })}
        </Option>
        <DisqualifyOption
          id={1}
          option={disqualifyingAnswer}
          onUpdate={updateDisqualifyingOption}
          value={fieldValue?.[0]}
        />
      </OptionWrapper>
    </>
  );
};

interface MultipleChoiceProps {
  id?: number;
  initialValue?: QualificationQuestionsPayload;
}

export const MultipleChoice: React.FC<MultipleChoiceProps> = ({ id: questionId, initialValue }) => {
  const inititalOptions = [
    {
      id: 0,
    },
    {
      id: 1,
    },
  ];

  const { formatMessage } = useIntl();
  const [options, setOptions] = useState(inititalOptions);
  //
  const { control, getValues } = useFormContext();
  const fieldName = `question.${questionId}.disqualifying`;

  const fieldValue = useWatch<Record<string, string[] | number[]>>({
    name: fieldName,
    // useWatch return defaultValue instead current value after remount
    // see https://github.com/react-hook-form/react-hook-form/issues/2739
    defaultValue: getValues(fieldName) || false,
  });

  const {
    field: { value, onChange },
  } = useController({
    name: fieldName,
    control,
  });
  //
  const [disqualifyingAnswer, setDisqualifyingAnswer] = useState<number | null>(value?.[0] || null);

  useEffect(() => {
    if (initialValue?.choices?.length) {
      const defaultOptions = initialValue?.choices?.map((_, index) => ({ id: index }));
      setOptions(defaultOptions);
    }
  }, [initialValue]);

  const updateDisqualifyingOption = useCallback(
    (id: number, isTrue: boolean) => {
      setDisqualifyingAnswer(isTrue ? id : null);
      onChange(isTrue ? [id] : null);
    },
    [setDisqualifyingAnswer, onChange],
  );

  const addNewOption = useCallback(() => {
    setOptions((options) => [...options, { id: options[options.length - 1].id + 1 }]);
  }, [setOptions]);

  const removeOption = useCallback(
    (id: number) => {
      const newOptions = options.filter((options) => options.id !== id);

      setOptions(newOptions);
    },
    [setOptions, options],
  );

  return (
    <>
      <OptionContainer>
        {options.map((option) => (
          <OptionWrapper key={option.id} align="center">
            <Option>
              <OptionLabel>{getNumberToCharacter(option.id + 1)}</OptionLabel>
              <FormTextField name={`question.${questionId}.choices.${option.id}`} required />
            </Option>
            <DisqualifyOption
              id={option.id}
              option={disqualifyingAnswer}
              onUpdate={updateDisqualifyingOption}
              value={fieldValue?.[0]}
            />
            <Tooltip title="Remove option">
              <Delete onClick={() => removeOption(option.id)}>
                <GrTrash />
              </Delete>
            </Tooltip>
          </OptionWrapper>
        ))}
        <AddNewOption align="center" gap={'.5rem'} onClick={addNewOption}>
          <GrAdd /> <Text>{formatMessage({ id: IntlKeys.addNewOption })}</Text>
        </AddNewOption>
      </OptionContainer>
    </>
  );
};

const DisqualifyOption: React.FC<DisqualifyOptionProps> = ({ id, option, onUpdate, value }) => {
  const { formatMessage } = useIntl();

  if (option !== null && option !== undefined && id !== option) return null;
  return (
    <FlexBox align="center">
      <Tooltip title={formatMessage({ id: IntlKeys.disqualifyTooltip })}>
        <Switch
          checked={value === id}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onUpdate(id, e.target?.checked)}
        />
      </Tooltip>
      <Text>{formatMessage({ id: IntlKeys.disqualify })}</Text>
    </FlexBox>
  );
};

const OptionWrapper = styled(FlexBox)`
  margin-top: 1rem;
`;

const Option = styled(FlexBox)`
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: ${theme.colors.lightGray};
  border-radius: 4px;
  align-items: center;
  flex: 1;
`;

const OptionLabel = styled.div`
  font-weight: 600;
  color: ${theme.colors.secondary};
  background: ${theme.colors.white};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border-radius: 4px;
`;

const OptionContainer = styled.div``;

const AddNewOption = styled(FlexBox)`
  padding: 0.5rem 0rem;
  margin-top: 0.5rem;
  cursor: pointer;
`;

const Delete = styled.div`
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;

  > svg {
    path {
      stroke: ${theme.colors.gray};
    }
  }

  &:hover {
    background-color: ${theme.colors.lightGray};
    border-radius: 40px;
    transition: all linear 0.25s;
  }
`;
