import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { InputAdornment, IconButton } from '@mui/material';
// utils
import { IntlKeys } from 'localization/keys';
import { extractIntlKey } from 'localization/keys';
import usePasswordVisibility from 'hooks/usePasswordVisibility';
// components
import FormTextField from 'components/hook-form/fields/FormTextField';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import { Card, ErrorMessage, FlexBox, SubmitButton } from 'components/base';
// local
import useAuthPage from '../hooks/useAuthPage';
import styled from 'styled-components';
// styles
import logo from 'assets/images/tandf-logo.svg';
import { theme } from 'theme';
import { Loader } from 'components/loader';

const LoginPage = () => {
  const { formatMessage } = useIntl();
  //
  const { form, onSubmit, isLoading, errorMessageId } = useAuthPage({ authPage: 'signIn' });
  const { inputType, VisibilityIcon, onToggleShowPassword } = usePasswordVisibility();

  return (
    <Container align="center" justify="center">
      <Card withBorder width={'400px'}>
        <img src={logo} />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <h2>{formatMessage({ id: IntlKeys.signIn })}</h2>

            <LoginInputWrapper label={formatMessage({ id: IntlKeys.email })}>
              <FormTextField name="email" placeholder="name@mail.com" />
            </LoginInputWrapper>

            <LoginInputWrapper label={formatMessage({ id: IntlKeys.password })}>
              <FormTextField
                name="password"
                type={inputType}
                placeholder={formatMessage({ id: IntlKeys.enterPassword })}
                InputProps={{
                  endAdornment: (
                    <TriggerEye position="end">
                      <IconButton aria-label="toggle password visibility" onClick={onToggleShowPassword}>
                        {<VisibilityIcon />}
                      </IconButton>
                    </TriggerEye>
                  ),
                }}
              />

              <NavLink to={'/forgot-password'}>{formatMessage({ id: IntlKeys.forgotPasswordQuestion })}</NavLink>
            </LoginInputWrapper>

            <LoginButton type="submit" disabled={isLoading}>
              {isLoading ? <Loader /> : formatMessage({ id: IntlKeys.signIn })}
            </LoginButton>

            {errorMessageId && <ErrorMessage errorMessage={formatMessage({ id: extractIntlKey(errorMessageId) })} />}
          </form>
        </FormProvider>
      </Card>
    </Container>
  );
};

export default LoginPage;

const Container = styled(FlexBox)`
  height: 100vh;
  width: 100vw;
  background-color: ${theme.colors.mintGreen};

  img {
    margin: 1.5rem 0 0.5rem;
  }

  h2 {
    margin: 0.5rem 0 1rem;
    font-weight: 400;
    font-size: 1.25rem;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.primary};
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid ${theme.colors.primary};
    }
  }
`;

const LoginInputWrapper = styled(InputWrapper)`
  font-size: 0.85rem;
  :not(:last-child) {
    margin-bottom: 1rem;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 0;
  }

  input {
    margin-bottom: 2px;
  }
`;

const LoginButton = styled(SubmitButton)`
  width: 100%;
  margin: 1rem 0;
`;

const TriggerEye = styled(InputAdornment)`
  position: absolute;
  right: 0;
  padding: 0;
  margin: 0;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
