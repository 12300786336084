import React from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import clsx from 'clsx';
// assets
import { ReactComponent as ErrorValidationIcon } from 'helpers/forms/assets/validation-error-icon.svg';
import FlexBox from './FlexBox';

type OwnProps = {
  errorMessage?: string;
  classes?: Partial<Record<'errorWrapper' | 'errorMessage', string>>;
};

const ErrorMessage = ({ errorMessage, classes }: OwnProps) => {
  return (
    <>
      {errorMessage && (
        <ErrorWrapper className={clsx(classes?.errorWrapper)}>
          <ErrorValidationIcon />
          <MessageSpan className={clsx(classes?.errorMessage)}>{errorMessage}</MessageSpan>
        </ErrorWrapper>
      )}
    </>
  );
};

const ErrorWrapper = styled(FlexBox)`
  column-gap: 8px;

  & svg {
    min-width: 20px;
  }

  & path {
    fill: ${theme.colors.error};
  }
`;

const MessageSpan = styled.span`
  padding-top: 2px;
  color: ${theme.colors.error};
`;

export default ErrorMessage;
