//
export const IntlKeys = {
  //COMMON
  here: 'common.here',
  image: 'common.image',
  video: 'common.video',
  loading: 'common.loading',
  helloWorld: 'common.helloWorld',
  email: 'common.email',
  password: 'common.password',
  enterPassword: 'common.enterPassword',
  passwordPlaceholder: 'common.passwordPlaceholder',
  retypePassword: 'common.retypePassword',
  newPassword: 'common.newPassword',
  retypeNewPassword: 'common.retypeNewPassword',
  confirm: 'common.confirm',
  cancel: 'common.cancel',
  name: 'common.name',
  namePlaceholder: 'common.namePlaceholder',
  surname: 'common.surname',
  surnamePlaceholder: 'common.surnamePlaceholder',
  jobTitle: 'common.jobTitle',
  jobTitlePlaceholder: 'common.jobTitlePlaceholder',
  yes: 'common.yes',
  no: 'common.no',
  profile: 'common.profile',
  organization: 'common.organization',
  logout: 'common.logout',
  settings: 'common.settings',
  apply: 'common.apply',
  applySend: 'common.applySend',
  applyfiles: 'common.applyfiles',
  additionalFiles: 'common.additionalFiles',
  interestedIn: 'common.interestedIn',
  firstName: 'common.firstName',
  lastName: 'common.lastName',
  phoneNumber: 'common.phoneNumber',
  enterFirstName: 'common.enterFirstName',
  enterLastName: 'common.enterLastName',
  enterEmail: 'common.enterEmail',
  enterPhoneNumber: 'common.enterPhoneNumber',
  foundingDate: 'common.foundingDate',
  addressStreet: 'common.address.street',
  addressHouseNumber: 'common.address.number',
  addressCity: 'common.address.city',
  addressPostcode: 'common.address.postcode',
  addressCountry: 'common.address.country',
  dragAndDropOrChoose: 'common.dragAndDropOrChoose',
  undo: 'common.undo',
  tryAgainMessage: 'common.tryAgainMessage',
  save: 'common.save',
  edit: 'common.edit',
  delete: 'common.delete',
  draft: 'common.draft',
  add: 'common.add',
  hiddenSalary: 'common.hiddenSalary',
  hideSalary: 'common.hideSalary',
  hideSalaryTrue: 'common.hideSalaryTrue',
  hideSalaryFalse: 'common.hideSalaryFalse',

  //LOGIN PAGE
  loginTitle: 'login.title',
  loginSubtitle: 'login.subtitle',
  loginNoAccount: 'login.noAccount',
  signIn: 'login.signIn',
  signInError401: 'login.signIn.error.401',
  signInError404: 'login.signIn.error.404',

  //FORGOT PASSWORD PAGE
  forgotPassword: 'forgotPassword.common',
  forgotPasswordQuestion: 'forgotPassword.question',
  forgotPasswordEnterEmail: 'forgotPassword.enterEmail',
  forgotPasswordSendLink: 'forgotPassword.sendLink',
  forgotPasswordBackToLogin: 'forgotPassword.backToLogin',
  forgotPasswordFulfilled: 'forgotPassword.fulfilled',
  forgotPasswordError404: 'forgotPassword.error.404',

  //RESET PASSWORD PAGE
  registerHead: 'registerHead.common',
  resetPassword: 'resetPassword.common',
  resetPasswordSubtitle: 'resetPassword.subTitle',
  resetPasswordChangePassword: 'resetPassword.changePassword',
  resetPasswordHaveAccount: 'resetPassword.haveAccount',
  resetPasswordError404: 'resetPassword.fulfilled',
  resetPasswordFulfilled: 'resetPassword.error.404',
  resetPasswordError422: 'resetPassword.error.422',

  //SIGN UP PAGE
  signUp: 'signUp.common',
  signUpTitle: 'signUp.title',
  signUpSubTitle: 'signUp.subTitle',
  signUpHaveAccount: 'signUp.haveAccount',
  signUpFulfilled: 'signUp.fulfilled',
  signUpError404: 'signUp.error.404',
  signUpError422: 'signUp.error.422',
  signUpError500: 'signUp.error.500',

  // Form validations
  formFieldFequired: 'form.field.required',

  // Auth validations
  incorrectEmail: 'incorrect.email',
  requiredItem: 'required.item',
  eightCharacterAtLeast: 'character.count.eight',
  settingsPasswordMaxCharacter: 'setting.password.max.character',
  enterValidString: 'enter.valid.string',
  passwordsMustMatch: 'passwords.mismatch',

  // Talents
  talents: 'talents',
  talentsAll: 'talents.all',
  talentsReview: 'talents.review',
  talentsAccepted: 'talents.accepted',
  talentsRejected: 'talents.rejected',
  talentsView: 'talents.view',
  talentsDownload: 'talents.download',
  talentsMarkAsMatch: 'talents.markAsMatch',
  talentsMarkAsNoMatch: 'talents.markAsNoMatch',
  talentsZero: 'talents.no',
  talentsProvided: 'talents.provided',
  talentsRequested: 'talents.requested',
  talentsBaseCredits: 'talents.baseCredits',
  talentsCreditsNeeded: 'talents.creditsNeeded',
  talentsTotalCredits: 'talents.totalCredits',
  talentsMinRequired: 'talents.minRequired',

  // jobs
  jobs: 'jobs',
  jobsAbortQuestion: 'jobs.abortQuestion',
  jobsAbortDescription: 'jobs.abortDescription',
  jobsExpressRecruiting: 'jobs.expressRecruiting',
  jobsFilter: 'jobs.filter',
  jobApplied: 'job.applied',
  jobRemarks: 'job.remarks',
  remarksComesHere: 'job.remarksComesHere',
  notAnsweredYet: 'job.notAnsweredYet',
  noQuestions: 'job.noQuestions',
  searchJob: 'jobs.search',
  createJob: 'jobs.create',
  jobsAll: 'jobs.all',
  jobsActive: 'jobs.active',
  jobsArchived: 'jobs.archived',
  jobsDraft: 'jobs.draft',
  jobDeleteQuestion: 'job.delete.question',
  jobDeleteDescription: 'job.delete.description',
  jobDeleteSuccess: 'job.delete.success',
  jobDeleteError: 'job.delete.error',
  jobsExpired: 'jobs.expired',
  candidatesProvided: 'job.candidatesProvided',
  jobDescription: 'job.description',
  jobName: 'job.title',
  enterJobTitle: 'job.enter.title',
  workLocation: 'job.workLocation',
  contractType: 'job.contractType',
  minSalary: 'job.minSalary',
  maxSalary: 'job.maxSalary',
  selectCity: 'job.selectCity',
  tasks: 'job.tasks',
  seniority: 'job.seniority',
  selectSeniority: 'job.selectSeniority',
  selectBenefits: 'job.selectBenefits',
  benefits: 'job.benefits',
  yourBenefits: 'job.yourBenefits',
  createBenefits: 'job.createBenefits',
  emojiSelect: 'job.emojiSelect',
  requirements: 'job.requirements',
  jobCover: 'job.cover',
  introVideo: 'job.introVideo',
  gallery: 'job.gallery',
  customColor: 'job.customColor',
  customBackgroundColor: 'job.customBackgroundColor',
  jobDetails: 'job.details',
  customization: 'job.customization',
  qualifyingQuestion: 'job.qualifyingQuestion',
  questionHeading: 'job.questionHeading',
  questionShortAnswer: 'job.questionShortAnswer',
  questionShortAnswerDescription: 'job.questionShortAnswerDescription',
  questionChoice: 'job.questionChoice',
  questionChoiceDescription: 'job.questionChoiceDescription',
  questionYesNo: 'job.questionYesNo',
  questionYesNoDescription: 'job.questionYesNoDescription',
  summary: 'job.summary',
  disqualifyTooltip: 'job.disqualifyTooltip',
  disqualify: 'job.disqualify',
  typeYourQuestion: 'job.typeYourQuestion',
  addNewOption: 'job.addNewOption',
  fullTime: 'job.fullTime',
  partTime: 'job.partTime',
  workFromOffice: 'job.workFromOffice',
  hybrid: 'job.hybrid',
  remote: 'job.remote',
  workingHours: 'job.workingHours',
  employemtType: 'job.employemtType',
  salary: 'job.salary',
  location: 'job.location',
  match: 'job.match',
  nomatch: 'job.nomatch',
  appliedOn: 'job.appliedOn',
  gdp: 'job.gdp',
  yetToWriteRemarks: 'job.yetToWriteRemarks',
  jobPreview: 'job.preview',
  jobProceed: 'job.proceed',
  addLinkToIntroVideo: 'job.addLinkToIntroVideo',
  qualificationQuestionDescription: 'job.qualificationQuestion.description',
  questionNoAnswer: 'job.questionNoAnswer',
  publishSuccess: 'job.publish.success',
  publishFailed: 'job.publish.failed',
  maximumCandidateNeeded: 'job.maximumCandidateNeeded',
  enterMaxCandidateNeeded: 'job.enterMaxCandidateNeeded',
  minCandidateRequired: 'job.minCandidateRequired',
  jobPagePreviewMessage: 'job.jobPagePreviewMessage',
  jobPagePreviewSubmit: 'job.pagePreviewSubmit',
  applicationSubmitSuccess: 'application.submitSuccess',
  viewApplications: 'job.viewApplications',
  jobDuplicate: 'job.duplicate',
  jobPosition: 'job.position',
  jobSeniority: 'job.seniority',
  jobContract: 'job.contract',
  jobSalary: 'job.salary',

  // job applications
  applicationEmptyPlaceholder: 'application.empty',

  // job comments
  comments: 'comments',
  commentsDefault: 'comments.default',
  commentsEnter: 'comments.enter',
  commentsSave: 'comments.save',

  // Applications
  userAcceptedMessage: 'user.acceptes.message',
  userDeclinedMessage: 'user.rejected.message',

  // JobLanding
  jobLandingWeAreLookingFor: 'job.landing.weAreLookingFor',
  jobLandingWeAreLookingForWithName: 'job.landing.weAreLookingForWithName',
  interestedInRole: 'job.landing.interestedInRole',
  jobShareMessage: 'job.landing.shareMessage',
  shareThisJob: 'job.landing.shareThisJob',
  copyToClipboard: 'job.landing.copyToClipboard',
  shareOnWhatsapp: 'job.landing.shareOnWhatsapp',
  allSet: 'job.landing.allSet',
  weWillGetBack: 'job.landing.weWillGetBack',

  // Company
  companyUpdatedSuccessfully: 'company.settings.updatedSuccessfully',
  companyName: 'company.name',
  enterCompanyName: 'company.enterName',
  companyLogo: 'company.logo',
  companyWebsite: 'company.website',
  enterWebsite: 'company.enterWebsite',
  linkedin: 'company.linkedin',
  enterLinkedIn: 'company.enterLinkedIn',
  enterXing: 'company.enterXing',
  xing: 'company.xing',
  industry: 'company.industry',
  selectIndustry: 'company.selectIndustry',
  headCount: 'company.headCount',
  foundingDateLabel: 'company.foundingDateLabel',
  aboutCompany: 'company.aboutCompany',
  aboutCompanyHelper: 'company.aboutCompanyHelper',
  billingAddress: 'company.billingAddress',
  billingAddressHelper: 'company.billingAddressHelper',
  companySettings: 'company.settingsLabel',
  companySubscriptions: 'company.subscriptionsLabel',
  companyUsers: 'company.usersLabel',
  companyCustomization: 'company.customizationLabel',
  enterCompanyStreet: 'coompany.address.enterStreet',
  enterCompanyStreetNumber: 'coompany.address.enterStreetNumber',
  enterCompanyCity: 'coompany.address.enterCity',
  enterCompanyPostcode: 'coompany.address.enterPostcode',
  enterCompanyCountry: 'coompany.address.enterCountry',

  // Settings
  passwordUpdatedSuccessfully: 'settings.passwordUpdatedSuccessfully',
  failedToUploadPassword: 'settings.failedToUploadPassword',
  oldPassword: 'setting.oldPassword',
  enterOldPassword: 'settings.enterOldPassword',
  enterNewPassword: 'settings.enterNewPassword',
  confirmPassword: 'settings.confirmPassword',
  enterConfirmPassword: 'settings.enterConfirmPassword',
  profileUpdatedSuccessfully: 'settings.profileUpdatedSuccessfully',
  avatar: 'settings.avatar',
  language: 'settings.language',
  descriptionLanguage: 'settings.descriptionLanguage',
  selectLanguage: 'settings.selectLanguage',
  profileSettings: 'settings.profile',
  accountSettings: 'settings.account',

  // Credits
  credits: 'credits',
  currentCredits: 'credits.currentLabel',
  pendingCredits: 'credits.pendingLabel',
  totalCredits: 'credits.totalLabel',
  invoicesHeading: 'credits.invoicesHeading',
  invoiceTitle: 'creadits.invoiceTitle',
  invoicePaid: 'credits.invoicePaid',
  invoiceNotPaid: 'credits.invoiceNotPaid',
  invoiceHasStorno: 'credits.invoiceHasStorno',
  noInvoicesAvailable: 'credits.noInvoicesAvailable',
  reservedCredits: 'credits.reservedCredits',
  yetToSpendCredits: 'credits.yetToSpendCredits',
  creditErrorLoadPrice: 'credits.error.load.price',
  creditStartRecruiting: 'credits.startRecruiting',
  creditTooLess: 'credits.TooLess',
  creditSelectAmountHead: 'credits.SelectAmountHead',
  creditPerCreditPrice: 'credit.perCreditPrice',
  creditPriceTotal: 'credits.priceTotal',
  creditsIAgree: 'credits.iAgree',
  termsConditions: 'credits.termsConditions',
  termsConditionsError: 'credits.termsConditionsError',
  creditsSelectPlan: 'credits.selectPlan',
};

export const extractIntlKey = (id: string) => {
  const isPropertyExist = IntlKeys.hasOwnProperty(id);

  return isPropertyExist ? IntlKeys[id as unknown as keyof typeof IntlKeys] : id;
};
