import { IntlKeys } from 'localization';
import * as React from 'react';
import { useIntl } from 'react-intl';

const EmptyApplications = () => {
  const { formatMessage } = useIntl();
  return <div>{formatMessage({ id: IntlKeys.applicationEmptyPlaceholder })}</div>;
};

export default EmptyApplications;
