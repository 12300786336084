import React from 'react';
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  reportTitle: {
    color: '#F7F8FF',
    letterSpacing: 4,
    fontSize: 25,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  logo: {
    width: 74,
  },
});
interface OwnProps {
  title: string;
}

const InvoiceTitle: React.FC<OwnProps> = ({ title }) => (
  <View style={styles.titleContainer}>
    <View>
      <Image style={styles.logo} src="https://storage.talentsandfriends.de/tandf/tandf-logo.png" />
    </View>
    <Text style={styles.reportTitle}>{title}</Text>
  </View>
);

export default InvoiceTitle;
