import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CreditType, InvoicesList, PurchaseCreditPayload, SubscriptionPayload, SubscriptionType } from 'types/credits';
//
import { Params, restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';

export const getCreditsAction = createAsyncThunk<CreditType, Params | undefined>(
  'credits/getCreditsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CreditType>({
      api: restClient.getCredits,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const purchaseCreditsAction = createAsyncThunk<CreditType, PurchaseCreditPayload>(
  'company/purchaseCreditsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CreditType>({
      api: restClient.purchaseCredits,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchInvoicesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<InvoicesList>({
    api: restClient.getInvoices,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const getSubscriptionsAction = createAsyncThunk<SubscriptionType[], Params | undefined>(
  'credits/getSubscriptionsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<SubscriptionType[]>({
      api: restClient.getSubscriptions,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createSubscriptionAction = createAsyncThunk<SubscriptionType, SubscriptionPayload & Params>(
  'company/createSubscriptionAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<SubscriptionType>({
      api: restClient.createSubscription,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
