import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
// redux
import {
  signInAction,
  signUpAction,
  resetPasswordAction,
  forgotPasswordAction,
  demoSignInAction,
} from 'domain/auth/actions';
// validation
import { yupResolver } from '@hookform/resolvers/yup';
import {
  LoginValidationSchema,
  SignUpValidationSchema,
  ResetPasswordValidationSchema,
  ForgotPasswordValidationSchema,
  DemoLoginValidationSchema,
} from '../validation';
// types
import { FieldValues } from 'react-hook-form';
import { AxiosError } from 'axios';
import { Params } from 'helpers/http';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'domain/store';

type AuthPageType = 'signIn' | 'demoSignIn' | 'signUp' | 'forgotPassword' | 'resetPassword';

type NetworkErrorType = {
  data: AxiosError;
  requestPayload: Params;
};

type OwnProps = {
  authPage: AuthPageType;
  defaultValues?: FieldValues;
};

const extractFormSchema = (authPage: AuthPageType) => {
  switch (authPage) {
    case 'signIn':
      return LoginValidationSchema;
    case 'demoSignIn':
      return DemoLoginValidationSchema;
    case 'signUp':
      return SignUpValidationSchema;
    case 'forgotPassword':
      return ForgotPasswordValidationSchema;
    case 'resetPassword':
      return ResetPasswordValidationSchema;
  }
};

const useAuthPage = ({ authPage, defaultValues }: OwnProps) => {
  const [fulfilledMessageId, setFulfilledMessageId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessageId, setErrorMessageId] = useState('');
  const navigate = useNavigate();
  const form = useForm({
    resolver: yupResolver(extractFormSchema(authPage)),
    defaultValues,
  });
  const dispatch = useAppDispatch();

  const extractSubmitAction = useCallback(
    (authPage: AuthPageType, payload: FieldValues) => {
      switch (authPage) {
        case 'signIn':
          return dispatch(signInAction(payload));
        case 'demoSignIn':
          return dispatch(demoSignInAction(payload));
        case 'signUp':
          return dispatch(signUpAction(payload));
        case 'forgotPassword':
          return dispatch(forgotPasswordAction(payload));
        case 'resetPassword':
          return dispatch(resetPasswordAction(payload));
      }
    },
    [dispatch],
  );

  const onSubmit = useCallback(
    (payload: FieldValues) => {
      setIsLoading(true);
      setErrorMessageId('');
      setFulfilledMessageId('');

      extractSubmitAction(authPage, payload)
        .unwrap()
        .then(() => {
          setFulfilledMessageId(`${authPage}Fulfilled`);
          setIsLoading(false);
          if (authPage === 'signUp' || authPage === 'resetPassword') {
            navigate('/login');
          } else if (authPage === 'signIn' || authPage === 'demoSignIn') {
            navigate('/');
          }
        })
        .catch((e: NetworkErrorType) => {
          const response = e?.data?.response;
          const errorMessageId = response ? `${authPage}Error${response.status}` : 'serverNotAvailableError';

          setErrorMessageId(errorMessageId);
          setIsLoading(false);
        });
    },
    [authPage, extractSubmitAction, navigate],
  );

  return { form, onSubmit, isLoading, fulfilledMessageId, errorMessageId };
};

export default useAuthPage;
