import { clsx as cx } from 'clsx';
import React from 'react';
import { GrClose } from 'react-icons/gr';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
//
import { AppBar, Dialog, DialogProps, Fade, IconButton, Toolbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
//
import { FlexBox, Title3 } from 'components/base';
import { Loader } from 'components/loader';
//
import { theme } from 'theme';

interface OwnProps extends Omit<DialogProps, 'open' | 'classes' | 'title'> {
  isOpen?: boolean;
  isLoading?: boolean;
  title?: React.ReactNode;
  //
  handleClose?: (e: React.MouseEvent) => void;

  className?: string;
  classes?: Partial<Record<'dialog' | 'topBar' | 'topBarTitle' | 'bottomBar' | 'content', string>>;
  //
  children: React.ReactNode;
  bottomBarContent?: React.ReactNode;
  disableTopBarCloseButton?: boolean;
  disableTitle?: boolean;
}

const ModalDialog: React.FC<OwnProps> = ({
  //
  isOpen = false,
  isLoading = false,
  title = '',
  //
  handleClose = () => {},
  //
  className,
  classes = {},
  //
  children,
  bottomBarContent,
  disableTopBarCloseButton,
  disableTitle = false,
  ...dialogProps
}) => {
  const { formatMessage } = useIntl();

  return (
    <DialogBox
      //
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{
        paper: cx('dialog', classes.dialog, className),
      }}
      {...dialogProps}
      disablePortal={true}
    >
      {!disableTitle && (
        <TopAppBar position="sticky">
          <TopBar className={cx(classes.topBar)} justify={title ? 'space-between' : 'flex-end'} align="center">
            <TopBarTitle className={cx(classes.topBarTitle)}>{title}</TopBarTitle>

            {!disableTopBarCloseButton && (
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <GrClose />
              </IconButton>
            )}
          </TopBar>
        </TopAppBar>
      )}

      <Content className={cx(classes.content)}>{children}</Content>

      {bottomBarContent && (
        <BottomAppBar position="sticky">
          <BottomBar className={cx(classes.bottomBar)}>{bottomBarContent}</BottomBar>
        </BottomAppBar>
      )}

      {isLoading && <Loader />}
    </DialogBox>
  );
};

const Transition: React.FC<
  TransitionProps & {
    children: React.ReactElement;
  }
> = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const DialogBox = styled(Dialog)`
  & .dialog {
    display: block;
    max-width: 100%;
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;

    .MuiAppBar-root {
      box-shadow: none;
    }
  }
`;

const AppBarSection = styled(AppBar)`
  background: ${theme.colors.white} !important;
  box-shadow: none;
`;

const TopAppBar = styled(AppBarSection)`
  top: 0;
  bottom: 'auto';
`;

const BottomAppBar = styled(AppBarSection)`
  bottom: 0;
  top: auto;
`;

const TopBar = styled(FlexBox)`
  border-bottom: 1px solid ${theme.colors.stroke};
  padding: 0 1rem;
`;

const BottomBar = styled(Toolbar)`
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid ${theme.colors.stroke};
`;

const TopBarTitle = styled(Title3)`
  font-size: 1.5rem;
  font-family: ${theme.fonts.montreal};
  color: ${theme.colors.dark};
  font-weight: 600;
  line-height: 2rem;
  align-items: start;
`;

const Content = styled.div`
  max-height: calc(100% - 64px);
`;

export default React.memo(ModalDialog);
