import { Wrapper } from 'components/Layout';
import { getCompanyDetailsAction } from 'domain/company/actions';
import { companyResourceSelector } from 'domain/company/selectors';
import { getCreditsAction } from 'domain/credits/actions';
import { creditResourceSelector } from 'domain/credits/selectors';
import { updateJobStatusAction } from 'domain/jobs/actions';
import { jobsResourceSelector } from 'domain/jobs/selectors';
import { useAppDispatch } from 'domain/store';
import useJobCreation from 'hooks/useJobCreation';
import { IntlKeys } from 'localization';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { FieldValues, FormProvider, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { JobPublishPayload } from 'types/job';
import FormFooter from '../FormFooter';
import CalculationSection from './CalculationSection';
import PurchaseCreditsModal from './Modals/PurchaseCreditsModal';
import Summary from './Summary';
//
import { ImWarning } from 'react-icons/im';
import { Card } from 'components/base';

const Step4 = () => {
  const jobDetails = useSelector(jobsResourceSelector);
  const companyDetails = useSelector(companyResourceSelector);
  const { form } = useJobCreation({
    step: 'summary',
    defaultValues: { maxCandidates: jobDetails?.maxCandidates || 15, isPriority: jobDetails?.priority } || {},
  });
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const maxCandidates = useWatch({ name: 'maxCandidates', control: form.control });
  const isPriority = useWatch({ name: 'isPriority', control: form.control });
  const credits = useSelector(creditResourceSelector);
  const [creditsNeeded, setCreditsNeeded] = useState({ total: maxCandidates || 0, toBePurchased: 0 });
  const [isPurchaseMoreCreditsVisible, setIsPurchaseMoreCreditsVisible] = useState(false);

  useEffect(() => {
    dispatch(getCreditsAction());
    dispatch(getCompanyDetailsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (jobDetails) {
      form.reset({ maxCandidates: jobDetails?.maxCandidates || 15, isPriority: jobDetails?.priority });
    }
  }, [form, jobDetails]);

  useEffect(() => {
    setCreditsNeeded({
      total: maxCandidates,
      toBePurchased: Math.ceil(maxCandidates - Number(credits?.currentAmount) + Number(credits?.reservedAmount)),
    });
  }, [maxCandidates, credits]);

  const setPurchaseCreditsModalOpen = (open: boolean) => {
    setIsPurchaseMoreCreditsVisible(open);
  };

  const onSubmit = useCallback(
    (data: FieldValues) => {
      if (maxCandidates < 5) {
        toast.error(formatMessage({ id: IntlKeys.talentsMinRequired }));
        return;
      }

      if (creditsNeeded.toBePurchased > 0) {
        setIsPurchaseMoreCreditsVisible(true);
        return;
      }

      handleUpdateJobStatus(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [creditsNeeded, maxCandidates, formatMessage],
  );

  const handleUpdateJobStatus = (data: FieldValues) => {
    dispatch(
      updateJobStatusAction({
        params: { id: jobId! },
        ...(data as Omit<JobPublishPayload, 'status'>),
        status: 'published',
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(formatMessage({ id: IntlKeys.publishSuccess }));
        navigate('/jobs?status=published');
        setIsPurchaseMoreCreditsVisible(false);
      })
      .catch(() => {
        toast.error(formatMessage({ id: IntlKeys.publishFailed }));
      });
  };

  const showPreview = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      window.open(`/jobs/${jobId}/preview`, '_blank');
    },
    [jobId],
  );

  const TipSection = () => (
    <SubscriptionTip withBorder>
      <ImWarning />{' '}
      <span>
        {formatMessage({ id: IntlKeys.creditsSelectPlan }) + ' '}
        <Link to="/organization/subscriptions" target={'_blank'}>
          {formatMessage({ id: IntlKeys.here })}
        </Link>
      </span>
    </SubscriptionTip>
  );

  return (
    <>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <FormContainer>
            <Summary job={jobDetails} />
            <CalculationSection
              maxCandidates={maxCandidates}
              job={jobDetails}
              creditPerCandidate={{
                rareFactor: jobDetails?.rareFactor || 0,
                seniorityFactor: jobDetails?.seniorityFactor || 0,
                priorityFactor: isPriority ? 1 : 0,
              }}
              creditsNeeded={creditsNeeded}
            />
            {!companyDetails?.subscription && <TipSection />}
          </FormContainer>
          <FormFooter showPreview={showPreview} />
        </Form>
      </FormProvider>
      <PurchaseCreditsModal
        handleUpdateJobStatus={handleUpdateJobStatus}
        amount={maxCandidates as number}
        creditsNeeded={creditsNeeded}
        visible={isPurchaseMoreCreditsVisible}
        setVisible={setPurchaseCreditsModalOpen}
      />
    </>
  );
};

export default Step4;
const Form = styled.form`
  width: 100%;
`;

const FormContainer = styled(Wrapper)`
  padding: 1rem;
  max-width: 800px;

  > p {
    border-left: 4px solid ${theme.colors.disabledGray};
    padding-left: 0.75rem;
    font-size: 0.875rem;
    color: ${theme.colors.gray};
  }
`;

const SubscriptionTip = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  font-size: 1.25rem;
  color: ${theme.colors.error};
  border: 1px solid;
  background: ${theme.colors.lightYellow};
  margin-top: 2rem;

  svg {
    font-size: 1.5rem;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.primary};
  }
`;
