import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableFooter from './InvoiceTableFooter';
import { InvoiceType } from 'types/credits';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#F7F8FF',
  },
});

interface OwnProps {
  invoice: InvoiceType;
}

const InvoiceItemsTable: React.FC<OwnProps> = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow invoice={invoice} />
    <InvoiceTableFooter invoice={invoice} />
  </View>
);

export default InvoiceItemsTable;
