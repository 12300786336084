// types
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { SortConfigType, FilterFormValues } from 'types/common';

const uSortConfigs = {
  nameAsc: {
    field: 'name',
    order: 'ASC',
  },
  nameDesc: {
    field: 'name',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
};

export const ApplicationsSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type ApplicationFiltersFormValues = FilterFormValues<keyof typeof ApplicationsSortConfigs>;

export const ApplicationSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: 'IntlKeys.candidateNameAsc' }),
      value: 'nameAsc',
    },
    {
      label: formatMessage({ id: 'IntlKeys.candidateNameDesc' }),
      value: 'nameDesc',
    },
    {
      label: formatMessage({ id: 'IntlKeys.applicationCreatedAtAsc' }),
      value: 'applicationCreatedAtAsc',
    },
    {
      label: formatMessage({ id: 'IntlKeys.applicationCreatedAtAsc' }),
      value: 'applicationCreatedAt',
    },
  ];
};
