import { Button, Card, SubmitButton } from 'components/base';
import { Loader } from 'components/loader';
import { companyMetaSelector, companyResourceSelector } from 'domain/company/selectors';
import { createSubscriptionAction, getSubscriptionsAction } from 'domain/credits/actions';
import { subscriptionsSelector } from 'domain/credits/selectors';
import { useAppDispatch } from 'domain/store';
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { theme } from 'theme';
import { SubscriptionType } from 'types/credits';
//
import { FaCheckCircle } from 'react-icons/fa';
import { ImWarning } from 'react-icons/im';
import { toast } from 'react-toastify';
import { getCompanyDetailsAction } from 'domain/company/actions';
import TFSlider from 'components/base/TFSlider';
import { Link } from 'react-router-dom';

const CompanySubscriptions = () => {
  const companyDetails = useSelector(companyResourceSelector);
  const companyMeta = useSelector(companyMetaSelector);
  const subscriptions = useSelector(subscriptionsSelector);
  const dispatch = useAppDispatch();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionType | null>(null);
  const [creditsCount, setCreditsCount] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getSubscriptionsAction());
  }, []);

  const selectNewPlan = useCallback((plan: SubscriptionType) => {
    setSelectedPlan(plan);
  }, []);

  const confirmPlanSeelction = useCallback(() => {
    dispatch(createSubscriptionAction({ params: { id: selectedPlan!.id }, amount: creditsCount! }))
      .then(() => {
        toast.success('Succeffully started the new subscription');
        dispatch(getCompanyDetailsAction());
      })
      .catch(() => {
        toast.error('Failed to start subscription. Please try again later');
      });
  }, [selectedPlan, creditsCount]);

  if (companyMeta.isLoading) {
    return <Loader />;
  }

  if (!companyDetails) {
    return <div>Error! Subscription details can not be loader</div>;
  }

  const { subscription } = companyDetails;

  const TipSection = () => (
    <SubscriptionTip withBorder>
      <ImWarning />{' '}
      <span>
        Add a billing address <Link to="/organization/settings">here</Link> to start a new subscription
      </span>
    </SubscriptionTip>
  );

  if (!subscription) {
    return (
      <>
        {!companyDetails.billingAddress && <TipSection />}
        <NewSubscriptionContainer>
          <PricingPlans>
            {subscriptions.map((plan) => (
              <EachPlan className={plan.name.toLocaleLowerCase()} key={plan.name}>
                <Label className={plan.name.toLocaleLowerCase()} color={'white'}>
                  {plan.name}
                </Label>
                <Price>
                  <Heading>{plan.perCreditsPrice}€</Heading>
                  <span>pro credit</span>
                </Price>
                <MainBenefits className="benefits">
                  <EachBenefits>
                    <FaCheckCircle /> 12 Monate Gültigkeit der Credits
                  </EachBenefits>
                  <EachBenefits>
                    <FaCheckCircle /> Kein Grundgebühr
                  </EachBenefits>
                  <EachBenefits>
                    <FaCheckCircle /> 1 {plan.period === 'monthly' ? 'Monat' : 'Jahre'} Laufzeit
                  </EachBenefits>
                  <EachBenefits>
                    <FaCheckCircle /> 1 Credit pro Bewerbung
                  </EachBenefits>
                  <EachBenefits>
                    <FaCheckCircle /> {plan.maxJobs} Parallel Job
                  </EachBenefits>
                  <EachBenefits>
                    <FaCheckCircle /> Customized landing page
                  </EachBenefits>
                  <EachBenefits>
                    <FaCheckCircle /> Priority customer service
                  </EachBenefits>
                </MainBenefits>
                <Button onClick={() => selectNewPlan(plan)} variant={plan.name === 'Professional' ? 'fill' : 'outline'}>
                  Select Plan
                </Button>
                {selectedPlan?.id === plan.id ? (
                  <CreditChooser>
                    <h3>
                      Choose the number of credits you wanted every {plan.period === 'monthly' ? 'month' : 'year'}
                    </h3>
                    <TFSlider
                      aria-label="Always visible"
                      defaultValue={plan.period === 'monthly' ? 20 : 50}
                      step={plan.period === 'monthly' ? 10 : 50}
                      valueLabelDisplay="on"
                      onChange={(_, value) => setCreditsCount(value as number)}
                      min={plan.period === 'monthly' ? 20 : 50}
                      max={plan.period === 'monthly' ? 150 : 1000}
                    />
                    <SubmitButton onClick={confirmPlanSeelction}>Confirm</SubmitButton>
                  </CreditChooser>
                ) : null}
              </EachPlan>
            ))}
          </PricingPlans>
        </NewSubscriptionContainer>
        <LinkToPricingPage>
          Full breakdown of the subscription plans are{' '}
          <a href="https://talentsandfriends.de/pricing" target={'_blank'} rel="noreferrer">
            available here
          </a>
        </LinkToPricingPage>
      </>
    );
  }

  return (
    <>
      {!companyDetails.billingAddress && <TipSection />}
      <Container>
        <SubscriptionCard className={String(subscription?.name).toLowerCase()}>
          <Label>{subscription?.name}</Label>
          <Price>
            <Heading>{subscription.perCreditsPrice}€</Heading>
            <span>pro credit</span>
          </Price>
          <MainBenefits>
            <EachBenefits>12 Monate Gültigkeit der Credits</EachBenefits>
            <EachBenefits>Kein Grundgebühr</EachBenefits>
            <EachBenefits>1 {subscription.period === 'monthly' ? 'Monat' : 'Jahre'} Laufzeit</EachBenefits>
            <EachBenefits>1 Credit pro Bewerbung</EachBenefits>
            <EachBenefits>{subscription.maxJobs} Parallel Job</EachBenefits>
            <EachBenefits>Customized landing page</EachBenefits>
            <EachBenefits>Priority customer service</EachBenefits>
          </MainBenefits>
        </SubscriptionCard>
      </Container>
    </>
  );
};

export default CompanySubscriptions;

const Container = styled.div`
  margin-top: 2rem;
`;

const SubscriptionCard = styled(Card)`
  border-radius: 30px;
  padding: 2rem;
  color: white;
  max-width: 400px;

  &.basic {
    background-color: ${theme.colors.darkGreen};
  }
  &.professional {
    background-color: ${theme.colors.maroon};
  }
  &.enterprise {
    background-color: ${theme.colors.purple};
  }
`;

const Heading = styled.h2`
  font-size: 3rem;
  line-height: 4.125rem;
  font-weight: 400;
  text-align: center;
  margin: 0;
  line-height: 2rem;

  @media screen and (max-width: 800px) {
    font-size: 2rem;
    line-height: 2.2rem;
  }
`;

const Label = styled.div`
  display: inline-block;
  font-weight: 530;
  padding: 2px 0.5rem;
  border-radius: 0.25rem;
  background-color: ${(props: { bg?: string }) => props.bg || theme.colors.white};
  color: ${(props: { color?: string }) => props.color || theme.colors.secondary};
  font-size: 0.875rem;

  &.basic {
    background-color: ${theme.colors.darkGreen};
  }
  &.professional {
    background-color: ${theme.colors.maroon};
  }
  &.enterprise {
    background-color: ${theme.colors.purple};
  }
`;

const Price = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 2.5rem 0rem 1.5rem;
  gap: 0.4rem;

  h2 {
    margin: 0;
    line-height: 2rem;
  }
`;

const MainBenefits = styled.div`
  margin: 1rem 0;
`;

const EachBenefits = styled.div`
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${theme.colors.mintGreen};
  }

  &:not(:first-child) {
    border-top: 1px solid ${theme.colors.stroke};
  }
`;

const NewSubscriptionContainer = styled.div``;

const PricingPlans = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 2rem;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

const EachPlan = styled(Card)`
  padding: 2rem;
  position: relative;

  &.basic {
    background-color: ${theme.colors.lightGray};
  }
  &.professional {
    background-color: ${theme.colors.mintGreen};

    .benefits {
      > div {
        &:not(:first-child) {
          border-top: 1px solid ${theme.colors.gray};
        }

        svg {
          color: ${theme.colors.maroon};
        }
      }
    }
  }
  &.enterprise {
    background-color: ${theme.colors.lightGray};
  }

  button {
    width: 100%;
    margin-top: 1.5rem;
    font-weight: 400;
  }
`;

const CreditChooser = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 2rem;
  background-color: ${theme.colors.white};
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid ${theme.colors.stroke};

  h3 {
    margin: 0rem 0rem 3rem;
    font-weight: 400;
  }
`;

const LinkToPricingPage = styled.div`
  padding: 1rem;
  margin-top: 2rem;
  text-align: center;

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
  }
`;

const SubscriptionTip = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  font-size: 1.25rem;
  color: ${theme.colors.error};
  border: 1px solid;
  background: ${theme.colors.lightYellow};

  svg {
    font-size: 2rem;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.primary};
  }
`;
