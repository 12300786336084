import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
//
import { theme } from 'theme';
import { FlexBox } from 'components/base';
import { formatMessage } from '@formatjs/intl';
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';

interface EachNav {
  label: string;
  icon: string;
  link: string;
  end?: boolean;
}



const secNavigation = [
  {
    label: 'Settings',
    icon: 'icon1',
    link: '/settings',
  },
];
  
const Navigation = () => {
  const { formatMessage } = useIntl();

  const mainNavigation: EachNav[] = [
    {
      label: formatMessage({ id: IntlKeys.jobs }),
      icon: 'icon1',
      link: '/jobs',
    },
    {
      label: formatMessage({ id: IntlKeys.talents }),
      icon: 'icon1',
      link: '/applications',
    },
  ];

  return (
    <Container align="center" direction="row">
      <EachSection>
        {mainNavigation.map((eachNav) => (
          <NavigationLink key={eachNav.label} to={eachNav.link} end={eachNav?.end}>
            {eachNav.label}
          </NavigationLink>
        ))}
      </EachSection>
    </Container>
  );
};

export default Navigation;

const Container = styled(FlexBox)`
  box-sizing: border-box;
`;

const EachSection = styled.div``;

const NavigationLink = styled(NavLink)`
  padding: 0.75rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  color: ${theme.colors.dark};
  border-radius: 4px;
  &.active {
    background-color: ${theme.colors.lightBlue};
    color: ${theme.colors.primary};
  }
`;
