import styled from 'styled-components';
import { theme } from 'theme';

const Title1 = styled.h1`
  font-size: 2.5rem;
  margin: 0;
  color: ${theme.colors.secondary};
`;

const Title2 = styled.h2`
  font-size: 2rem;
  margin: 0;
  color: ${theme.colors.secondary};
  font-weight: 400;
`;

const Title3 = styled.h3`
  font-size: 1.5rem;
  margin: 0;
  font-weight: 400;
  color: ${theme.colors.secondary};
`;

const Text = styled.p`
  font-size: 1rem;
  margin: 0;
  color: ${theme.colors.dark};
`;

const SecText = styled.span`
  font-size: 0.875rem;
  color: ${theme.colors.gray};
`;

export { Title1, Title2, Title3, Text, SecText };
