import { createSlice } from '@reduxjs/toolkit';

import { getCreditsAction, getSubscriptionsAction } from './actions';

import { CreditType, SubscriptionType } from 'types/credits';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    //
    isStale: false,
    //
  },
  resource: null as CreditType | null,
  mySubscriptions: null as SubscriptionType | null,
  subscriptions: [] as SubscriptionType[],
};

const creditSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder //

      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getCreditsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getCreditsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;

        draft.resource = payload;
      })
      .addCase(getCreditsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.resource = null;
      })
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getSubscriptionsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getSubscriptionsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;

        draft.subscriptions = payload;
      })
      .addCase(getSubscriptionsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.subscriptions = [];
      });
  },
});

export const creditReducer = creditSlice.reducer;
