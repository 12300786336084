import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  width?: number | string;
  withBorder?: boolean;
  borderColor?: string;
  bgColor?: string;
}

const Card = styled.div`
  background-color: ${(props: CardProps) => (props.bgColor ? props.bgColor : theme.colors.white)};
  padding: 1rem;
  border-radius: 20px;
  cursor: ${(props: CardProps) => (props.onClick ? 'pointer' : 'initial')};
  box-sizing: border-box;
  width: ${(props: CardProps) => (props.width ? props.width : 'auto')};
  border: ${(props: CardProps) => (props.withBorder ? `1px solid` : 'none')};
  border-color: ${(props: CardProps) => (props.borderColor ? props.borderColor : theme.colors.stroke)};
`;

export default Card;
