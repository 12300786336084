import styled from 'styled-components';
import { Skeleton } from '@mui/material';
import React from 'react';
import { range } from 'lodash';

interface OwnProps {
  count?: number;
}

const DropdownSkeleton: React.FC<OwnProps> = ({ count = 5 }) => (
  <SkeletonConainter>
    {range(count).map((_, index) => (
      <Skeleton key={index} variant="rectangular" height={32} width={'100%'} />
    ))}
  </SkeletonConainter>
);

export default DropdownSkeleton;

const SkeletonConainter = styled.div`
  display: grid;
  gap: 0.5rem;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
`;
