import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
//
import { Card, FlexBox } from 'components/base';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { useIntl } from 'react-intl';
import { MultipleChoice, YesOrNoOptions } from './ChoiceTypes';
//
import yesOrNoIcon from 'assets/icons/yesOrNo.svg';
import multipleChoiceIcon from 'assets/icons/multipleChoice.svg';
import shortTextIcon from 'assets/icons/shortText.svg';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import { GrTrash } from 'react-icons/gr';
import { theme } from 'theme';
import { IntlKeys } from 'localization';
import { Tooltip } from '@mui/material';
import { QualificationQuestion, QualificationQuestionsPayload } from 'types/job';

export type QuestionOptionType = {
  value: QualificationQuestion;
  label: string;
  icon: string;
  descrition: string;
};

interface QuestionRendererProps {
  questionType: QualificationQuestion;
  id: number;
  removeQuestion: (id: number) => void;
  updateQuestionType: (newValue: QuestionOptionType, id: number) => void;
  initialValue: QualificationQuestionsPayload;
}

export const QuestionRenderer: React.FC<QuestionRendererProps> = ({
  questionType,
  id,
  removeQuestion,
  updateQuestionType,
  initialValue,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(questionType);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (questionType !== selectedQuestion) {
      setSelectedQuestion(questionType);
    }
  }, [questionType, selectedQuestion]);

  const questionTypes: QuestionOptionType[] = [
    {
      value: 'short_answer',
      label: formatMessage({ id: IntlKeys.questionShortAnswer }),
      icon: shortTextIcon,
      descrition: formatMessage({ id: IntlKeys.questionShortAnswerDescription }),
    },
    {
      value: 'choice',
      label: formatMessage({ id: IntlKeys.questionChoice }),
      icon: multipleChoiceIcon,
      descrition: formatMessage({ id: IntlKeys.questionChoiceDescription }),
    },
    {
      value: 'yes_no',
      label: formatMessage({ id: IntlKeys.questionYesNo }),
      icon: yesOrNoIcon,
      descrition: formatMessage({ id: IntlKeys.questionYesNoDescription }),
    },
  ];

  const renderQuestionAndOptions = (type: QualificationQuestion) => {
    switch (type) {
      case 'short_answer':
        return (
          <FormTextField
            name={`question.${id}.question`}
            placeholder={formatMessage({ id: IntlKeys.typeYourQuestion })}
            rules={{
              required: formatMessage({ id: IntlKeys.requiredItem }),
            }}
            required
          />
        );
      case 'yes_no':
        return (
          <Container>
            <FormTextField
              name={`question.${id}.question`}
              placeholder={formatMessage({ id: IntlKeys.typeYourQuestion })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
              required
            />
            <YesOrNoOptions id={id} initialValue={initialValue} />
          </Container>
        );
      case 'choice':
        return (
          <Container>
            <FormTextField
              name={`question.${id}.question`}
              placeholder={formatMessage({ id: IntlKeys.typeYourQuestion })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
              required
            />
            <MultipleChoice id={id} initialValue={initialValue} />
          </Container>
        );
    }
  };

  return (
    <QuestionCard withBorder>
      <CardHeader justify="space-between" align="center" gap="1rem">
        <FormAutoComplete
          options={questionTypes}
          name={`question.${id}.type`}
          disableClearable
          defaultOption={questionTypes.find((question) => question.value === questionType)}
          onChange={(_, newValue: any) => updateQuestionType(newValue as QuestionOptionType, id)}
        />
        <Tooltip title="Remove question">
          <Delete onClick={() => removeQuestion(id)}>
            <GrTrash />
          </Delete>
        </Tooltip>
      </CardHeader>
      {renderQuestionAndOptions(selectedQuestion)}
    </QuestionCard>
  );
};

const QuestionCard = styled(Card)`
  margin-top: 1.5rem;
`;

const CardHeader = styled(FlexBox)`
  margin-bottom: 1.5rem;

  & .MuiAutocomplete-root {
    max-width: 300px;

    input {
      background-color: ${theme.colors.white};
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        border: none;
      }
    }
    .MuiOutlinedInput-root fieldset {
      border: none !important;
    }
  }
`;

const Container = styled.div``;

const Delete = styled.div`
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;

  > svg {
    path {
      stroke: ${theme.colors.red};
    }
  }

  &:hover {
    background-color: ${theme.colors.lightGray};
    border-radius: 40px;
    transition: all linear 0.25s;
  }
`;
