import { FlexBox, Title3 } from 'components/base';
import React from 'react';
import { IntlShape } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { CompanyType } from 'types/company';
import Gallery from './Gallery';
import { BsPeople, BsGlobe, BsCalendarEvent, BsLinkedin } from 'react-icons/bs';
import { ImXing } from 'react-icons/im';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { IntlKeys } from 'localization';
import { formatDate } from 'helpers/date';

interface OwnProps {
  company?: CompanyType | null;
  intl: IntlShape;
}

const CompanyDetails: React.FC<OwnProps> = ({ company, intl }) => {
  const { formatMessage, locale } = intl;
  if (!company) return null;
  return (
    <CompanyDetailsSection>
      {company.logo?.url ? <Logo src={company.logo?.url} /> : <Title3>{company.name}</Title3>}
      <MetaDetails>
        <EachDetails>
          <BsPeople />
          <span>{company.employeeCount || '--'}</span>
        </EachDetails>
        <EachDetails>
          <HiOutlineBuildingOffice2 /> <span>{company.industry || '--'}</span>
        </EachDetails>
        {company.website && (
          <EachDetails>
            <BsGlobe /> <span>{company.website}</span>
          </EachDetails>
        )}
        {company.linkedin && (
          <EachDetails>
            <BsLinkedin /> <span>{company.linkedin}</span>
          </EachDetails>
        )}
        {company.xing && (
          <EachDetails>
            <ImXing /> <span>{company.xing}</span>
          </EachDetails>
        )}
        <EachDetails>
          <BsCalendarEvent />
          <span>
            {formatMessage(
              { id: IntlKeys.foundingDate },
              { date: formatDate(company.foundingDate, 'MMM YYYY') || '--' },
            )}
          </span>
        </EachDetails>
      </MetaDetails>
      {locale.startsWith('de') && company.description && (
        <Description dangerouslySetInnerHTML={{ __html: company.description['de-DE'] }} />
      )}
      {locale.startsWith('en') && company.description && (
        <Description dangerouslySetInnerHTML={{ __html: company.description['en-US'] }} />
      )}

      {company.defaultGallery?.length ? <Gallery gallery={company.defaultGallery} /> : null}
    </CompanyDetailsSection>
  );
};

export default CompanyDetails;

const CompanyDetailsSection = styled.div`
  padding: 2rem;
  background: ${theme.colors.lightGray};
  border-radius: 20px;
  margin-top: 4rem;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 1rem;
  }
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 48px;
`;

const MetaDetails = styled(FlexBox)`
  gap: 0.5rem;
  margin-top: 1.25rem;
  flex-wrap: wrap;
`;

const EachDetails = styled.div`
  border-radius: 30px;
  background-color: ${theme.colors.white};
  padding: 0.35rem 1rem;
  line-height: 0.85rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const Description = styled.div`
  margin-top: 1.5rem;

  * {
    background-color: transparent !important;
    font-family: NeueMontreal !important;
    font-size: initial !important;
  }
`;
