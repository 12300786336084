import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
//
import VerticalInfiniteGrid from 'components/InfiniteList/VerticalInfiniteGrid';
import ApplicationCard from '../ApplicationCard';
import EmptyApplications from '../EmptyApplications';
import { Application, ApplicationStatus } from 'types/application';
import { ApplicationActionType } from 'types/application';
import { ApplicationsContext } from 'pages/Applications/utils';
import FloatingApplicationCard from './componenst/FloatingApplicationCard';
import { useAppDispatch } from 'domain/store';
import {
  downloadCandidatesAction,
  fetchApplicationsWorker,
  updateApplicationAction,
} from 'domain/applications/actions';
import { ApplicationFiltersFormValues, ApplicationsSortConfigs } from 'types/application/entities';
import { useSearchParams } from 'react-router-dom';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { debounce } from 'lodash';
import ApplicationFilter from '../ApplicationFilter';

type RejectedAcceptiedType = {
  type: ApplicationActionType;
  application: Application;
};

const ApplicationsList = () => {
  const [acceptedOrDeclined, setAcceptedOrDeclined] = useState<Record<string, RejectedAcceptiedType | null>>({});
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const jobId = searchParams.get('jobId') || '';
  const initialFilterConfig: ApplicationFiltersFormValues = {
    sort: 'createdAtDesc',
    filters: { jobId },
  };

  const [filterConfig, setfilterConfig] = useState(initialFilterConfig);
  const {
    isLoading,
    isFetched,
    resources: applications,
    fetchNextPage,
    refetch: refetchApplications,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'companyApplicationsList',
    //
    fetchResources: fetchApplicationsWorker,
    initialSort: ApplicationsSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: ApplicationFiltersFormValues) => {
      const { sort, filters } = config;
      setSearchParams({ ...filters });
      setSort(ApplicationsSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
      setfilterConfig(config);
    }, 200),
    [],
  );

  const addToUpdatedApplications = (type: ApplicationActionType, application: Application) => {
    const id = new Date().getTime();
    const newOne = {
      type,
      application,
    };
    setAcceptedOrDeclined((applications) => ({ ...applications, [id]: newOne }));
    refetchApplications?.();
    setTimeout(() => {
      setAcceptedOrDeclined((applications) => ({ ...applications, [id]: undefined }));
    }, 4000);
  };

  const removeFromUpdatedList = useCallback((key: string) => {
    const { [key]: value, ...rest } = acceptedOrDeclined;
    setAcceptedOrDeclined(rest);
  }, []);

  const updateApplicationStatus = (status: ApplicationStatus, applicationId: number | string, key: string) => {
    const payload = { status };
    dispatch(updateApplicationAction({ params: { id: applicationId }, ...payload }))
      .unwrap()
      .then(() => {
        refetchApplications?.();
        removeFromUpdatedList(key);
      });
  };

  return (
    <ApplicationsContext.Provider value={{ addToUpdatedApplications }}>
      <Container>
        <ApplicationFilter onChange={handleFiltersChange} config={filterConfig} />
        <VerticalInfiniteGrid
          list={applications || []}
          //
          isLoading={isLoading}
          isLoaded={isFetched}
          page={pagination.page}
          pageCount={pagination.pageCount}
          itemCount={pagination.total}
          //
          fetchNext={fetchNextPage}
          emptyState={<EmptyApplications />}
          ListCard={ApplicationCard}
          classes={{
            container: 'grid-container',
          }}
        />
      </Container>
      {Object.keys(acceptedOrDeclined).length ? (
        <FixedContainer>
          {Object.entries(acceptedOrDeclined).map(([key, value]) =>
            value ? (
              <FloatingApplicationCard
                type={value?.type}
                application={value?.application}
                updateApplicationStatus={updateApplicationStatus}
                key={key}
              />
            ) : null,
          )}
        </FixedContainer>
      ) : null}
    </ApplicationsContext.Provider>
  );
};

export default ApplicationsList;

const Container = styled.div`
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(min(380px, 100%), 1fr));
  }
`;

const FixedContainer = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
`;
