import { Button, Card, FlexBox, SecText, Text, Title3 } from 'components/base';
import { Wrapper } from 'components/Layout';
import { formatCurrency } from 'helpers/number';
import translations, { IntlKeys } from 'localization';
import React, { useCallback, useEffect, useState } from 'react';
import { createIntl } from 'react-intl';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { theme } from 'theme';
import { ContractTypeEnum, JobLocationEnum, JobType } from 'types/job';
//
import jobCoverPlaceHolder from 'assets/images/jobCover.svg';
import { BiClipboard } from 'react-icons/bi';
import { BsWhatsapp } from 'react-icons/bs';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { Tooltip } from '@mui/material';
import JobApplicationForm from './JobApplicationForm';
import Gallery from './Gallery';
import CompanyDetails from './CompanyDetails';
import { useSearchParams } from 'react-router-dom';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { MdWavingHand } from 'react-icons/md';
import { setSeo } from 'helpers/seo';

interface OwnProps {
  isPreview?: boolean;
  jobDetails: JobType;
}

const JobLandingPage: React.FC<OwnProps> = ({ isPreview, jobDetails }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const applicationIdFromUrl = searchParams.get('applicationId');
  const [isApplyFormVisible, setIsApplyFormVisible] = useState(applicationIdFromUrl ? true : false);
  const { company, customMessage, tokens, language } = jobDetails;
  const additionalInfo = tokens?.[0]?.additionalInfo;
  const nameFromUrl = additionalInfo
    ? `${additionalInfo.firstName} ${additionalInfo.lastName}`
    : searchParams.get('name');
  const [customMessageFromBackoffice, setCustomMessageFromBackoffice] = useState(customMessage);
  const intl = createIntl({
    locale: language,
    messages: translations[language],
  });
  const { formatMessage } = intl;
  const [, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    if (jobDetails) {
      setSeo({
        title: jobDetails.title,
        meta: {
          description: `${company?.name} is looking for ${jobDetails.title}. Apply now with Talents & Friends to put you on top of all other applicants with extended support`,
          ...(jobDetails.customVideo ? { 'og:video:url': jobDetails.customVideo?.url } : {}),
        },
      });
    }
  }, [jobDetails, company?.name]);

  useEffect(() => {
    if (customMessage) {
      const trimmedMessage = customMessage.replaceAll('{name}', nameFromUrl || '').replace(/ *\{[^)]*\} */g, ' '); // Remove all extra unknown variables
      setCustomMessageFromBackoffice(trimmedMessage);
    }
  }, [customMessage, nameFromUrl]);

  const contractTypeTranslation: Record<ContractTypeEnum, string> = {
    fullTime: formatMessage({ id: IntlKeys.fullTime }),
    partTime: formatMessage({ id: IntlKeys.partTime }),
  };

  const employmentTypeTranslation: Record<JobLocationEnum, string> = {
    office: formatMessage({ id: IntlKeys.workFromOffice }),
    hybrid: formatMessage({ id: IntlKeys.hybrid }),
    remote: formatMessage({ id: IntlKeys.remote }),
  };

  const shareOnWhatsapp = useCallback(() => {
    window.open(
      `whatsapp://send?text=${formatMessage({ id: IntlKeys.jobShareMessage }, { url: window.location.href })}`,
    );
  }, [formatMessage]);

  const handleApplyWidgetOpen = useCallback(() => {
    setIsApplyFormVisible(true);
  }, [setIsApplyFormVisible]);

  const getMinimumHeightForStickySection = () => {
    let height = '400px';
    if (isApplyFormVisible) {
      height = '650px';
    }
    return height;
  };

  return (
    <Article>
      <Container>
        <Banner>
          <MetaDetails>
            <JobTitleCard
              fgColor={jobDetails.customColor || theme.colors.white}
              bgColor={jobDetails.customBackgroundColor || theme.colors.primary}
            >
              <Logo>{company?.logo?.url ? <LogoImage src={company?.logo?.url} /> : <Text>{company?.name}</Text>}</Logo>
              {customMessageFromBackoffice && nameFromUrl ? (
                <div>
                  <CustomMessage dangerouslySetInnerHTML={{ __html: customMessageFromBackoffice }}></CustomMessage>
                </div>
              ) : (
                <div>
                  <SubTitle>
                    {!nameFromUrl
                      ? formatMessage({ id: IntlKeys.jobLandingWeAreLookingFor })
                      : formatMessage({ id: IntlKeys.jobLandingWeAreLookingForWithName }, { name: nameFromUrl })}
                  </SubTitle>
                  <Title>{jobDetails.title}</Title>
                </div>
              )}
            </JobTitleCard>
            <Highlights>
              <EachHighlight>
                <SecText>{formatMessage({ id: IntlKeys.workingHours })}</SecText>
                <HighlightValue>{contractTypeTranslation[jobDetails.contractType!]}</HighlightValue>
              </EachHighlight>
              <EachHighlight>
                <SecText>{formatMessage({ id: IntlKeys.employemtType })}</SecText>
                <HighlightValue>{employmentTypeTranslation[jobDetails.location!]}</HighlightValue>
              </EachHighlight>
              <EachHighlight>
                <SecText>{formatMessage({ id: IntlKeys.salary })}</SecText>
                {jobDetails.hideSalery ? (
                  <HighlightValueSmall>{formatMessage({ id: IntlKeys.hiddenSalary })}</HighlightValueSmall>
                ) : (
                  <HighlightValueSmall>
                    {formatCurrency(jobDetails.minSalary)} - {formatCurrency(jobDetails.maxSalary)}
                  </HighlightValueSmall>
                )}
              </EachHighlight>
              <EachHighlight>
                <SecText>{formatMessage({ id: IntlKeys.location })}</SecText>
                <Tooltip title={jobDetails.city?.split(',')[0]}>
                  <HighlightValueSmall>{jobDetails.city?.split(',')[0]}</HighlightValueSmall>
                </Tooltip>
              </EachHighlight>
            </Highlights>
          </MetaDetails>
          {jobDetails.customVideo ? (
            <CustomVideoWrapper>
              <ReactPlayer
                url={jobDetails.customVideo?.url}
                controls
                playIcon={<BsFillPlayCircleFill />}
                playing
                light={
                  jobDetails.customVideo?.thumbnail ? (
                    <VideoThumbnail src={jobDetails.customVideo?.thumbnail} />
                  ) : nameFromUrl ? (
                    <VideoMessage>
                      <span> Hi, {nameFromUrl}</span>
                    </VideoMessage>
                  ) : (
                    <VideoMessage>
                      Hallo!! <MdWavingHand />
                    </VideoMessage>
                  )
                }
                config={{
                  file: {
                    attributes: {
                      crossOrigin: 'true',
                    },
                  },
                }}
                width={'100%'}
                height={'100%'}
              />
            </CustomVideoWrapper>
          ) : additionalInfo?.video ? (
            <CustomVideoWrapper>
              <ReactPlayer
                url={additionalInfo?.video?.url}
                controls
                playIcon={<BsFillPlayCircleFill />}
                playing
                light={
                  additionalInfo?.video?.thumbnail ? (
                    <VideoThumbnail src={additionalInfo?.video?.thumbnail} />
                  ) : nameFromUrl ? (
                    <VideoMessage>
                      <span> Hi, {nameFromUrl}</span>
                    </VideoMessage>
                  ) : (
                    <VideoMessage>
                      Hallo!! <MdWavingHand />
                    </VideoMessage>
                  )
                }
                config={{
                  file: {
                    attributes: {
                      crossOrigin: 'true',
                    },
                  },
                }}
                width={'100%'}
                height={'100%'}
              />
            </CustomVideoWrapper>
          ) : (
            <JobCover src={additionalInfo?.logo?.url || jobDetails.cover?.url || jobCoverPlaceHolder} />
          )}
        </Banner>
        <JobInfoSection>
          <LeftSection>
            <SubHeading>{formatMessage({ id: IntlKeys.tasks })}</SubHeading>
            <Tasks dangerouslySetInnerHTML={{ __html: jobDetails.tasks }} />

            {jobDetails.video ? (
              <VideoWrapper>
                <ReactPlayer url={jobDetails.video?.url} width={'100%'} wrapper={VideoPlayer} />
              </VideoWrapper>
            ) : null}
            <>
              <SubHeading>{formatMessage({ id: IntlKeys.yourBenefits })}</SubHeading>

              <Benefits>
                {jobDetails.benefits?.map((benefit) => (
                  <EachBenefit key={benefit.id}>
                    <span>{benefit.icon}</span>
                    {benefit.name}
                  </EachBenefit>
                ))}
              </Benefits>
            </>
            {jobDetails.gallery && <Gallery gallery={jobDetails.gallery} />}
          </LeftSection>
          <StickySection maxHeight={getMinimumHeightForStickySection()}>
            <ApplySection>
              {!isApplyFormVisible ? (
                <>
                  <Text>
                    {formatMessage({ id: IntlKeys.interestedInRole }, { role: <span>{jobDetails.title}</span> })}
                  </Text>
                  <ApplyButton
                    fgColor={jobDetails.customColor || theme.colors.white}
                    bgColor={jobDetails.customBackgroundColor || theme.colors.primary}
                    onClick={handleApplyWidgetOpen}
                  >
                    {formatMessage({ id: IntlKeys.interestedIn })}
                  </ApplyButton>
                </>
              ) : (
                <ApplicationFormSection>
                  <JobApplicationForm
                    isPreview={isPreview}
                    questions={jobDetails.questions}
                    applicationIdFromUrl={applicationIdFromUrl || null}
                    setSearchParams={setSearchParams}
                    token={tokens?.[0]}
                    uploadToken={jobDetails?.uploadToken}
                    hideApplyForm={() => setIsApplyFormVisible(false)}
                  />
                </ApplicationFormSection>
              )}
            </ApplySection>
            <ShareJobSection>
              <SecText>{formatMessage({ id: IntlKeys.shareThisJob })}</SecText>
              <ShareJob>
                <JobLink>{window.location.href.split('?')[0]}</JobLink>{' '}
                {/** TODO: Update this logic with a shortened url */}
                <Tooltip title={formatMessage({ id: IntlKeys.copyToClipboard })}>
                  <ShareButton onClick={() => copyToClipboard(window.location.href.split('?')[0])}>
                    <BiClipboard />
                  </ShareButton>
                </Tooltip>
                <Tooltip title={formatMessage({ id: IntlKeys.shareOnWhatsapp })}>
                  <ShareButton onClick={shareOnWhatsapp}>
                    <BsWhatsapp />
                  </ShareButton>
                </Tooltip>
              </ShareJob>
            </ShareJobSection>
          </StickySection>
        </JobInfoSection>

        <CompanyDetails company={jobDetails.company} intl={intl} />
      </Container>
    </Article>
  );
};

export default JobLandingPage;

const Article = styled.article`
  width: 100%;
`;

const Container = styled(Wrapper)`
  padding: 2rem 1rem;
  max-width: 1260px;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    padding: 1.5rem 0.75rem;
    overflow: hidden;
  }
`;

const Banner = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  grid-gap: 1rem;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
  }
`;

const MetaDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const JobTitleCard = styled(Card)`
  min-height: 280px;
  border-radius: 20px;
  background-color: ${(props: { bgColor: string; fgColor: string }) => props.bgColor};
  color: ${(props: { bgColor: string; fgColor: string }) => props.fgColor};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.5rem;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    padding: 1rem;
  }
`;

const Highlights = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 30px;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const EachHighlight = styled(Card)`
  background-color: ${theme.colors.lightGray};
  border-radius: 20px;
  padding: 24px;
  max-width: 100%;
  overflow: hidden;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    padding: 1rem;
  }
`;

const Logo = styled.div`
  p {
    color: ${theme.colors.white};
    font-size: 1.4rem;
  }

  img {
    max-width: 60%;
    max-height: 64px;
  }
`;

const Title = styled.h2`
  font-size: 2.25rem;
  margin: 0;
`;

const SubTitle = styled.span`
  font-size: 1.25rem;
`;

const LogoImage = styled.img``;

const CustomVideoWrapper = styled.div`
  border-radius: 20px;
  background-size: cover;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background: ${theme.colors.mintGreen};
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  > div {
    background: black;
    border-radius: 20px;
    height: 100%;
    width: 100%;
  }

  video {
    border-radius: 20px;
    scale: 1.01;
  }

  svg {
    font-size: 4rem;
    color: ${theme.colors.mintGreen};
    z-index: 2;
  }

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
    height: calc(100vw - 2rem);
    max-height: 420px;
  }

  > div,
  video {
    max-width: 100%;
    width: 100%;
  }
`;

const VideoThumbnail = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const VideoMessage = styled.div`
  color: ${theme.colors.white};
  font-size: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 6rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 1rem 4rem;

  svg {
    font-size: 3rem;
    margin: 0;
  }
`;

const JobCover = styled.img`
  border-radius: 20px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
  }
`;

const HighlightValue = styled.p`
  font-size: 1.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    font-size: 1rem;
  }
`;

const HighlightValueSmall = styled.p`
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    font-size: 0.75rem;
  }
`;

const JobInfoSection = styled.div`
  margin-top: 4rem;
  position: relative;
  display: flex;
  gap: 1rem;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    flex-direction: column-reverse;
    margin-top: 2rem;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  overflow: hidden;
  padding-top: 20px;
`;

const StickySection = styled.div`
  flex: 1;
  position: sticky;
  height: auto;
  max-height: ${(props: { maxHeight: string }) => props.maxHeight || '450px'};
  top: 20px;
  max-width: calc(100% / 2 - 0.5rem);
  margin-bottom: 3rem;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    position: relative;
    max-height: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
`;

const SubHeading = styled(Title3)`
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
`;

const Tasks = styled.div`
  font-size: 1.125rem;

  * {
    background-color: transparent !important;
    font-family: NeueMontreal !important;
  }

  ul {
    padding: 0 4px;
  }

  li {
    margin-left: 1rem !important;
    line-height: 24px;
    margin-bottom: 8px;

    > span {
      color: ${theme.colors.secondary} !important;
      background: transparent !important;
    }

    ::marker {
      color: ${theme.colors.primary};
    }
  }
`;

const VideoPlayer = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;

const VideoWrapper = styled.div`
  margin: 4rem 0;
`;

const Benefits = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const EachBenefit = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  background-color: ${theme.colors.lightGray};
  border-radius: 4rem;
  font-size: 0.875rem;
  gap: 0.5rem;
  align-items: center;
`;

const ApplySection = styled(Card)`
  background: ${theme.colors.lightGray};
  margin-bottom: 1rem;
  padding: 1.5rem;

  p {
    font-size: 2.5rem;
    font-weight: 530;

    span {
      font-family: VictorSerifSmooth;
      font-weight: 300;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    padding: 1rem;
  }
`;

const ShareJobSection = styled(Card)`
  background: ${theme.colors.lightGray};
  padding: 1.5rem;

  > span {
    font-size: 1rem;
    font-weight: 530;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    padding: 1rem;
    margin-bottom: 2rem;
  }
`;

const ApplyButton = styled(Button)`
  border-color: ${(props: { bgColor: string; fgColor: string }) => props.bgColor};
  background-color: ${(props: { bgColor: string; fgColor: string }) => props.bgColor};
  color: ${(props: { bgColor: string; fgColor: string }) => props.fgColor};
  margin-top: 2rem;
  width: 100%;
`;

const ShareJob = styled(FlexBox)`
  margin-top: 1.5rem;
  gap: 0.5rem;
`;

const JobLink = styled.div`
  padding: 0.75rem 1rem;
  background: ${theme.colors.white};
  border-radius: 30px;
  flex: 1;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ShareButton = styled.div`
  border: 1px solid ${theme.colors.disabledGray};
  height: 40px;
  width: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border: 1px solid ${theme.colors.gray};
  }
`;

const ApplicationFormSection = styled.div``;

const CustomMessage = styled.div`
  * {
    background-color: transparent !important;
  }

  p {
    margin: 0;
    font-size: 1.125rem;
  }
`;
