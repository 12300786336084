import { FlexBox, SubmitButton } from 'components/base';
import ImageDropzone from 'components/Dropzone/ImageDropzone';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { Loader } from 'components/loader';
import { updateUserAction } from 'domain/auth/actions';
import { authMetaSelector, authUserSelector } from 'domain/auth/selectors';
import { useAppDispatch } from 'domain/store';
import { IntlKeys } from 'localization';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { UserType, UserPayloadType } from 'types/user';
import { uploadAvatar } from './helpers';

const ProfileSettings = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const userDetails = useSelector(authUserSelector);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const { isLoading: isUserLoading } = useSelector(authMetaSelector);
  const form = useForm<UserType & { avatarObj?: Record<string, any> }>({ defaultValues: userDetails || {} });
  const [isSubitting, setIsSubitting] = useState(false);
  const avatarObj = useWatch({ name: 'avatarObj', control: form.control });
  const avatarUrl = useWatch({ name: 'avatar', control: form.control });

  useEffect(() => {
    if (userDetails) {
      form.reset({ ...userDetails });
    }
  }, [userDetails]);

  useEffect(() => {
    setAvatarSrc(avatarUrl?.url || avatarObj?.src);
  }, [avatarObj, avatarUrl]);

  const onSubmit = useCallback(async (data: FieldValues) => {
    setIsSubitting(true);
    const { firstName, lastName, language } = data;
    const [avatar] = await Promise.all([uploadAvatar(data.avatarObj, data.avatar)]);

    dispatch(updateUserAction({ firstName, lastName, language, avatar } as UserPayloadType))
      .unwrap()
      .then(() => {
        toast.success(formatMessage({ id: IntlKeys.profileUpdatedSuccessfully }));
        setIsSubitting(false);
      });
  }, []);

  const languagesOptions = [
    {
      value: 'de-DE',
      label: '🇩🇪 Deutsch',
    },
    {
      value: 'en-US',
      label: '🇬🇧 English',
    },
  ];

  const handleAvatarUpload = useCallback((avatarObj: Record<string, any>) => {
    form.setValue('avatarObj', avatarObj);
  }, []);

  const removeAvatar = () => {
    form.setValue('avatarObj', undefined);
    form.setValue('avatar', null);
  };

  if (isUserLoading) {
    return <Loader />;
  }

  return (
    <SettingsContainer>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <FormContainer>
            <EachInput label={formatMessage({ id: IntlKeys.avatar })}>
              <ImageDropzone
                onDrop={(acceptedFiles) =>
                  handleAvatarUpload({
                    fileObj: acceptedFiles[0],
                    src: URL.createObjectURL(acceptedFiles[0]),
                    name: acceptedFiles[0].name,
                  })
                }
                useAvatar
                maxFiles={1}
                height={200}
                src={avatarSrc}
                multiple={false}
                onRemove={removeAvatar}
                cropConfig={{ aspectRatio: 1 }}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.firstName })}>
              <FormTextField
                name={'firstName'}
                placeholder={formatMessage({ id: IntlKeys.enterFirstName })}
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.lastName })}>
              <FormTextField
                name={'lastName'}
                placeholder={formatMessage({ id: IntlKeys.enterLastName })}
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
              />
            </EachInput>
            <EachInput label={formatMessage({ id: IntlKeys.language })}>
              <FormAutoComplete
                freeSolo
                name="language"
                placeholder={formatMessage({ id: IntlKeys.selectLanguage })}
                options={languagesOptions}
              />
            </EachInput>
          </FormContainer>
          <Footer>
            <ButtonContainer align="center" justify={'flex-end'}>
              <SubmitButton type="submit" isLoading={isSubitting} disabled={isSubitting}>
                Save
              </SubmitButton>
            </ButtonContainer>
          </Footer>
        </Form>
      </FormProvider>
    </SettingsContainer>
  );
};

export default ProfileSettings;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

const SettingsContainer = styled.div``;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 64px;
  border-top: 1px solid ${theme.colors.stroke};
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${theme.colors.white};
`;

const ButtonContainer = styled(FlexBox)`
  max-width: 800px;
  margin: auto;
  align-items: center;
  padding: 0rem 1rem;
  height: 100%;

  > button {
    padding: 0.5rem 1.5rem;
  }
`;

const FormContainer = styled.div`
  max-width: 800px;
  padding-bottom: 64px;
`;
