import { createApiCall } from './http/utils/createApiCall';
import { restClient } from './http';
import axios from 'axios';
import { FileType } from 'types/common';

type ImageUploadConfig = {
  name?: string;
  image: Blob | null;
};
export const uploadImage = async ({ name, image }: ImageUploadConfig) => {
  const callGetCoverUploadUrlApi = createApiCall<Record<'uploadUrl' | 'accessUrl', string>>({
    api: restClient.uploadImage,
  });

  let coverUrl = undefined;

  if (image) {
    const {
      data: { uploadUrl, accessUrl },
    } = await callGetCoverUploadUrlApi({ type: image.type, name });
    //
    const file = new File([image], 'image', {
      lastModified: new Date().getTime(),
      type: image.type,
    });

    //
    await axios.put(uploadUrl, file);

    coverUrl = accessUrl;
  }

  return coverUrl;
};

type FileUploadConfig = { file: File | Blob; name: string };

export const uploadFile = async (file: FormData) => {
  const callFileUploadUrl = createApiCall<FileType>({
    api: restClient.uploadFile,
    headers: {
      'content-type': `multipart/form-data;`,
    },
    preventDataTransform: true,
  });

  let fileUrl, fileThumbnail;

  if (file) {
    try {
      const {
        data: { url, thumbnail },
      } = await callFileUploadUrl({ data: file });

      // await axios.put(url, file);

      fileUrl = url;
      return { url, thumbnail };
    } catch (e) {
      console.log('e', e);
    }
  }

  return { url: fileUrl, thumbnail: '' };
};

export const uploadFilePublic = async (file: FormData) => {
  const callFileUploadUrl = createApiCall<FileType>({
    api: restClient.uploadFilePublic,
    headers: {
      'content-type': `multipart/form-data;`,
    },
    preventDataTransform: true,
  });

  let fileUrl, fileThumbnail;

  if (file) {
    try {
      const {
        data: { url, thumbnail },
      } = await callFileUploadUrl({ data: file });

      // await axios.put(url, file);

      fileUrl = url;
      return { url, thumbnail };
    } catch (e) {
      console.log('e', e);
    }
  }

  return { url: fileUrl, thumbnail: '' };
};

export const canvasToBlob = (canvas: HTMLCanvasElement) => {
  return new Promise<CropResultType>((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve({
          blob,
          url: URL.createObjectURL(blob),
        });
      }
    });
  });
};

export type CropResultType = {
  blob: Blob;
  url: string;
};
