import { createSelector } from '@reduxjs/toolkit';
//
import { RootState } from '../store';

const jobsSelector = (state: RootState) => state.jobs;
export const jobsMetaSelector = createSelector(jobsSelector, ({ meta }) => meta);

export const jobsResourceSelector = createSelector(jobsSelector, ({ resource }) => resource);
export const jobsResourcesSelector = createSelector(jobsSelector, ({ resources }) => resources);
export const publicJobResourceSelector = createSelector(jobsSelector, ({ publicJob }) => publicJob);
