import { configureApiCalls } from 'helpers/http/utils/createApi';
import { makeUrl } from 'helpers/http/utils/url';
import { api } from './api';

export const restClient = configureApiCalls({
  // updateExample: api('post', makeUrl`/examples/${'exampleId'}`), // exampleId -> from action.payload.params
  // auth
  signIn: api('post', '/auth/login'),
  signInDemo: api('post', '/auth/demo-login'),
  signUp: api('post', '/auth/sign-up'),
  forgotPassword: api('post', '/auth/request-password'),
  resetPassword: api('post', '/auth/reset-password'),
  refreshToken: api('post', '/auth/refresh-token'),
  inviteMember: api('post', '/auth/invite'),
  generateInviteLink: api('post', '/auth/invite/link'),
  //
  getCurrentUser: api('get', '/users/me'),
  updateUserAvatar: api('patch', '/users/me/avatar'),
  uploadUserAvatar: api('post', '/users/me/avatar/upload'),
  // settings
  updateCurrentUser: api('put', '/users/me'),
  updateUserPassword: api('post', '/auth/change-password'),

  // files
  uploadImage: api('post', '/storage'),
  uploadFile: api('post', '/storage'),
  uploadFilePublic: api('post', '/storage/public'),

  // benefits
  getBenefits: api('get', '/benefits'),
  getBenefitDetails: api('get', makeUrl`/benefits/${'id'}/`),
  createBenefit: api('post', '/benefits'),
  updateBenefit: api('put', makeUrl`/benefits/${'id'}`),
  deleteBenefit: api('delete', makeUrl`/benefits/${'id'}`),

  // jobs
  getJobs: api('get', '/jobs'),
  getJobDetails: api('get', makeUrl`/jobs/${'id'}/`),
  deleteJob: api('delete', makeUrl`/jobs/${'id'}/`),
  createJob: api('post', '/jobs'),
  updateJob: api('put', makeUrl`/jobs/${'id'}`),
  customizeJob: api('patch', makeUrl`/jobs/${'id'}/customize`),
  createJobQualificationQuestions: api('post', makeUrl`/jobs/${'id'}/questions`),
  deleteQuestion: api('delete', makeUrl`/jobs/${'jobId'}/questions/${'questionId'}`),
  updateQuestion: api('put', makeUrl`/jobs/${'jobId'}/questions/${'questionId'}`),
  updateJobStatus: api('patch', makeUrl`/jobs/${'id'}/status`),
  getPublicJobDetails: api('get', makeUrl`/jobs/${'jobId'}/public`),
  getComments: api('get', makeUrl`/jobs/${'jobId'}/comments`),
  createComment: api('post', makeUrl`/jobs/${'jobId'}/comments`),
  updateComment: api('put', makeUrl`/jobs/${'jobId'}/comments/${'commentId'}`),
  deleteComment: api('delete', makeUrl`/jobs/${'jobId'}/comments/${'commentId'}`),

  // applications
  getApplications: api('get', '/applications'),
  createApplication: api('post', '/applications'),
  getApplicationDetails: api('get', makeUrl`/applications/${'id'}/`),
  updateApplication: api('patch', makeUrl`/applications/${'id'}/`),
  sumbitAnswer: api('post', makeUrl`/applications/${'id'}/answers`),
  downloadCandidates: api('post', '/candidates/export'),

  // settings
  getCompany: api('get', '/companies'),
  updateCompany: api('put', '/companies'),

  // Credits
  getCredits: api('get', '/credits'),
  purchaseCredits: api('put', '/credits/purchase'),
  getInvoices: api('get', '/invoices'),
  getSubscriptions: api('get', '/subscriptions'),
  createSubscription: api('put', makeUrl`/subscriptions/${'id'}/subscribe`),
});
