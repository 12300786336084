import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { LinearProgress } from '@mui/material';
//
import DefaultRowSkeleton from 'components/skeletons/DefaultRowSkeleton';
//
import cx from 'clsx';
import styled from 'styled-components';

interface OwnProps {
  list: unknown[];
  //
  isLoading: boolean;
  isLoaded?: boolean;
  isStale?: boolean;
  //
  page: number;
  pageCount: number;
  perPage?: number;
  itemCount?: number;
  //
  fetchNext: VoidFunction;
  //
  emptyState?: React.ReactNode;
  //
  ListItem: React.ElementType<{ data: unknown }>;
  ItemSkeleton?: React.ElementType;
  componentProps?: Record<string, unknown>;
  className?: string;
}
const InfiniteList: React.FC<OwnProps> = ({
  list,
  //
  isLoading,
  isLoaded = false,
  isStale = false,
  //
  page,
  perPage = 10,
  pageCount,
  itemCount = 0,
  //
  fetchNext,
  //
  emptyState,
  //
  ListItem,
  componentProps = {},
  ItemSkeleton = DefaultRowSkeleton,
  className,
}) => {
  //
  const resources = isStale ? [] : list;
  //
  const morePagesAvailable = (isLoaded || pageCount !== 0) && pageCount > page;
  const shouldReload = !isLoaded && page === 0 && pageCount === 0;
  const itemsLeft = itemCount !== 0 ? itemCount - resources.length : perPage;
  const itemsLoading = Math.min(itemsLeft, perPage);
  const hasMore = !isLoading && (morePagesAvailable || shouldReload || isStale);
  //
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore: fetchNext,
  });

  return (
    <Wrapper className={cx(className)}>
      <List>
        {resources?.map((data, ix) => (
          <li key={(data as { id?: string })?.id || ix}>
            <ListItem data={data} {...componentProps} />
          </li>
        ))}

        {isLoading &&
          new Array(itemsLoading).fill(0).map((_, ix) => (
            <li key={ix}>
              <ItemSkeleton />
            </li>
          ))}
      </List>

      {!isLoading && isLoaded && list.length === 0 && <NoDataContainer>{emptyState}</NoDataContainer>}

      {/* ///////////////////////// SENTRY ///////////////////////// */}
      {hasMore && <SentryComponent ref={sentryRef} />}

      {isLoading && <LinearProgress />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const List = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NoDataContainer = styled.div`
  height: 100%;
`;

////////////////////////////////////////////////////////////////////////////////
// useInfiniteScroll uses IntersectionObserver under the hood
// when sentry element becomes visible - load
const SentryComponent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200;
  visibility: hidden;
`;

export default InfiniteList;
