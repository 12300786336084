// local
import { restClient } from './restClient';
// utils
import { saveAuthTokensToLS, getAuthTokens } from 'helpers/localStorage';
import { NetworkErrors } from 'helpers/http/index';
// domain
import { store } from 'domain/store';
import { changeAuthLoadingStatusAction, signOutAction, updateTokensAction } from 'domain/auth/actions';
//
import dayjs from 'dayjs';
import axios, { AxiosError } from 'axios';

type CreateRevalidateTokenConfig = {
  tokenExpiryTime?: number;
};

type ResolvablePromiseWrapper = {
  promise: Promise<unknown>;
  resolve: (value?: unknown) => void;
};
const createRevalidateToken = ({ tokenExpiryTime = 300 }: CreateRevalidateTokenConfig = {}) => {
  // shared among concurrent requests - access by reference
  const sharedState = {
    isRefreshing: false,
    refreshEndListeners: [] as ResolvablePromiseWrapper[],
  };

  const createListenerPromise = () => {
    // promise executor is called before Promise constructor even returns promise
    // so resolve function is already up to date when add to listeners array
    let resolve: (value: unknown) => void = () => {};

    const waitingPromise = new Promise((_resolve) => {
      resolve = _resolve;
    });

    sharedState.refreshEndListeners.push({
      promise: waitingPromise,
      resolve,
    });

    return waitingPromise;
  };

  const resolveListenerPromises = () => {
    sharedState.refreshEndListeners.forEach(({ resolve }) => {
      resolve();
    });

    sharedState.refreshEndListeners = [];
  };

  return async () => {
    const { refreshToken, receivedAt } = getAuthTokens();
    if (!refreshToken) {
      store.dispatch(changeAuthLoadingStatusAction({ status: false }));
      return;
    }

    const someTimeAgo = dayjs().subtract(tokenExpiryTime - 5, 'seconds');
    const tokenValidityIsNearlyExpired = dayjs(receivedAt).isBefore(someTimeAgo);

    switch (true) {
      // Concurrent request when refresh is already in progress
      case sharedState.isRefreshing: {
        await createListenerPromise();

        return;
      }

      // token exist, nearly expired and not currently refreshing
      case tokenValidityIsNearlyExpired && !sharedState.isRefreshing: {
        sharedState.isRefreshing = true;

        const url = restClient.refreshToken.url;

        await axios
          .post(
            url as string,
            {
              refreshToken,
            },
            {
              baseURL: process.env.REACT_APP_API_URL,
            },
          )
          .then(({ data }) => {
            store.dispatch(updateTokensAction(data));
            saveAuthTokensToLS(data);
          })
          .catch(({ response }: AxiosError<{ message: string; statusCode: number }>) => {
            if (response?.data) {
              const { statusCode } = response.data;

              // refreshToken expired
              if (statusCode === 401) {
                store.dispatch(signOutAction());
                throw NetworkErrors.RefreshTokenExpired;
              }
            }
          })
          .finally(() => {
            resolveListenerPromises();
            sharedState.isRefreshing = false;
          });
      }
    }
  };
};

const revalidateToken = createRevalidateToken({
  tokenExpiryTime: 300, // seconds
});

export default revalidateToken;

// const revalidateToken = () => ({
//   accessToken: 'string',
//   refreshToken: 'string',
//   receivedAt: 'string',
// });

// createTokenRevalidationWorker({
//   getAuthTokens: () => {
//     return getAuthTokens() || authHeadersSelector(store.getState());
//   },
//   //
//   getRevalidationUrl: () => require('./restClient').refreshToken.url as string,
//   onRevalidationSuccess: ({ data }) => {
//     store.dispatch(updateTokensAction(data));
//     saveAuthTokensToLS(data);
//   },
//   onRevalidationFailure: ({ response }) => {
//     if (response?.data) {
//       const { statusCode } = response.data;

//       // refreshToken expired
//       if (statusCode === 401) {
//         store.dispatch(signOutAction());
//         throw NetworkErrors.RefreshTokenExpired;
//       }
//     }
//   },
//   //
//   tokenExpiryTime: 300, // seconds
// });

// export default revalidateToken;
