import React from 'react';
import { useFormState, FieldError } from 'react-hook-form';
import { get } from 'lodash';
// components
import { ErrorMessage } from 'components/base';

type ExtractedErrorProps = {
  touched: boolean | undefined;
  error: FieldError | undefined;
};

export type ErrorProps = {
  error: boolean;
  helperText: React.ReactNode;
};

export const extractErrorProps = ({ touched, error }: ExtractedErrorProps): ErrorProps => {
  const isError = Boolean(touched && error);

  return {
    error: isError,
    helperText: isError && <ErrorMessage errorMessage={error?.message} />,
  };
};

export const useFieldErrors = (name: string): ErrorProps => {
  const { errors, touchedFields, isSubmitted } = useFormState<Record<string, unknown>>();
  //

  const touched = get(touchedFields, name) || isSubmitted;
  const error = get(errors, name);

  const isError = Boolean(touched && error);

  return {
    error: isError,
    helperText: isError && <ErrorMessage errorMessage={error?.message} />,
  };
};
