import React from 'react';
import Dropzone, { CustomDropzoneProps } from '.';
import { documentMimeTypesArray } from './entities';
import styled from 'styled-components';
import { MdDeleteForever } from 'react-icons/md';
import { theme } from 'theme';
import { Link } from '@mui/material';

interface OwnProps extends CustomDropzoneProps {
  src?: string;
  onRemove?: () => void;
  onDrop: (files: File[]) => void;
  previewClassName?: string;
}

const DocumentDropzone: React.FC<OwnProps> = ({ src, onRemove, onDrop, previewClassName = '', ...dropZoneProps }) => {
  return (
    <DocumentDropzoneContainer>
      {src ? (
        <PreviewContainer className={previewClassName}>
          <Link href={src} target="_blank">
            Preview
          </Link>
          {onRemove && (
            <RemoveContainer onClick={onRemove} className={'remove-image'}>
              <MdDeleteForever />
            </RemoveContainer>
          )}
        </PreviewContainer>
      ) : (
        <Dropzone accept={documentMimeTypesArray} onDrop={onDrop} {...dropZoneProps} />
      )}
    </DocumentDropzoneContainer>
  );
};

export default DocumentDropzone;

const DocumentDropzoneContainer = styled.div`
  width: 100%;
`;

const PreviewContainer = styled.div`
  max-width: 100%;
  width: auto;
  position: relative;
`;

const RemoveContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: -1rem;
  /* padding: 1rem; */
  border-radius: 30px;
  background: ${theme.colors.maroon};
  width: 48px;
  height: 48px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.colors.white};
`;
