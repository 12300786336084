import CreateNewJobCard from 'components/JobCard/CreateNewJobCard';
import * as React from 'react';
import styled from 'styled-components';

const EmptyJobs = () => {
  return (
    <div>
      <Container>
        <CreateNewJobCard />
      </Container>
    </div>
  );
};

export default EmptyJobs;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(380px, 100%), 1fr));
  margin-top: 1rem;
`;
