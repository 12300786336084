interface SeoData {
  title: string;
  meta?: Record<string, string>;
}

export const setSeo = (data: SeoData) => {
  document.title = data.title;

  if (data.meta) {
    Object.keys(data.meta)?.forEach((metaKey) => {
      document?.querySelector(`meta[name="${metaKey}"]`)?.setAttribute('content', data.meta?.[metaKey] as string);
    });
  }
};
