import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
//
import { theme } from 'theme';
import { FlexBox } from 'components/base';

interface OwnProps {
  label?: React.ReactNode;
  className?: string;
  //
  children?: React.ReactNode;
}

const InputWrapper: React.FC<OwnProps> = ({ label, className, children }) => {
  return (
    <Container className={clsx(className)} direction="column" gap={2}>
      {label && <InputLabel>{label}</InputLabel>}
      {children}
    </Container>
  );
};

const Container = styled(FlexBox)`
  max-width: 100%;
`;

const InputLabel = styled.span`
  font-size: 0.875rem;
  color: ${theme.colors.secondary};
  line-height: 20px;
  font-weight: 500;
  padding-bottom: 0.5rem;
  span {
    color: ${theme.colors.red};
  }
`;

export default React.memo(InputWrapper);
