import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { InputAdornment, IconButton } from '@mui/material';
// utils
import { IntlKeys } from 'localization/keys';
import { extractIntlKey } from 'localization/keys';
import usePasswordVisibility from 'hooks/usePasswordVisibility';
import useQueryParams from 'hooks/useQueryParams';
// components
import FormTextField from 'components/hook-form/fields/FormTextField';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import { Button, Card, ErrorMessage, FlexBox } from 'components/base';
// local
import useAuthPage from '../hooks/useAuthPage';
// styles
import logo from 'assets/images/tandf-logo.svg';
import styled from 'styled-components';
// import authPlaceholderImage from 'assets/auth-placeholder-image.svg';
interface OwnProps {
  header: string;
}

const ResetPasswordPage: React.FC<OwnProps> = ({ header }) => {
  const { formatMessage } = useIntl();
  //
  const query = useQueryParams();
  const token = query.get('token');

  const { form, onSubmit, isLoading, errorMessageId } = useAuthPage({
    authPage: 'resetPassword',
    defaultValues: { token },
  });

  //

  //
  const {
    inputType: confirmationInputType,
    VisibilityIcon: ConfirmationVisibilityIcon,
    onToggleShowPassword: onConfirmationToggleShowPassword,
  } = usePasswordVisibility();
  const { inputType, VisibilityIcon, onToggleShowPassword } = usePasswordVisibility();

  return (
    <Container align="center" justify="center">
      <Card withBorder width={'400px'}>
        <img src={logo} alt="Talents & Friends logo" />

        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <h2>{header}</h2>
            <h3>{formatMessage({ id: IntlKeys.resetPasswordSubtitle })}</h3>

            <InputWrapper label={formatMessage({ id: IntlKeys.newPassword })}>
              <FormTextField
                name="password"
                type={inputType}
                placeholder={formatMessage({ id: IntlKeys.passwordPlaceholder })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={onToggleShowPassword}>
                        <VisibilityIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </InputWrapper>

            <InputWrapper label={formatMessage({ id: IntlKeys.retypeNewPassword })}>
              <FormTextField
                name="passwordConfirmation"
                type={confirmationInputType}
                placeholder={formatMessage({ id: IntlKeys.retypeNewPassword })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={onConfirmationToggleShowPassword}>
                        <ConfirmationVisibilityIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </InputWrapper>

            <Button type="submit" disabled={isLoading}>
              {formatMessage({ id: IntlKeys.resetPasswordChangePassword })}
            </Button>

            {errorMessageId && <ErrorMessage errorMessage={formatMessage({ id: extractIntlKey(errorMessageId) })} />}

            <div>
              {formatMessage({ id: IntlKeys.resetPasswordHaveAccount }) + ' '}
              <NavLink to={'/login'}>{formatMessage({ id: IntlKeys.signIn })}</NavLink>
            </div>
          </form>
        </FormProvider>
      </Card>

      <div>{/* <img src={authPlaceholderImage} alt="auth placeholder image" /> */}</div>
    </Container>
  );
};

export default ResetPasswordPage;

const Container = styled(FlexBox)`
  height: 100vh;
  width: 100vw;
`;
