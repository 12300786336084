import React from 'react';
import { Divider, ListItemIcon, Menu, MenuItem, MenuProps } from '@mui/material';
import { Text } from './Typography';
import styled from 'styled-components';

type MenuAction = {
  label: string;
  icon?: React.ReactElement;
  onClick: () => void;
};

interface OwnProps extends Omit<MenuProps, 'open'> {
  trigger: React.ReactElement<any, any>;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  actions: MenuAction[];
  dividers?: number[];
}

const DropdownMenu: React.FC<OwnProps> = ({ trigger, anchorEl, handleClose, actions, dividers, ...menuProps }) => {
  const open = Boolean(anchorEl);
  return (
    <MenuWrapper>
      {trigger}
      <Menu
        anchorEl={anchorEl}
        className="tf-dropwdown-menu"
        id="dropdown-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            maxWidth: 300,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            // mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
            },
            '&:before': {
              display: 'none',
            },
            ...menuProps?.sx,
          },
        }}
        // anchorOrigin={menuProps.anchorOrigin || { horizontal: 'right', vertical: 'bottom' }}
        {...menuProps}
      >
        {actions?.map((action, index) => (
          <div key={index}>
            <MenuItem onClick={action.onClick}>
              {action.icon && <Icon> {action.icon}</Icon>}
              <Text>{action.label}</Text>
            </MenuItem>
            {dividers?.includes(index) ? <Divider /> : null}
          </div>
        ))}
      </Menu>
    </MenuWrapper>
  );
};

export default DropdownMenu;

const Icon = styled(ListItemIcon)`
  /* display: flex;
  align-items: center;
  justify-content: center; */

  svg {
    width: 24px;
    height: 24px;
  }
`;

const MenuWrapper = styled.div``;
